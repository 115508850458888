import React from 'react';

const Polemics = () => {
  return (
    <div className="div_main">
      <h2>Debate</h2>
      <div className="div_item_sostav">
        <h5 style={{ textAlign: 'right' }}>
          <time dateTime="2018-07-30">July 30, 2018</time>
        </h5>
        <h3>
          On Compliance with the Requirements of "Israel's Maritime Policy" and Environmental Requirements for the Conservation of the Marine Ecosystem of "The Concept of Developing the Mediterranean Coastal Territory of Israel" as Presented in the Article by M. Kozlov, A. Kozlov, and V. Gurevich
        </h3>
        <h4>Honored Ecologist of the Russian Federation, Prof., Dr. Mikhail Rudnik</h4>
        <a href="/compliance-with-the-requirements">Learn more...</a>
        <p>
        Since the multifunctional dam is the core of the idea (Concept), we will examine it in light of the requirements of the "Israeli Maritime Program" and the current state of the coastal territory. The Israeli maritime policy reflects some of the issues I raised in my article but also proposes new requirements and rules for the development of the marine space and includes maps showing the current state of the Mediterranean coastal area...        </p>
        <p><i> </i></p>
      </div>

      <div className="div_item_sostav">
        <h5 style={{ textAlign: 'right' }}>
          <time dateTime="2018-07-28">July 28, 2018</time>
        </h5>
        <h3>The Expediency of a Conceptual Approach to the Development of Israel's Territorial Waters</h3>
        <h4>Alexander Kozlov, Michael Kozlov, Moses Charitonov</h4>
        <a href="publ_celes_conc_podh.shtml">Learn more...</a>
        <p>
          <b>Abstract:</b> The article assumes that three main interrelated factors hinder the development of work on the development of territorial waters: Insufficient development of norms and rules for activities in the coastal zone, coordinated with all interested structures; the absence of a continuously operating coordinating organization; the lack of a developed concept for the development of territorial waters as part of the concept for the development of the entire marine space of Israel...
        </p>
      </div>

      <div className="div_item_sostav">
        <h5 style={{ textAlign: 'right' }}>
          <time dateTime="2018-05-29">May 29, 2018</time>
        </h5>
        <h3>Utilization of Israel's Shelf Resources - Managerial Aspect</h3>
        <h4>Boris Finkelstein, Ph.D. Expert at the Institute of Integration and Professional Adaptation. Netanya, Israel.</h4>
        <a href="publ_publ_texts.shtml#1">Learn more...</a>
        <p>
          Given the continuous coastline of approximately 180 km, favorable natural-climatic and geological conditions, the resources of the coastal zone undoubtedly constitute a significant part of the national wealth of the State of Israel. These resources include recreational, biological, hydrocarbon, and other mineral resources. In recent years, large gas deposits have been explored on the shelf, the largest of which, Leviathan, according to publicly available data, allows not only for domestic gas consumption but also for export...
        </p>
      </div>

      <div className="div_item_sostav">
        <h5 style={{ textAlign: 'right' }}>
          <time dateTime="2018-05-18">May 18, 2018</time>
        </h5>
        <h3>Response to Mikhail Rudnik's Article "On the Implementation of the Concept for Developing the Mediterranean Coastal Territory of Israel"</h3>
        <h4>Mr. Alexander Kozlov</h4>
        <a href="//drive.google.com/file/d/1DR5tfXrBjX9Yoa-qriKaf5AbGqghhI9O/view" target="_blank">Learn more...</a>
        <p>
          The proposed concept for the development of the Mediterranean coastal territory of Israel is based on the imminent need for comprehensive development of Israel's territorial sea in the country's socio-economic interests. Possible, thus, the actual increase in territory, with effective use, will provide a significant boost to Israel's economy...
        </p>
      </div>

      <div className="div_item_sostav">
        <h5 style={{ textAlign: 'right' }}></h5>
        <h3>On the Attitude Towards Some Expert Reasoning and Criticism of Certain Provisions of "The Concept of Developing the Mediterranean Coastal Territory of Israel" (by M. Kozlov and co-authors A. Kozlov, V. Gurevich) at the Current Stage of Its Development</h3>
        <h4>Vladimir Stefanovich Mokiy, Director of the Institute of Transdisciplinary Technologies...</h4>
        <a href="//drive.google.com/file/d/1pQRvIiDStm3rM5o7AA5mTk-_Obm4GRb4/view" target="_blank">Learn more...</a>
        <p>
          It is known that about 60% of Israel's territory is desert, and of the remaining 40%, more than half is rocky soil of hills and mountainous areas. The ecological systems of such regions are usually formed over centuries and are extremely sensitive to external influences, including anthropogenic and technological ones. Taking this circumstance into account...
        </p>
      </div>

      <div className="div_item_sostav">
        <h5 style={{ textAlign: 'right' }}>
          <time dateTime="2018-05-08">May 8, 2018</time>
        </h5>
        <h3>Review of Mikhail Rudnik's Article "On the Implementation of the Concept for Developing the Mediterranean Coastal Territory of Israel"</h3>
        <h4>Albert Notkin, Head of the Scientific Ecological Center "Gamma"</h4>
        <a href="//drive.google.com/file/d/1gCSkn8KA6V4q0vH3Ujv-8Om3r0RMG8RS/view" target="_blank">Learn more...</a>
        <p>
          The information presented in the article is undoubtedly useful and convincing. However, the claims of the inadequacy of the concept raise doubts. Undoubtedly, global projects require serious preliminary discussions and elaboration based on a systemic comprehensive scientific approach. The feasibility of developing coastal waters is quite...
        </p>
      </div>

      <div className="div_item_sostav">
        <h5 style={{ textAlign: 'right' }}></h5>
        <h3>On the Formation of a Conceptual Design Group for Developing the Concept of Developing the Mediterranean Coastal Territory of Israel</h3>
        <h4>Mikhail Kozlov</h4>
        <a href="//drive.google.com/file/d/1nRmJjf9zyp39mVInAyNohnrPua9zQ4bk/view?usp=sharing" target="_blank">Learn more...</a>
        <p>
          <b>Israel will grow by the sea...</b> Employees of the Institute of Integration and Professional Adaptation, Netanya, and other specialists and scientists in Israel have organized a conceptual design group to develop a concept for the development of the Mediterranean coastal territory of Israel. The group is forming sectors: ecology; economy; systems analysis, forecasting, and planning; oceanography; construction and architecture; hydraulic structures; energy; intellectual technologies; medical...
        </p>
      </div>

      <div className="div_item_sostav">
        <h5 style={{ textAlign: 'right' }}></h5>
        <h3>On the Implementation of the Concept for Developing the Mediterranean Coastal Territory of Israel</h3>
        <h4>Mikhail Rudnik</h4>
        <a href="//drive.google.com/file/d/1chL24m8pOv-u_I1hPtryLe6XQ_QwL2SE/view" target="_blank">Learn more...</a>
        <p>
          The proposed concept for the development of the Mediterranean coastal territory of Israel, through the creation of an industrial-resort complex from ecological, technical, geological, and hydrological points of view, justifies the inadequacy of the concept...
        </p>
        <p><i><b>Keywords:</b> concept, dam, ecological safety, comprehensive development, shelf, coast, marine basin, current...</i></p>
      </div>
    </div>
  );
};

export default Polemics;
