import React from 'react';

const CreativeActivity = () => {
  return (
    <div className="div_main">
      <div className="div_item_sostav">
        <h2>Creative Activity</h2>
        <p>
          Fundamental and applied research is conducted in the fields of physics, mathematics, chemistry, biology, cosmology, materials science, artificial intelligence, cognitive activity, nanotechnology, laser and plasma technologies, ecology and life safety, energy, electronics, machine engineering, medicine, sociology, high technologies, economics, philosophy, literature and arts, psychology, and education.
        </p>
      </div>

      <div className="div_item_sostav">
        <h2>Main Projects</h2>
        <p>
          Members of the Academy have developed numerous major projects at various stages of implementation. The main projects are listed below:
        </p>
      </div>

      <div className="div_item_sostav">
        <h2>Search for Tunnels</h2>
        <h3>Author: Prof. I.I. Edelshtein</h3>
        <p>The project was accepted and implemented by the Israeli Ministry of Defense.</p>
      </div>

      <div className="div_item_sostav">
        <h2>Theory of Cluster Components</h2>
        <h3>Author: Late Professor A. Men</h3>
        <p>
          The theory allows for constructing the structural chemical formula of materials based on their known, predetermined properties, enabling the creation of new materials.
        </p>
        <p>The project is currently being implemented.</p>
      </div>

      <div className="div_item_sostav">
        <h2>New Methodology for Teaching Physics and Other Exact Sciences Based on Deductive Method and Systemic Approach</h2>
        <h3>Authors: Dr. L. Preygerman, Dr. M. Bruk.</h3>
        <p>
          During the project implementation, three editions of the textbook have been released. The third edition, edited by Professor and Doctor of Philosophy O. Baksansky, received approval from the Russian Academy of Sciences (RAS) and the Russian Academy of Maimonides. It is recommended as a textbook for university students and teachers of higher and secondary schools. Currently, the textbook has been published by the Russian scientific publishing house URSS and is available for sale in Moscow bookstores.
        </p>
        <p>The project is currently in the implementation stage.</p>
      </div>

      <div className="div_item_sostav">
        <h2>Development of a Production Energy Complex</h2>
        <h3>Authors: Prof. M. Slonim, Prof. L. Preygerman, Prof. B. Medres, Prof. L. Tepman, Mag. A. Notkin</h3>
        <p>
          The complex consists of a 500 MW photovoltaic station (PVS), a pumped storage hydroelectric station (PSHS), and a desalination plant.
        </p>
        <p>Stage: Preliminary project and business plan.</p>
      </div>

      <div className="div_item_sostav">
        <h2>System for Autonomous Power Supply of Modern Cities and Their Districts, as well as Cities of the Future</h2>
        <h3>Author: Prof. L. Preygerman</h3>
        <p>
          This is an environmentally friendly, affordable, and safe system of autonomous power supply using a 20 MW photovoltaic station located at the center of a residential (or administrative) complex on a site with a diameter of 300 meters. The station is surrounded by a green zone about 200 meters wide, beyond which is a ring of multi-storey buildings for approximately 20,000 residents. These buildings are framed by a circular road that connects the center and other city districts, which are arranged concentrically around the center through a system of radial roads.
        </p>
        <p>
          The distribution smart autonomous microgrid for low-voltage direct current transmission is disconnected from the general high-voltage three-phase network and is designed as a system of radial cable lines laid underground. Such a city includes 10-15 districts for 250,000 residents, a small industrial zone, and is supplied with low-voltage direct current electricity with a total capacity of 250 MW. This distribution autonomous microgrid operates without losses, eliminating the need for converter technology and substations by using battery-type power accumulators. The cost of 1 kWh of electricity does not exceed 5 cents, making it at least twice as cheap as conventional electricity.
        </p>
        <p>Currently, this project is at the concept stage.</p>
      </div>

      <div className="div_item_sostav">
        <h2>Projects for Remote Detection of Terrorists</h2>
        <h3>Authors: Master Leonid Katz, Prof. M. Kozlov</h3>
      </div>

      <div className="div_item_sostav">
        <h2>Social Housing Construction Project</h2>
        <h3>Prof. B. Medres, M. Koten, and A. Reif</h3>
        <p>
          Members of the Academy, Prof. B. Medres, M. Koten, and A. Reif, developed an original project for the construction of social housing, which was transferred to the Ministry of Absorption.
        </p>
        <p>Currently, the project is at the concept stage.</p>
      </div>

      <div className="div_item_sostav">
        <h2>Territorial Sea Development Project in Israel</h2>
        <h3>Author: Professor M. Kozlov</h3>
        <p>Currently, the project is at the concept stage.</p>
      </div>

      <div className="div_item_sostav">
        <h2>Project of Ecologically Clean Settlements and Garden Cities</h2>
        <h3>Author: Master Architect A. Notkin</h3>
        <p>Currently, the project is at the concept stage.</p>
      </div>

      <div className="div_item_sostav">
        <h2>Project: A Lifelong Affair</h2>
        <h3>Authors: Y. Iovnovich, Prof. B. Medres, Dr. L. Preygerman, M. Koten, and others</h3>
        <p>
          During the project implementation, the "Regulations on the Procedure for Awarding the Academic Degree of Doctor of Philosophy (Ph.D.)" were released. Two certification councils were formed, and eleven public dissertation defenses were successfully conducted.
        </p>
        <p>
          <a href="//drive.google.com/file/d/0BzQzK8pTyFePbUoyX2liU1k5d28/view?usp=sharing" target="_blank" rel="noopener noreferrer">
            Regulations on the Procedure for Awarding the Academic Degree of Doctor of Philosophy (Ph.D)
          </a>
        </p>
      </div>

      <div className="div_item_sostav">
        <h2>Conferences and Seminars</h2>
        <p>
          The Academy conducts and actively participates in regional and international conferences and scientific seminars, presenting over 100 reports.
        </p>
        <p>
          The most active speakers include:
          <br />
          Prof. M. Kozlov, Prof. A. Sokol, Prof. R. Shurupov, Prof. L. Preigerman, Prof. M. Slonim, Prof. N. Golubev, Master A. Notkin, and many others.
        </p>
        <p>As a result of the conferences, collections of reports have been published.</p>
        <p>
          It is particularly worth noting the international conferences organized and conducted for several years by Academy member Prof. V. Roizman. These conferences, held off-site, are distinguished by high scientific and organizational standards and are conducted in different countries around the world, with a frequency of up to 6-8 conferences per year.
        </p>
      </div>
    </div>
  );
}

export default CreativeActivity;
