import React from 'react';

const AdvancementsInScienceAndTechnology = () => {
  return (
    <div className="div_main">
      <h2>Advancements in Science and Technology</h2>
      <h3>
        Inventions. Research. Discoveries. Latest Developments. Events in the world of science and high technology.
        Recent achievements of modernity.
      </h3>
      <article>
        <p>May, 2019</p>
        <h2>Nobel Prize Laureates of 2018</h2>
        <p>
          <b>As of 2018, more than 892 individuals have become Nobel Laureates. Out of these, 211 individuals
            (approximately 22% - 23%) are Jews or of Jewish descent, although Jews constitute less than 0.2% of
            the Earth's population (1 Jew per 500 people). Jews are the second-largest nationality (after the
            USA) in terms of the number of Nobel Prize winners.</b>
        </p>
        <br />
        <figure style={{ float: 'right', margin: '0 50px 0' }}>
          <img src="images/unt1.jpg" width="250px" alt="Nobel Prize" />
          <figcaption></figcaption>
        </figure>
        <p>
          <a href="//zn.ua/TECHNOLOGIES/nobel-prize-in-physiology-and-medicine-awarded-for-cancer-treatment-295956_.html"
            target="_blank" rel="noopener noreferrer"><b>Nobel Prize in Physiology and Medicine</b></a> was awarded to James P. Allison
          and Tasuku Honjo "for their discovery of cancer therapy by inhibiting negative immune reactions".
        </p>
        <p>
          Researchers have discovered protein molecules PD-1 on the surface of immune cells, which cancer cells
          "block" and become "invisible" to the immune system. Later, therapeutic antibodies were developed that
          block the ability to "activate" PD-1, thereby restoring the body's ability to recognize and destroy
          cancer tumors.
        </p>
        <figure style={{ float: 'left', margin: '50px 50px 20px' }}>
          <img src="images/unt2.jpg" width="250px" alt="Nobel Prize" />
          <figcaption></figcaption>
        </figure>
        <br />
        <p>
          <a href="//zn.ua/TECHNOLOGIES/nobel-prize-in-physics-awarded-for-research-in-laser-physics-296047_.html"
            target="_blank" rel="noopener noreferrer"><b>Nobel Prize in Physics</b></a> was awarded for lasers. Half of the prize was
          awarded to Arthur Ashkin with the citation "for optical tweezers and their application to biological
          systems". The other half of the prize was awarded to Gérard Mourou and Donna Strickland for "their
          method of generating high-intensity ultra-short optical pulses".
        </p>
        <p>
          Optical tweezers allow the use of laser beams as microscopic tweezers, with which one can capture
          individual cells and even atoms and manipulate them. The technology of Mourou and Strickland allows for
          increasing the power of the laser beam.
        </p>
        <br />
        <br />
        <br />
        <figure style={{ float: 'right', margin: '20px 50px' }}>
          <img src="images/unt3.jpg" width="250px" alt="Nobel Prize" />
          <figcaption></figcaption>
        </figure>
        <p>
          <a href="//zn.ua/TECHNOLOGIES/nobel-prize-in-chemistry-awarded-for-discoveries-in-the-field-of-enzyme-evolution-296154_.html"
            target="_blank" rel="noopener noreferrer"><b>Nobel Prize Laureates in Chemistry</b></a> took control over evolution and used
          the same methods - genetic modifications and selection - to create enzymes capable of overcoming
          chemical challenges of humanity".
        </p>
        <p>
          Half of the prize was awarded to Frances Arnold, and the other half to George Smith and Sir Gregory
          Winter. Arnold received the prize for the directed evolution of enzymes, and Smith and Winter - for the
          method of phage display of peptides and antibodies.
        </p>
        <br />
        <br />
        <figure style={{ float: 'left', margin: '20px 50px' }}>
          <img src="images/unt4.jpg" width="250px" alt="Nobel Prize" />
          <figcaption></figcaption>
        </figure>
        <p>
          <a href="//zn.ua/WORLD/nobel-peace-prize-awarded-for-fighting-sexual-violence-in-armed-conflicts-296349_.html"
            target="_blank" rel="noopener noreferrer"><b>Nobel Peace Prize</b></a> was awarded to Denis Mukwege and Nadia Murad with the
          citation "for their efforts to end the use of sexual violence as a weapon of war and armed conflict".
        </p>
        <p>Denis Mukengere Mukwege is best known as the founder and director of Panzi Hospital, which provides
          medical assistance to women who have been victims of gang rapes, often practiced by rebels during the
          conflict in Kivu (DR Congo).
        </p>
        <p>
          Nadia Murad Basee Taha is an Iraqi human rights activist, her work is aimed at bringing to the
          international community's attention the precarious situation of Yazidi women in the territory controlled
          by the Islamic State. In 2014, she became a victim of the persecution of Yazidis by Islamists and was
          turned into sexual slavery, in November of that year she managed to escape, she currently lives in
          Germany. The Nobel Committee's statement mentions that "she showed extraordinary courage in telling her
          own suffering and speaking on behalf of other victims".
        </p>
        <br />
        <figure style={{ float: 'right', margin: '20px 50px' }}>
          <img src="images/unt5.jpg" width="250px" alt="Nobel Prize" />
          <figcaption></figcaption>
        </figure>
        <p>
          <a href="//zn.ua/ECONOMICS/nobel-memorial-prize-in-economic-sciences-awarded-for-296575_.html"
            target="_blank" rel="noopener noreferrer"><b>The Sveriges Riksbank Prize in Economic Sciences in Memory of Alfred
            Nobel</b></a> was awarded to two scientists. The first half of the prize was awarded to William
          Nordhaus "for integrating climate change into long-term macroeconomic analysis". The second half of the
          prize was awarded to Paul Romer "for integrating technological innovations into long-term macroeconomic
          analysis".
        </p>
      </article>
      <article style={{ clear: 'both' }}>
        <p>November, 2017</p>
        <h2>Nobel Prizes of 2017</h2>
        <p>
          Over 116 years of awarding Nobel Laureates, including 2017, 896 individuals have been awarded. Among
          them, 203 are Jews (22.7%), who make up only 0.2% of the world's population. In other words, the
          percentage of Jewish Nobel Laureates is 113 times higher than the percentage of Jews among the world's
          population.
        </p>
        <p>2017 was no exception.</p>
        <p>There were 11 laureates in total this year, including:</p>
        <ul>
          <li>in Economics - 1, USA;</li>
          <li>in Physics - 3, USA;</li>
          <li>in Medicine - 3, USA;</li>
          <li>in Chemistry - 3, including 1 - USA, 1 - Switzerland, 1 - United Kingdom;</li>
          <li>in Literature - 1, USA (Japan).</li>
        </ul>
        <p>Among the 2017 laureates, 4 are Jews, i.e., 36.3%.</p>
      </article>
      <article>
        <h2>In Israel. 2016</h2>
        <p>July, 2016</p>
        <p>
          <ol>
            <li><b>A device</b> has been created that analyzes exhaled air. It allows for the rapid detection of
              malignant tumors of the breast, stomach, colon, lungs, liver. It also diagnoses Parkinson's and
              Alzheimer's disease.
            </li>
            <li><b>A phone translator</b> has been created, allowing people to communicate by phone, even if they
              speak different languages, including Chinese, English, French, German, Spanish, Portuguese, Italian,
              Polish, Hebrew, Russian.
            </li>
            <li><b>An anti-terrorist robot with 360-degree vision</b> has been created, which can see through
              impenetrable walls of buildings. It carries a pistol and also non-lethal means. Equipped with a
              two-way communication head for negotiations with terrorists. Neutralizes terrorists without risking
              the lives of the soldiers controlling it. Weight - 12 kg
            </li>
            <li><b>A device</b> has been invented that projects images from a computer or smartphone onto any
              surface. Controlled by a finger with a worn gadget. Can control drones.
            </li>
            <li><b>Multifocal glasses</b> have been invented, allowing to see both near and distant objects.
            </li>
            <li><b>A display for F16 fighter jet helmets</b> has been developed. Mounted on regular glasses, it
              also projects an unlimited size image from a mobile device.
            </li>
            <li><b>A device</b> has been invented that charges a smartphone in 1 minute.
            </li>
            <li><b>A pocket infrared sensor</b> has been invented, determining the chemical composition and calorie
              content of food, drinks, plants, soil. It also distinguishes various medications, transmitting
              information to a smartphone.
            </li>
            <li><b>A safe driving system</b> has been invented. Using a digital camera and microprocessor, the
              system determines the distance to the object ahead. It can be used in autopilots, i.e., in
              driverless cars.
            </li>
            <li><b>A technology for freezing benign tumors</b> of the breast, kidneys, prostate, liver by a simple
              injection to minus 170 degrees. After thawing, the tumor becomes safe.
            </li>
            <li><b>An exoskeleton for paralyzed people</b> has been invented. With its help, they can stand, walk,
              etc. It is considered the best invention of the year.
            </li>
            <li><b>A system for protecting aircraft from MANPADS missiles</b> has been developed. The system
              directs a thermal tracking device to the missile, capturing it. Then, using a powerful onboard
              laser, the missile's sensors are blinded, rendering it inoperable.
            </li>
            <li><b>A wheel with suspension</b> has been invented, allowing a vehicle to overcome steps, curbs, and
              other obstacles.
            </li>
            <li><b>Glasses for the blind</b> have been invented, which recognize and vocalize text.
            </li>
          </ol>
          <b>In case of an emergency situation in an airplane...</b><br />A global company (its name is kept secret) is
          developing a system that saves passengers and crew in case of an emergency situation in an airplane or
          rocket. The system is surprisingly simple. It consists of the passenger cabin being a separate, airtight
          compartment from the rest of the airplane. Before the flight, the cabin is docked with the rest of the
          airplane using a special device, forming a single unit. In any emergency situation, the cabin, into which
          the crew also moves, automatically or manually detaches from the airplane within fractions of a second,
          deploys two or several parachutes and a cushioning bottom, and makes a soft landing on land or water. This
          completely eliminates injury to the people inside. It is known that a similar system of smaller size has
          long been used in the landing of spacecraft.
        </p>
      </article>
      <article>
        <p>April, 2016</p>
        <ul>
          <li><b>Israeli archaeologists have studied inscriptions,</b> made in Hebrew on clay shards from the
            ancient fortress of "Arad" in 600 BCE, and concluded that ordinary Jews of that ancient era were
            already highly literate;
          </li>
          <li><b>It is known that superconductors</b> become magnets at temperatures below the critical level when
            placed in an electromagnetic field; however, until recently, it was believed that the efficiency of
            this process was very low, as the magnetic field strength of the superconductor was three times
            lower than the magnetic component of the electromagnetic field; recently, scientists have found this
            assumption to be erroneous; it turns out that under certain conditions, the magnetic field strength
            of the superconductor suddenly increases to the level of the electromagnetic field strength; this
            will significantly reduce the cost of superconducting magnets used in X-ray installations, in
            separators of red blood cells, in the automation of docking units of spacecraft, etc.
          </li>
          <li><b>British and German scientists have established</b> that lycopene, found in red tomatoes, protects
            the membranes of living cells from ionizing radiation;
          </li>
          <li><b>American scientists have obtained</b> direct evidence of an increased risk of colorectal cancer
            in vegetarians - by 27% in men and 19% in women.
          </li>
        </ul>
        <h3>Achievements of the Israeli military complex</h3>
        <ol>
          <li><b>At a military technology exhibition</b> in South Korea, the Israeli company "Rafael" demonstrated
            a new laser air defense system, effectively protecting against artillery shells, low-flying
            missiles, and drones;
          </li>
          <li><b>Work has long been underway in the world</b> aimed at making military equipment invisible. The
            greatest achievement in recent years is an invisibility device a few centimeters in size. The
            Israeli company "Eltics Ltd" meanwhile introduced this year a camouflage system that makes
            airplanes, tanks, helicopters, sea vessels, and other equipment invisible.
          </li>
          <li><b>The Israeli company Rafael</b> has begun producing a miniature robot the size of a child's toy
            car, armed with a dozen pencil-sized rocket projectiles, destroying minefields and homemade
            explosive devices.
          </li>
          <li><b>The new Israeli drone</b> can stay in the air for more than 24 hours and reach Iran without
            landing.
          </li>
        </ol>
      </article>
      <br />
      <article>
        <h2>Interesting News</h2>
        <h4 style={{ textAlign: 'right' }}>March 10, 2016</h4>
        <h3>Advancements in Science and Technology</h3>
        <p>As winter always comes "unexpectedly", so Japan has "unexpectedly" made a real breakthrough in
          non-hydrocarbon energy. Look, here's the first swallow:
        </p>
        <figure style={{ float: 'right', margin: '10px' }}>
          <img src="images/unt6.jpg" width="300" alt="Toyota Mirai" />
          <br />
          <figcaption><i>The swallow is named Toyota Mirai</i></figcaption>
        </figure>
        <p>The Japanese automotive giant Toyota has begun mass production of the world's first hydrogen-powered car.
          The new sedan "Mirai" runs only on hydrogen, and instead of harmful emissions, it produces clean water.
          According to the designers, one refueling is enough for 650 km of travel.
        </p>
        <p>The price of the new car in Japan is about $60,000. At the same time, the government of the country
          announced that all buyers of eco-friendly sedans will receive state subsidies of $17,000.
        </p>
        <p>On a full tank, the sedan can travel about 650 km, and the cost of refueling the tank per km is only
          $0.1.
        </p>
        <p>The energy in the unique engine of the car is generated by the oxidation reaction of hydrogen inside an
          electrochemical generator. The power of the electric motor is 136 horsepower.</p>
        <p>Honda also plans to enter the hydrogen car market soon. The company begins sales of the FCV model in
          2016.
        </p>
        <p>If someone did not understand, let me explain: - This is not only the "death" of the oil industry but
          also the end of energy as we know it. Homes will be equipped with autonomous hydrogen fuel generators.
          Not only Sayano-Shushenskaya and all nuclear power plants will become unnecessary, but also all networks
          transmitting electricity. Can you imagine how many unemployed will appear at once? From geologists to
          manufacturers of power electrical equipment? What equipment! Metal will cost pennies...
        </p>
        <p style={{ textAlign: 'right' }}><b>Source: </b><a href="//ria.ru/docs/about/rss.html"
          target="_blank" rel="noopener noreferrer">&nbsp;ria.ru&nbsp;</a>
        </p>
        <br />
        <b>Comment:</b>
        <ol>
          <li>
            <p>The hydrogen engine is, first of all, not a novelty, but a deep old thing. The first internal
              combustion engines, even before gasoline, were hydrogen. Hydrogen was then obtained by
              hydrolysis of water. Hydrolysis, as, by the way, still today, turned out to be a very costly
              enterprise, and when comparatively cheap gasoline appeared, the gasoline engine quickly
              displaced the hydrogen one, and the latter was forgotten for a long time.
            </p>
          </li>
          <li>
            <p>Modern methods of hydrogen production are based on the decomposition of methane, they are
              significantly cheaper than hydrolysis, but do not do without processing the same hydrocarbons,
              heavily polluting the environment. True, it is easier to combat centralized environmental
              pollution by constructing treatment facilities, but this again leads to a significant increase
              in the cost of hydrogen.
            </p>
          </li>
          <li>
            <p>To talk about the use of hydrogen energy in everyday life is absurd, especially today when
              ecologically clean and significantly cheaper solar panels are rapidly being introduced. True,
              solar panels, like, by the way, hydrogen generators, require the use of expensive batteries and
              converters. But, if there is no alternative for hydrogen generators in this sense, then in the
              use of solar energy, another revolution is expected in the coming years. We are talking about
              the fourth generation of phototransducers, nanoantennas, which work in the range of the infrared
              spectrum of light waves, i.e., around the clock, and do not require the use of battery storage.
              Actually, they can already be used today, but so far only in laboratory conditions. Now the
              Americans are working out the technology of their mass production using 3D printers. It is
              expected that all problems will be solved in 5-7 years. So hydrogen generators have no future.
              They will live a maximum of 10 more years and only with their limited use.
            </p>
          </li>
        </ol>
        <p style={{ textAlign: 'right' }}><b>L. Preygerman</b></p>
      </article>
      <article>
        <h4 style={{ textAlign: 'right' }}>March 10, 2016</h4>
        <h2>The editorial board of the magazine IT Business Week (International Edition) about Israeli innovations
          compiled a list of 67 Israeli inventions that changed the world.
          <figure>
            <img src="images/unt7.jpg" width="300" alt="IT Business Week" />
            <br />
            <figcaption><i></i></figcaption>
          </figure>
        </h2>
        <ol>
          <li>
            <h3>The World's Smallest DNA Computer</h3>
            <figure style={{ float: 'right' }}>
              <img src="images/unt8.jpg" width="300" alt="DNA Computer" />
              <br />
              <figcaption><i></i></figcaption>
            </figure>
            <p>In 2002, researchers from the Weizmann Institute in Rehovot presented a programmable molecular
              computing machine consisting of enzymes and DNA molecules instead of traditional silicon
              microchips. On April 28, 2004, Ehud Shapiro, Yaakov Benenson, Binyamin Gil, Uri Ben-Dor, and
              Rivka Adar announced in Nature that they had built a DNA computer with an input-output module
              theoretically capable of diagnosing cancerous tumors at the cellular level and releasing
              anti-cancer drugs after diagnosis. This computer was recorded in the Guinness Book of Records as
              the smallest biological computing device on the planet.
            </p>
          </li>
          <li>
            <h3>DiskOnKey – the First USB Flash Drive in the World</h3>
            <figure style={{ float: 'right' }}>
              <img src="images/unt9.jpg" width="300px" alt="USB Flash Drive" />
              <br />
              <figcaption><i></i></figcaption>
            </figure>
            <p>The first USB flash drives (or simply flash drives) appeared in 2000, invented by employees of
              the Israeli company M-Systems under the leadership of Dov Moran. In April 1999, a patent for a
              flash drive was registered in the USA, and in September 2000, the drive itself was presented.
              The flash drive was named DiskOnKey, and in the USA, it was sold in partnership with IBM and
              bore the logo of the American corporation. Additionally, the company developed and patented the
              True Flash Filing System (TrueFFS), which represented flash memory as a regular PC disk. The
              first flash drive had 8 MB of memory and cost $50. In 2006, M-Systems was acquired by SanDisk
              Corporation for $1.55 billion.
            </p>
          </li>
          <li>
            <h3>Intel 8088, 80386 Processors, Centrino and Core Duo Platforms</h3>
            <figure style={{ float: 'right' }}>
              <img src="images/unt10.jpg" width="300px" alt="Intel Processors" />
              <br />
              <figcaption><i></i></figcaption>
            </figure>
            <p>Today, Intel Corporation is one of the largest employers in Israel. In 1974, Israel became the
              first location outside the United States where Intel decided to create its own R&D center. The
              Haifa division of Intel developed the 8088 processor in 1980, marking the beginning of a new
              technological era. The very Intel 8088 that IBM chose for creating the first IBM PC. The next
              technological breakthrough by Intel was also made in Israel: the Jerusalem branch introduced the
              80386 – the world's first 32-bit processor for the IBM PC platform. In March 2003, the Centrino
              platform was presented. The Intel Pentium M processor had a clock speed of 1.6 GHz and was twice
              as expensive as the 2.8 GHz Pentium 4-M, but it was a real breakthrough for mobile PCs. Intel's
              new strategy was called "right turn," marking a departure from the "megahertz race." The next
              stage of the "right turn" was the introduction of the Core Duo platform, also developed in
              Israel. However, Intel's vice-president Muli Eden notes that "it is much more difficult to
              manage 5 Israelis than 50 Americans, as Israelis constantly argue with you – starting with 'Why
              are you my boss, and not me yours?'"
            </p>
          </li>
          <li>
            <h3>VocalTec – the First VoIP Solution in the World</h3>
            <figure style={{ float: 'right' }}>
              <img src="images/unt11.jpg" width="300px" alt="VocalTec VoIP" />
              <br />
              <figcaption><i></i></figcaption>
            </figure>
            <p>In 1989, Israelis Alon Cohen and Lior Haramati founded VocalTec Communications, becoming pioneers
              in the world of VoIP. VocalTec invented and patented the world's first VoIP "transceiver" – in
              1995, the VocalTec Internet Phone solution appeared. Today, VocalTec continues to hold leading
              positions in the market, with clients including Deutsche Telekom, Telecom Italia, and other
              major telecommunications structures.
            </p>
          </li>
          <li>
            <h3>Lumus – Military Technology in Augmented Reality Glasses</h3>
            <figure style={{ float: 'right' }}>
              <img src="images/unt12.jpg" width="300px" alt="Lumus AR Glasses" />
              <br />
              <figcaption><i></i></figcaption>
            </figure>
            <p>The Israeli company Lumus presented its augmented reality technology, which looks much more
              advanced compared to Google Glass, thanks to an innovative image projection system. Lumus has
              been developing special displays for helmets in F-16 fighters, and now plans to transfer some
              military technologies to "smart" glasses for the consumer market. The unique LOE (Light-guide
              Optical Element) technology was first introduced about two years ago. Lenses were installed on
              regular glasses and allowed projecting an image of unlimited size from a mobile device. For
              example, a user could turn a building wall into a huge canvas for watching movies. Recently, a
              complete concept of Lumus glasses based on Android was presented. They support gesture and voice
              control functions, and are equipped with a camera and the aforementioned LOE projector. However,
              the company is not in a hurry to bring Lumus to the market, considering options for
              collaboration with other companies in this field for joint development of advanced virtual
              reality glasses and wearable gadgets.
            </p>
          </li>
          <li>
            <h3>ICQ – Online Messenger</h3>
            <figure style={{ float: 'right' }}>
              <img src="images/unt13.jpg" width="300px" alt="ICQ Messenger" />
              <br />
              <figcaption><i></i></figcaption>
            </figure>
            <p>The first version of the legendary messenger ICQ was developed in 1996 by four students of Tel
              Aviv University: Arik Vardi, Yair Goldfinger, Sefi Vigiser, and Amnon Amir, who created the
              company Mirabilis. Just six months later, the company reported its first million users, and two
              years later, the ICQ user base was increasing by 1 million approximately every three weeks. In
              1998, Microsoft made an offer to acquire Mirabilis, and the company was already preparing for
              sale, but then the father of one of the ICQ developers – one of the most successful Israeli
              investors, Yossi Vardi – harshly stated that Microsoft should not consider itself the only
              company on earth that wants to acquire ICQ. Vardi was right – in June 1998, Mirabilis became
              part of AOL, which paid $287 million for the startup. In April 2010, the company was sold to the
              Russian investment fund Digital Sky Technologies (DST), and after its reorganization in
              September 2010, ICQ became part of the Mail.Ru Group.
            </p>
          </li>
          <li>
            <h3>Check Point – World Standard in IT Security</h3>
            <figure style={{ float: 'right' }}>
              <img src="images/unt14.jpg" width="300px" alt="Check Point Security" />
              <br />
              <figcaption><i></i></figcaption>
            </figure>
            <p>In 1993, 25-year-old graduate of the elite IDF Unit 8200 Gil Shwed, along with two partners,
              founded the company Check Point. Check Point develops software and hardware security systems,
              including firewalls and virtual private network tools. Today, the company employs over 2,200
              people worldwide and has more than 100,000 clients. All companies from the Fortune 100 and 98%
              from Fortune 500 use Check Point products. Interestingly, the first investor in the company, who
              invested $400,000 in it, was the venture fund BRM Group, whose managing partner Nir Barkat is
              now the mayor of Jerusalem.
            </p>
          </li>
          <li>
            <h3>The Fastest Cisco Router in the World</h3>
            <figure style={{ float: 'right' }}>
              <img src="images/unt15.jpg" width="300px" alt="Cisco Router" />
              <br />
              <figcaption><i></i></figcaption>
            </figure>
            <p>In 2004, Cisco CEO John Chambers proudly announced that the company had revolutionized the
              industry by introducing the world's fastest router, CRS-1, with a throughput of up to 92 Tbit/s.
              Creating this model involved an investment of half a billion dollars and four years of intensive
              work, but the result exceeded all expectations. The initiator of this unique project was Michael
              Laor – an Israeli who had worked for 11 years at Cisco's California office. When Laor decided to
              return home, Cisco did not want to lose one of its leading engineers and made the decision to
              open its first research and development center outside the United States – in Israel. It was
              then that Michael Laor began to promote the idea of creating a powerful operator-class routing
              system, despite active resistance from several managers who believed that such capacities would
              not be in demand. He managed to convince the company's management – and the Israeli branch of
              Cisco began developing the architecture of the new generation. Despite the high cost ($2 million
              for CRS-1 in maximum configuration), the project was commercially successful and raised Cisco to
              a height unreachable for competitors. Today, Cisco's flagship model CRS-X far exceeds the
              performance of CRS-1 and continues to enjoy steady demand among operators.
            </p>
          </li>
          <li>
            <h3>Mobileye – Driving Safety System</h3>
            <figure style={{ float: 'right' }}>
              <img src="images/unt16.jpg" width="300px" alt="Mobileye System" />
              <br />
              <figcaption><i></i></figcaption>
            </figure>
            <p>The driver assistance system Mobileye was developed to increase safety on public roads. The main
              components of the system are a digital camera and a microprocessor, installed under the
              windshield. The image captured by the camera is digitally transmitted to the processor, which
              processes and analyzes the information in real-time — thus determining the distance to objects
              ahead: cars, pedestrians, and even large dogs. The algorithms do not involve optical, laser, or
              radio emission — the final information about the distance is obtained only through the use of
              special software, comparing the current position of the car relative to other traffic
              participants with pre-programmed data on safe distance. Among the company's clients are
              automotive giants Chrysler, Ford, GM, Tesla, Audi, BMW, FIAT, Jaguar, Opel, Renault, Volvo, VW,
              Honda, Mitsubishi, Nissan, and others. In 2014, Mobileye topped the ranking of Israeli exits —
              its IPO became the most successful public offering of an Israeli company in the history of the
              New York Stock Exchange.
            </p>
          </li>
          <li>
            <h3>Indigo Digital Press – the First Color Digital Printing Press</h3>
            <figure style={{ float: 'right' }}>
              <img src="images/unt17.jpg" width="300px" alt="Indigo Digital Press" />
              <br />
              <figcaption><i></i></figcaption>
            </figure>
            <p>The Israeli company Indigo was founded in 1977 by entrepreneur Benny Landa. In 1993, Landa
              presented E-Print 1000 to the world – the first digital printing press that could print color
              images. E-Print 1000 was based on the technology of digital offset printing. Based on Indigo's
              developments, HP Indigo Press digital printing machines were launched into production. In 2002,
              Indigo was acquired by HP Corporation for $830 million. Benny Landa continues to expand his
              portfolio of more than 700 patents issued in many countries around the world, thereby
              strengthening the intellectual property foundation of the companies he founded.
            </p>
          </li>
        </ol>
        <p>Open file <a
          href="//1302e79b-b9cc-eb2c-5ed1-eb900e3dec01.filesusr.com/ugd/92e356_372b2a35e0e040eab7ae7d824bdba3d7.doc?dn=novost1.doc"
          target="_blank" rel="noopener noreferrer">
          <img src="images/msword.jpg" width="30px" title="file in MS Word format"
            alt="in MS Word format" /></a> or go to the website
          <a href="//itbusinessweek.com/" target="_blank" rel="noopener noreferrer">IT Business Week (International Edition)</a>
        </p>
      </article>
      <article>
        <h2>Latest News. 2015</h2>
        <h3>Unusual Abilities of Superconductors</h3>
        <p>A series of new experiments vividly demonstrated to viewers the amazing quantum effects. Objects floating
          without visible support — what could be better for an ordinary person to "touch" quantum physics?
          Scientists from Tel Aviv University (<a href="//www.tau.ac.il/index-eng.html" target="_blank" rel="noopener noreferrer">Tel Aviv
            University</a>) conducted several experiments showing the unusual abilities of superconductors –
          quantum trapping and quantum levitation. Physicists explored how superconducting bodies behave in
          magnetic fields under a number of conditions. This originally scientific work gave rise to an
          interesting applied project <a href="//www.quantumlevitation.com/levitation/Quantum_Levitation.html"
            target="_blank" rel="noopener noreferrer">Quantum levitation</a>.
        </p>
        <h3>Antibacterial Bedding Made of Copper</h3>
        <p>The antibacterial properties of copper have been known since ancient times. But the use of copper in the
          textile industry was first conceived by Israeli Jeff Gabay. His antimicrobial bedding is now advertised
          on all U.S. TV channels. "If you sleep in a 'copper' bed, you wake up young and healthy," says Jeff
          Gabay, head of Cupron Scientific. "This fabric is woven with real natural copper oxide, which, when
          reacting with water, and our body consists of water, releases copper ions," he explains. According to
          its developers, the new fabric is a real hygiene miracle. Copper ions kill all harmful bacteria, all
          viruses, and protect the skin better than any chainmail. Self-sterilizing textiles are now used by U.S.
          special services. Next year, the Israeli startup, in agreement with the army, plans to re-equip its
          soldiers. Tens of thousands of pairs of copper socks are already on their way.
        </p>
        <h3>Israeli Scientists Developed a "Mini-Needle" That Kills Cancer</h3>
        <p>The device, developed by Professor Yona Keisari and Professor Itzhak Kelson, is a miniature needle
          emitting alpha particles. The needle is inserted directly into the tumor, and the emitted alpha
          particles are absorbed by the cells, disrupting their stability and leading to the "destruction" of the
          tumor's genetic apparatus. Researchers say that the device "kills" cancer cells, and the treatment
          process has no side effects. The publication emphasizes that the experiment is still in its very early
          stages, and so far the device has not been tested on humans. However, in experiments on mice, scientists
          have managed to defeat 20 types of cancerous tumors: the device successfully coped with tumors in the
          brain, lungs, pancreas, colon, breast. The report of the Israeli scientists was published in several
          specialized journals. Israeli media report that the scientists have not only patented the new device but
          also created a company at Tel Aviv University responsible for conducting clinical trials. According to
          Israeli media, the device will be tested at Beilinson Hospital in Petah Tikva.
        </p>
        <h3>Heart Valve Transplantation Without Fixings Started in Be'er Sheva</h3>
        <p>The first heart valve transplantation in Israel without sutures was performed at Soroka Hospital in Be'er
          Sheva. The new valve is made from horse heart tissue. Its shape allows it to hold itself in the heart
          without the need for any fixings. This latest method significantly reduces the operation time, as well
          as the period of connecting the patient to artificial blood circulation and ventilation devices. Around
          the world, the incidence of angina and heart failure (both diseases are related to the malfunctioning of
          valves) is increasing. As stated in the department of cardiothoracic surgery at Soroka, working with the
          latest techniques will not only allow more valve transplantation operations but also minimize chest and
          heart incisions.
        </p>
        <h3>Spiral Escalator</h3>
        <p>The moving staircase-escalator, capable of "crawling" in a spiral, named Helixator, has revolutionized
          modern architecture. It is an invention of Israeli engineer Michael David, described in the latest issue
          of the international journal ENGINEER. The helix of the Helixator can be installed in premises or inside
          high-rise structures where it is impossible to install a regular escalator due to space constraints.
          Examples and potential sites for using Michael David's revolutionary invention include the Azrieli
          skyscraper in Tel Aviv and the Eiffel Tower in Paris. The advantages of the Helixator are obvious: the
          test model of the Helixator, assembled in Germany, lifts up to 100 meters and lowers back to 20,000
          people per hour, which is equivalent to the capacity of 15 high-speed elevators simultaneously, but with
          much greater comfort.
        </p>
        <h3>New York City Hall Chooses Technion</h3>
        <article>
          <p>The Technion in Haifa, together with Cornell University in the USA, became the winner of a
            prestigious international competition for the organization of an academic Center of Applied Sciences
            in New York.</p>
          <p>On the evening of December 19, the Mayor of New York, Michael Bloomberg, announced the winners of the
            contest. The New York City Hall announced a competition for the best project of the Center of
            Applied Sciences, which is planned to be built on Roosevelt Island. Two billion dollars have been
            allocated for the creation of the center, reports the New York Times. 17 academic institutions from
            around the world participated in the competition. Having reviewed the applications, the New York
            City Hall found the project presented by Cornell University and the Technion in Haifa to be the most
            interesting and bold. Bloomberg stated that the project combines an amazing structure of
            technological greenhouses with the best interdisciplinary research program in the country.
            Announcing the winners of the contest, the Mayor of New York gave the highest accolade to the
            Israeli Technion, declaring that it was through its efforts that the Jewish state turned into a
            country of innovation and startups. Half of the 120 Israeli companies listed on NASDAQ are led by
            Technion graduates.</p>
          <p>The Center of Applied Sciences is a grand project of Mayor Michael Bloomberg, which is expected to be
            fully completed in thirty years, but the first students will start their studies in the next
            academic year. Bloomberg attaches great importance to the project - the mayor hopes to enter the
            history of New York specifically as the founder of this academic institution, notes Ynet.</p>
          <h3>The Minister of National Infrastructure of Israel: "We will become a regional energy superpower".</h3>
          <p>By the end of this decade, Israel will overcome water shortages through desalination and, on the
            contrary, will start returning huge volumes of life-giving moisture to natural reservoirs. This
            forecast was recently made by the Minister of National Infrastructure Uzi Landau from the podium of
            the "Business of Israel" conference in Tel Aviv. According to the minister's assessment, by 2020
            Israel will generate so much electricity that approximately 20% of it will remain in reserve. Israel
            and Cyprus are therefore now seriously discussing the idea of connecting their power systems via an
            underwater cable. One of the partners will be able to transfer the excess energy to the other, and
            vice versa. Furthermore, as Landau believes, by the end of the decade, natural gas production in
            Israel will reach such a volume that it will be possible to sell blue fuel to the Palestinian
            administration and Jordan. The Minister of National Infrastructure supports the project of creating
            the largest underground gas storage facility in the central part of the country.
          </p>
        </article>
      </article>
      <script src="scripts/main.js"></script>
    </div>
  );
};

export default AdvancementsInScienceAndTechnology;
