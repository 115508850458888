import React from "react";

const Publications = () => {
  return (
    <div className="div_main">
      <h2>Publications of Academy Members</h2>
      <ul>
        <li>
          B.Medres, E. Sapozhnikov, A. Kotliar and M. Bamberger. Automatic Laser welding. Lasers in Engineering. 2012, V.23, pp.209-219.
        </li>
        <li>
          B. Medres and M. Bamberger, "Laser Texturing of Friction Surfaces", Lasers in Engineering Vol. 18 (2008), pp137-144
        </li>
        <li>
          B.Medres, L. Shepeleva and M. Bamberger. Particularities of mixing with melt pool during laser alloying or laser cladding by direct   powder injection. Third International Conference on Mathematical Modeling and Computer Simulation of Material Technologies MMT-2004.        College of Jeuda and Samaria, Ariel, Israel, September 06-10, 2004.       p2451-2455.
        </li>
        <li>
          B.Medres, M. Bamberger, L. Shepeleva. Mathematical modeling for laser
          treatment processes. Third International Conference on Mathematical
          Modeling and Computer Simulation of Material Technologies MMT-2004.
          College of Jeuda and Samaria, Ariel, Israel, September 06-10, 2004.
          p2456-2464.
        </li>
        <li>
          B.S. Medres, G. Rik, L.V. Shepeleva, M. Bamberger, I. Etsion, B.L.
          Mordike, S. Mordike. Cladding of Copper with Modified Hard-Facing
          Alloys Using High Power Lasers. Abstract number 641 ( E4/F1-2-7).
          Abstract for International Conference on Metallurgical Coatings and
          Thin Films, April 10, 2001, ICMCTF 2000, 10-14 April, 2001, San Diego,
          California, USA.
        </li>
        <li>
          L. Shepeleva, B.Medres, W.D. Kaplan, M. Bamberger and A. Weisheit.
          Laser Cladding of Turbine Blades. Surface & Coatings Technology, 125,
          (2000), pp 45-48.
        </li>
        <li>
          L.Shepeleva, B.Medres, W.D. Kaplan, M. Bamberger, M.H. McCay ,T.D.
          McCay. and C.M. Sharp. Laser Induced Cu/Alumina Bonding Microstructure
          and Bond Mechanism. Surface & Coatings Technolog ,125 (2000), pp.
          40-44.
        </li>
        <li>
          B.Medres, L. Shepeleva and M. Bamberger. Liquid Movement in the Melt
          Pool during Laser Alloying of Laser cladding by Direct powder
          injection. Lasers in Engineering, 10, (2000), 305-314.
        </li>
        <li>
          B.Medres, L. Shepeleva, G. Ryk, G. Dehm and M. Bamberger. Laser
          Surface Treatment of Steels with CrB2 and Ni2B Powders. Journal of
          Laser Applications 11,5, pp.216-219, 1999.
        </li>
        <li>
          G.Dehm, B. Medres, L. Shepeleva, C. Scheu, M. Bamberger, B.L. Mordike,
          S. Mordike, G. Ryk, G. Halperin, I. Etsion. Microstructure and
          Tribological Properties of Ni-Based Claddings on Cu Substrates. WEAR,
          225-229(1999),pp 18-26.
        </li>
        <li>
          B.Medres, L. Shepeleva, W.D. Kaplan and M. Bamberger. The
          Effectiveness of the Laser Plasma Material Processes. Lasers in
          Engineering, 9, (1999), pp 205-214.
        </li>
        <li>
          L.Shepeleva, B. Medres, W.D. Kaplan, M. Bamberger, C.M. Sharp, M.H.
          McCay and T.D. McCay. Morphology of Laser Treated Polycrystalline
          -Al2O3. Journal of Laser Applications 11,1, pp.38-41, 1999.
        </li>
        <li>
          M.Bamberger, W.D. Kaplan, B Medres, L.Shepeleva. Calculation of
          Process Parameters for Laser Alloying and Cladding. Journal of Laser
          Applications, 10,1,pp.29-33, 1998.
        </li>
        <li>
          B.Medres, L. Shepeleva and M. Bamberger. Unidirectional Mathematical
          Model for Numerical Estimation of Laser Surface Treatment Conditions.
          Abstract for International Conference on Mathematical Modeling and
          Simulation of Metal Technologies MMT-2000. College of Judea and
          Samaria, Ariel, Israel, November 13-15, 2000).
        </li>
        <li>
          L.Shepeleva, B. Medres, W.D. Kaplan, M. Bamberger,C.M. Sharp, M.H.
          McCay and T.D. McCay. Morphology of Laser Treated Polycrystalline
          -Al2O3. Abstract for Bi-National Israel-Slovenia workshop on Advanced
          Ceramic Systems. June, 28-29, 1999, Israel.
        </li>
      </ul>

      <div className="div_item_sostav">
        <h5 style={{ textAlign: "right" }}>
          <time dateTime="2021-12">December, 2021</time>
        </h5>
        <h3>How the Theory of Relativity Was Born and Died (Part One)</h3>
        <h4>Vilshansky A., Ph.D</h4>
        <a href="publ_how_relativity_was_born_and_died.shtml">
          More details...
        </a>
        <p>
          It is shown that in the Ivanov-Pinchuk interferometer, the photon's
          speed is combined with the emitter's speed; this allows determining
          the absolute speed of the device in space without external references.
          Subsequently, it was found that the detected phenomenon explains the
          "transverse Doppler effect," and the combination of the photon's and
          source's speeds does not directly affect the "redshift" of the
          spectrum of astronomical objects, which is caused by other factors.
        </p>
        <p>
          <i>
            Relativity, photon speed and light speed, transverse Doppler effect,
            Galilean relativity, Ivanov and Pinchuk interferometer.
          </i>
        </p>

        <h3>Einstein. Genius or Charlatan?</h3>
        <h4>Prof. Lev Preigerman</h4>
        <a href="publ_einstein.shtml">More details...</a>
      </div>

      <div className="div_item_sostav">
        <h5 style={{ textAlign: "right" }}>
          <time dateTime="2019-09">September, 2019</time>
        </h5>
        <h3>Fractality and the Universe</h3>
        <h4>Preigerman Lev, Doctor of Physics. Third academic degree, Ph.D.</h4>
        <a href="publ_fract.shtml">More details...</a>
        <p>
          In this article, the author analyzes the concept of fractality that
          arose in mathematics and turned out to be a reflection of the
          fundamentally irregular reality...
        </p>
        <p>
          <i>
            fractality, regularity, fractional dimension, self-similarity, Koch
            curve and snowflake, fractality – an inherent property of being,
            fractality and the theory of evolution, fractality and dark matter
          </i>
        </p>

        <h3>The Topology of Space</h3>
        <h4>Preigerman Lev, Doctor of Physics. Third academic degree, Ph.D.</h4>
        <a href="publ_topol.shtml">More details...</a>
        <p>
          In this article, the author shows that the abstract topological
          Poincaré-Perelman theorem, seemingly far from reality, confirms with
          high certainty the representations...
        </p>
        <p>
          <i>
            topology, manifold, non-Euclidean geometry, theory of relativity,
            three-dimensional sphere, connectivity, Poincaré-Perelman theorem,
            geometrization and symmetrization, infinity, ubiquity, finiteness
          </i>
        </p>
      </div>

      <div className="div_item_sostav">
        <h5 style={{ textAlign: "right" }}>
          <time dateTime="2019-07">July, 2019</time>
        </h5>
        <h3>About the Sea Intellectual Complex of Ashdod</h3>
        <h4>Alexander Kozlov, Mikhail Kozlov - Ph.D.</h4>
        <a href="publ_o_morskom.shtml">More details...</a>
        <p>
          Based on the basic concept of the development of Israel's coastal
          zone, the formation of a local sea intellectual complex of structures
          tailored to the needs and conditions of the city of Ashdod is
          considered.
        </p>
        <p>
          <i>
            Sea basin, beach, marina, surfing, intellectual dam,
            transport-logistics system, sea intellectual complex.
          </i>
        </p>
      </div>

      <div className="div_item_sostav">
        <h5 style={{ textAlign: "right" }}>
          <time dateTime="2019-04">April, 2019</time>
        </h5>
        <h3>
          System Analysis of the Problems of Singularity and the Processes of
          Cognition
        </h3>
        <h4>Lev Moiseevich Preigerman – Ph.D. in Physics</h4>
        <a href="publ_sys_an.shtml">More details...</a>
        <p>
          In this article, the author uses a systemic approach to analyze
          problems that are a consequence of applying a non-systemic
          understanding of singularity in science and puts forward a number of
          hypotheses concerning the fundamental foundations of modern physics
          and cognitive science.
        </p>
        <p>
          <i>
            Concept of singularity, gravitational, cosmological and cognitive
            singularity, law of symmetry, space-time, expansion of the Universe,
            virtuality
          </i>
        </p>
      </div>

      <div className="div_item_sostav">
        <h5 style={{ textAlign: "right" }}>
          <time dateTime="2018">2018</time>
        </h5>
        <h3>Sustainable Housing</h3>
        <h4>Albert Notkin</h4>
        <a href="publ_tom11.shtml">More details...</a>
        <p>
          <b>Abstract</b> Residential complexes made of prefabricated
          standardized large elements on rafts and platforms are intended for
          the development of previously unused areas on the water surface, in
          complex terrains, in desert, mountainous, or hard-to-reach areas. Such
          housing is calculated for both permanent and temporary stays and can
          be in demand for country villages, recreation bases, agricultural and
          fishery cooperatives.
        </p>
      </div>

      <div className="div_item_sostav">
        <h5 style={{ textAlign: "right" }}>
          <time dateTime="2018-10-19">October 19, 2018</time>
        </h5>
        <h3>The Israeli Lyceum, "Smart Money," and Educational Economics</h3>
        <h4>Yuri Finkelstein, Aron Berznitsky, Boris Shtivelman</h4>
        <a href="publ_izr_lic.shtml">More details...</a>
        <p>
          The Israeli Lyceum program has been operating in the educational
          market of our country for over 25 years under various names.
          Initially, it aimed to improve the efficiency of school education
          using different approaches - from result-oriented teacher retraining
          to the use of special educational computer tools and the creation of
          homogenous study groups based on abilities. Today, we believe that we
          have found the philosopher's stone of educational...
        </p>
      </div>

      <div className="div_item_sostav">
        <h5 style={{ textAlign: "right" }}>
          <time dateTime="2018-10-19">October 19, 2018</time>
        </h5>
        <h3>
          Deductive-Systemic Methodology of Teaching Exact Sciences as a
          Condition for Improving the Quality of Education
        </h3>
        <h4>Lev Moiseevich Preigerman – Ph.D. in Physics</h4>
        <a href="publ_deduct_syst_metod.shtml">More details...</a>
        <p>
          The study of exact sciences by methods adopted in global practice is
          characterized by a non-systemic approach. The inductive method of
          teaching them replicates the historically evolved process of science
          development from simple phenomena to complex processes, from the
          specific to the general. As a result, the connection between phenomena
          of various complexities is lost. For example, teaching physics... In
          this article, based on a systemic approach, a deductive methodology
          for studying exact sciences is considered, through which the world is
          studied, starting from general phenomena and processes common to all.
          This, in the author's opinion, will improve the quality of education,
          encourage students to think and make the science under study more
          attractive to them...
        </p>
      </div>

      <div className="div_item_sostav">
        <h5 style={{ textAlign: "right" }}>
          <time dateTime="2018-08-20">August 20, 2018</time>
        </h5>
        <h3>
          On the Need for a Concept of Development of the Mediterranean Coastal
          Territory of Israel and the Chances of Creating an Intellectual Dam
        </h3>
        <h4>
          Mikhail Kozlov - Ph.D., Member of IIADS, Expert at the Institute of
          Integration and Professional Adaptation...
        </h4>
        <a href="publ_damba.shtml">More details...</a>
        <p>
          Let's consider some aspects of objections regarding active development
          of Israel's coastal territory, based on the analysis of the article
          published on 30.08.2018 on the IIADS website by the member of NTA
          "Ecological Imperative" by the honored ecologist of the Russian
          Federation, Prof., Dr. Mikhail Rudnik "On compliance with the
          requirements of 'Israel's Maritime Policy' and the ecological
          requirements of preserving the marine ecosystem 'Concept of
          Development of the Mediterranean Coastal Territory of Israel'
          presented in the article by M. Kozlov, A. Kozlov, and V. Gurevich"
          [1].
        </p>
        <p>
          In our article "Concept of Development of the Mediterranean Coastal
          Territory of Israel" [2] and published in its development, the article
          "The Appropriateness of the Conceptual Approach to the Development of
          the Territorial Sea...
        </p>
      </div>

      <div className="div_item_sostav">
        <h5 style={{ textAlign: "right" }}>
          <time dateTime="2018-05">May, 2018</time>
        </h5>
        <h3>
          Health and Wellness Marine Complex - Balneotherapy as a Component of
          Coastal Development
        </h3>
        <h4>Semen Zlatin</h4>
        <a
          href="//drive.google.com/file/d/1bygZdKUwrgvhxPwJDnVJ2Jd1U88tLFs-/view?usp=sharing"
          target="_blank"
          rel="noreferrer"
        >
          More details...
        </a>
        <p>
          <b>Israel Will Grow by the Sea...</b> The necessity of including in
          the concept of the development of Israel's Mediterranean coastal
          territory the creation and construction of a balneological health and
          wellness complex on the coast is considered.
        </p>
        <p>
          <i>
            <b>Keywords:</b> treatment of joint and spine diseases, injuries of
            the musculoskeletal system, posture disorders, and scoliotic
            disease.
          </i>
        </p>
      </div>

      <div className="div_item_sostav">
        <h5 style={{ textAlign: "right" }}>
          <time dateTime="2018-04">April, 2018</time>
        </h5>
        <h3>
          The Concept of Development of the Mediterranean Coastal Territory of
          Israel
        </h3>
        <h4>Valery Gurevich. Alexander Kozlov. Mikhail Kozlov.</h4>
        <a
          href="//drive.google.com/open?id=1h_BwW9d34k-ypL2QPMXXTqu76v_UsJqK"
          target="_blank"
          rel="noreferrer"
        >
          More details...
        </a>
        <p>
          <b>Israel Will Grow by the Sea...</b> Possible ways of creating
          transportation and industrial infrastructure along Israel's
          Mediterranean coast within the territorial sea, as well as an
          intellectual marine complex for recreation, health, and mass tourism
          are considered.
        </p>
        <p>
          <i>
            <b>Keywords:</b> Sea river, closed marine basin, wave energy, dam,
            artificial islands, ecology.
          </i>
        </p>
      </div>

      <div className="div_item_sostav">
        <h5 style={{ textAlign: "right" }}></h5>
        <h3>
          Proceedings of the Interdisciplinary Scientific Conference «Problems
          of Development of the Territorial Sea of Israel» (November 29, 2018,
          Netanya, Israel)
        </h3>
        <h4></h4>
        <a
          href="//drive.google.com/file/d/1YP7OX1wtvlv0gkkB1AIw3Kmp68pfGnbz/view"
          target="_blank"
          rel="noreferrer"
        >
          More details...
        </a>
        <br />
        <a
          href="//drive.google.com/file/d/1fS0_CePvA5s88dwRgpOEM7ngtQOo2dgM/view"
          target="_blank"
          rel="noreferrer"
        >
          More...(English)
        </a>
      </div>

      <div className="div_item_sostav">
        <h5 style={{ textAlign: "right" }}></h5>
        <h3>
          International Scientific and Practical Conference «Problems and
          Directions of Development of Modern Society» interesting. (September
          20, 2018, Haifa, Israel)
        </h3>
        <h4></h4>
        <a href="publ_confer20sent2018.shtml">More details...</a>
      </div>

      <div className="div_item_sostav">
        <h3>
          Proceedings of the Scientific and Practical Conference
          <br />
          «Environment, Ecology and Society»
          <br />
          Ashdod (Israel), November 2017
        </h3>
        <b>
          Environment, Ecology, and Society
          <br />
          Ashdod, 2017
          <br />
          <br />
          Scientific and Practical Conference
          <br />
          «Environment, ecology and society»
          <br />
          Ashdod (Israel), November 2017
        </b>
        <p>
          This collection contains articles by scientists and specialists from
          Israel and Russia on topics of education, science and technology,
          medicine, and arts, which were presented at the scientific-practical
          conference...
          <br />
          <a
            href="//drive.google.com/file/d/0BzQzK8pTyFePdHQ0QzhHQmxfUUFCQUtwNnpjMnhza2piUW1z/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            Full text...
          </a>
        </p>
        <b>The conference was organized with the support of:</b>
        <ul>
          <li>
            The Municipality of Ashdod (Aliyah and Absorption Department).
            <br />
          </li>
          <li>
            Israeli Independent Academy for Development of Sciences (IIADS)
            <br />
          </li>
          <li>Ashdod Humanitarian-Technical Scientific Center (AHTSC)</li>
        </ul>
        <b> Editorial Board:​ </b>
        <ul>
          <li>
            Chairman V. Boroda, director of INSOLTECH Ltd, head of AHTSC, IIADS
          </li>
          <li>
            Deputy Chairman Prof. B. Medres, Vice-President of IIADS for Science
          </li>
          <li>Responsible Secretary Roman Snits, AHTSC</li>
          <li>
            Head of Section 1 M. Koten, master, head of NII EI, Vice-President
            of IIADS
          </li>
          <li>Head of Section 2 Prof. M. Slonim, IIADS</li>
          <li>
            Head of Section 3 Prof. R. Tartakovskaya, head of medical section
            AHTSC, IIADS
          </li>
          <li>
            Head of Section 4 Prof. S. Shulman, scientific head of AHTSC, IIADS
          </li>
        </ul>
        <br />
        ISBN 978-965-572-455-4
        <br />
        <br />
        © Authors of the articles, 2017
        <br />
        © Editorial Board, 2017
        <br />© Photo, cover, design, Gr. Nisenboym, 2017
      </div>

      <div className="div_item_sostav">
        <h3>
          Electronic version of the proceedings of the interdisciplinary
          scientific conference "Multifactor Approaches to the Formation of a
          Comfortable Environment" Netanya (Israel), 2017
        </h3>
        <b>
          INTERDISCIPLINARY SCIENTIFIC CONFERENCE MULTIFACTOR APPROACHES TO THE
          FORMATION OF A COMFORTABLE ENVIRONMENT PROCEEDINGS
        </b>
        <p>
          The electronic version of the proceedings includes reports by Israeli
          and foreign scientists and specialists in various fields of science,
          technology, and medicine, presented at the interdisciplinary
          scientific conference "Multifactor Approaches to the Formation of a
          Comfortable Environment", Netanya (Israel), November 15, 2017. The
          proceedings are published by decision of the Scientific and Technical
          Council of the Institute of Integration and Professional Adaptation.
          <br />
          <a
            href="//drive.google.com/file/d/0BzQzK8pTyFePNUppLTczVDVLYjNBQnBhdmN4R0ZUempGQlJj/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            Full text...
          </a>
        </p>
        <img
          src="images/pb1.jpg"
          style={{
            float: "right",
            width: "300px",
            marginLeft: "40px",
            marginRight: "140px",
            borderRadius: "15px",
            boxShadow: "3px 3px 8px grey",
          }}
          alt="Publication 1"
        />
        <p>
          Director of the Institute Dr. Yakov Deul
          <br />
          <br />
          Scientific Secretary of the Institute Mr. Dolores Kaplan
          <br />
          <br />
          <b>Editorial Board:</b>
        </p>
        <ul>
          <li>Dr. Lyudmila Gryaznova</li>
          <li>Mr. Valery Gurevich</li>
          <li>Dr. Leon Kovarsky</li>
          <li>Dr. Mikhail Kozlov</li>
          <li>Dr. Lyubov Lerner</li>
          <li>Dr. Alexander Lisnyak</li>
          <li>Mr. Isaac Mogilevsky</li>
        </ul>
        <b>Cover Artwork:</b> Alexander Kozlov
        <br />
        <br />© Authors of the reports
        <br />© Institute of Integration and Professional Adaptation, Netanya
        <br />© Israeli Independent Academy for Development of Sciences
      </div>

      <div className="div_item_sostav">
        <h5 style={{ textAlign: "right" }}>
          <time dateTime="2017-02">February, 2017</time>
        </h5>
        <h3>To the Depths of Truth and Beauty. Autobiographical Sketches</h3>
        <h4>Doctor of Philosophy Galina Agaronova</h4>
        <a href="publ_k_glubinam.shtml">More details...</a>
        <p>
          <i>
            "The Path to Oneself in an Era of Change. Reflections on Business
            and Life. Israel, 2015"
          </i>{" "}
          - this is how the publishing house introduced the new book by Israeli
          author <b>Shlomo Borokhov</b>. We learn about the author from the note
          at the end of the book. Born in Samarkand. In Israel since 1974. For
          many years, Shlomo Borokhov headed major Israeli enterprises, served
          as the executive director of the Zionist Forum... His book "Lessons in
          Business Thinking" (1999) in Russian went through four editions. The
          new book continues the theme started....
        </p>
      </div>

      <div className="div_item_sostav">
        <h5 style={{ textAlign: "right" }}>
          <time dateTime="2017-02">February, 2017</time>
        </h5>
        <h3>Urgent Issues of School Education. Warning Signals.</h3>
        <h4>Doctor of Philosophy Galina Agaronova</h4>
        <a href="publ_nasush_vopr.shtml">More details...</a>
      </div>

      <div className="div_item_sostav">
        <h5 style={{ textAlign: "right" }}>
          <time dateTime="2016-12-06">December 6, 2016</time>
        </h5>
        <div className="div_item_sostav">
          <h3>Physics at a Crossroads</h3>
          <h4>
            Preigerman L.M. - Israel Independent Academy of Development of
            Science. President of the Academy. Doctor of Physics, Ph.D.
          </h4>
          <a href="publ_fiz_na_perep.shtml">More details...</a>
          <p>
            <b>Abstract.</b> In this article, the author examines the strengths
            and weaknesses of the currently recognized standard model of
            elementary particles and analyzes the most widely accepted
            alternative theories in this context. In conclusion, he proposes a
            hypothesis about the possibility of solving the emerged problems
            within the standard model based on the universal law of symmetry.
          </p>
        </div>
        <div className="div_item_sostav">
          <h5 style={{ textAlign: "right" }}>
            <time dateTime="2016-12-06">December 6, 2016</time>
          </h5>
          <h3>Dedicated to the Bright Memory of Grigory Borisovich Okun</h3>
          <h4>Dr. Florya Sclar</h4>
          <p style={{ textAlign: "right" }}>
            <i>
              "What source of reason has dimmed,
              <br />
              What heart has ceased to beat..."
            </i>
          </p>
          <p>
            When the editor of the magazine "Russian Literary Echo" Ms. Asya
            Teplodvorskaya asked me to write an article about Grigory Borisovich
            Okun, it initially seemed strange and even superfluous to me. The
            collection published six years ago and dedicated to the ninetieth
            anniversary of Grigory Borisovich, in my opinion, contains colossal
            exhaustive material and gives, it would seem, a complete picture of
            his personal and creative life. However, after flipping through the
            magazine, I wanted to show the titanic work behind this glamour. To
            draw a psychological portrait of Grigory Borisovich, where the titan
            and the eccentric are intertwined. To reveal his "eccentric wisdom."
            To show him from the inside, as only very close people, his family,
            knew him...
          </p>
          <a href="publ_publ_texts.shtml#2">More details...</a>
        </div>
        <div className="div_item_sostav">
          <h5 style={{ textAlign: "right" }}>
            <time dateTime="2016-07-03">July 3, 2016</time>
          </h5>
          <h3>Scientists and Writers Against Terror.</h3>
          <h4>
            Collection of Works from the Scientific and Practical Conference
          </h4>
          <a
            href="//drive.google.com/file/d/0BzQzK8pTyFePb0ZabV9FS1hvN00/view"
            target="_blank"
            rel="noreferrer"
          >
            More details...
          </a>
          <p>
            At the conference, speeches were given by Deputy Mayor of Ashkelon
            Sofia Beylin, journalist Tsvi Zilber, President of the Israeli
            Independent Academy Dr. Lev Preigerman, Chairman of the Union of
            Russian-speaking Writers of Israel Yuri Mor-Muradov, scientists,
            writers, cultural figures, and public figures. In their reports,
            scientists and writers analyzed the sources and nature of terrorism
            and expressed...
          </p>
        </div>
        <div className="div_item_sostav">
          <h5 style={{ textAlign: "right" }}>
            <time dateTime="2016-06-26">June 26, 2016</time>
          </h5>
          <h3>Convergent Approach: Modern Educational Paradigm</h3>
          <h4>Professor O.E. Baksansky</h4>
          <a
            href="//drive.google.com/file/d/0BzQzK8pTyFePb2RIb3BJNlE5ajA/view"
            target="_blank"
            rel="noreferrer"
          >
            More details...
          </a>
          <p>
            Currently, due to the acceleration of scientific and technological
            progress, we are witnessing the intersection in time of several
            waves of the scientific and technological revolution. In particular,
            we can highlight the information and communication technology
            revolution that started in the 1980s, followed by the
            biotechnological revolution, and the recently begun nanotechnology
            revolution. The rapid progress in cognitive science over the past
            decade also cannot be ignored...
          </p>
        </div>
        <div className="div_item_sostav">
          <h5 style={{ textAlign: "right" }}></h5>
          <h3>"Thought". International Scientific and Information Journal.</h3>
          <img
            src="images/pb3.jpg"
            style={{
              float: "left",
              width: "200px",
              marginLeft: "40px",
              marginRight: "40px",
              borderRadius: "15px",
              boxShadow: "3px 3px 8px grey",
            }}
            alt="Publication 2"
          />
          <p>Published by "Science".</p>
          <div className="div_item_sostav">
            <p>Israel, Ashdod - 2016</p>
            <a href="docs/misl.pdf" target="_blank">
              More details...
            </a>
          </div>
          <div className="div_item_sostav">
            <h5 style={{ textAlign: "right" }}></h5>
            <h3>Asymmetry Saves the World</h3>
            <h4>Dr. Lev Preigerman</h4>
            <a href="publ_asim.shtml">More details...</a>
            <p>
              The unbridled aspiration towards symmetry is a fundamental law of
              Nature, operating at all levels of the material world. On the
              other hand, the higher the degree of symmetry in any system, the
              lower its order, level of organization, and functionality.
              Absolute symmetry is equivalent to complete chaos. This means that
              any material system and the Universe as a whole would be doomed to
              destruction and nonexistence. However, contrary to this, the
              Universe not only exists but is continuously evolving towards
              higher organization. This can only be explained by the existence
              of some force in the Universe that inherently breaks its symmetry,
              countering the material world's tendency towards chaos. Indeed, in
              the middle of the last century, physicists concluded that the
              development of the Universe became possible only due to the
              breaking of the symmetry of its originating singularity. According
              to the modern quantum standard field theory, this was facilitated
              by the so-called Higgs field.
            </p>
            <p>
              This article discusses the mechanism of the functioning of the
              Universe under the influence of the Higgs field.
            </p>
          </div>
          <div className="div_item_sostav">
            <h3>The Facets of a Doctor's Personality: Paths to Perfection</h3>
            <h4>Prof. A.F. Sokol, Prof. R.V. Shurupova</h4>
            <p>
              <b>Abstract:</b>
            </p>
            <p>
              The profession of a doctor holds a unique place among the
              professions created by humanity. As rightfully noted by
              Academician A.V. Reshetnikov [1], "medical-social behavior (in
              particular, of doctors) must be considered in the context of
              adaptation to modern reality." According to his remark,
              "medical-sociological research is especially important for a
              practicing doctor, as understanding their own role and the role of
              the patient helps to reduce the number of errors in predicting the
              sequence of actions."
            </p>
          </div>
          <div className="div_item_sostav">
            <h5 style={{ textAlign: "right" }}></h5>
            <h3>
              The Unknown About the Known. A Series of Popular Science Lectures
              on Geology.
            </h3>
            <h4>Yakov Golodets-Krasilshchikov</h4>
            <p>
              <b>Abstract:</b>
            </p>
            <p>
              Delivered in the winter of 1999 – 2000 at the F. Kaplan Cultural
              Center in Haifa for Russian-speaking repatriates.
            </p>
            <p>
              I have invited you to talk about the Earth. About the Earth as it
              is, i.e., about everything that surrounds us in this world. About
              what we have become so accustomed to that we simply do not pay
              attention to it. It is ours, it is always and everywhere with us,
              it is our reality. And yet, if we think about it, too much of what
              has become familiar to us is either unknown to us or we have
              completely forgotten it. Today, I invite you to talk about this.
              You know that I am a geologist, an explorer of deposits. In 1962,
              I graduated from the Moscow Geological Prospecting Institute named
              after S. Ordzhonikidze and there in 1966, I defended my Ph.D.
              thesis. I have had the opportunity to travel extensively across
              our country. I have been to the Urals, Altai, Kazakhstan, Yakutia,
              the Kola Peninsula, the coastal areas of the Sea of Okhotsk, in
              Crimea, and the Caucasus. I was also in Norilsk. As you can see,
              the geography of my "travels" is quite extensive. In addition, I
              taught at the Moscow Geological Prospecting Institute, the Moscow
              Mining Institute, and the Geological Prospecting Technicum. Of
              course, I saw a lot with my own eyes, paid close attention to many
              things, and understood them as I gained production and life
              experience. Now, I invite you to look at the reality surrounding
              us through my eyes, through the eyes of a geologist and educator.
              Actually, my talks could be called "ENTERTAINING GEOLOGY," but
              such a work was done by our remarkable geologist Academician
              Alexander Evgenievich Fersman, and I will only try to tell you
              about the most important things. I hope that this journey will
              bring certain pleasure. If not, please excuse me, then. I was not
              eloquent enough. I want to add one more thing – all the
              illustrations (schemes and photos) I borrowed from the Internet, a
              powerful means of knowledge. So, let's go!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Publications;
