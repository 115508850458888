import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import CharterDocument from './components/CharterDocument';
import Header from './components/Header';
import Footer from './components/Footer';
import BankDetails from './components/BankDetails';
import Events from './components/Events';
import ScientificCouncil from './components/ScientificCouncil';
import Contacts from './components/Contacts';
import CreativeActivity from './components/CreativeActivity';
import Publications from './components/Publications';
import Home from './pages/Home';
import About from './pages/About';
import './styles/main.css';
import NavBar from './components/NavBar';
import Members from './components/Members';
import CharterRegistration from './components/CharterRegistration';
import ScienceAndSociety from './components/ScienceAndSociety';
import AdvancementsInScienceAndTechnology from './components/AdvancementsInScienceAndTechnology';
import TitansOfScience from './components/TitansOfScience';
import DidYouKnowThat from './components/DidYouKnowThat';
import FromTheLivesOfScientists from './components/FromTheLivesOfScientists';
import Polemics from './components/Polemics';
import ComplianceWithTheRequirements from './components/ComplianceWithTheRequirements';

function App() {
  return (
    <Router>
      <NavBar />
      <Header />
      <div className="App">
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            {/* <Route path="/charter" element={<CharterDocument />} /> */}
            <Route path="/Bank-Details" element={<BankDetails />} />
            <Route path="/creativity" element={<CreativeActivity />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/events" element={<Events />} />
            <Route path="/publications" element={<Publications />} />
            <Route path="/scientific-council" element={<ScientificCouncil />} />
            <Route path="/members" element={<Members />} />
            <Route path="/charter-registration" element={<CharterRegistration />} />
            <Route path="/science-and-society" element={<ScienceAndSociety />} />
            <Route path="/advancements-in-science-and-technology" element={<AdvancementsInScienceAndTechnology />} />
            <Route path="/titans-of-science" element={<TitansOfScience />} />
            <Route path="/did-you-know-that" element={<DidYouKnowThat />} />
            <Route path="/from-the-lives-of-scientists" element={<FromTheLivesOfScientists />} />
            <Route path="/polemics" element={<Polemics />} />
            <Route path="/compliance-with-the-requirements" element={<ComplianceWithTheRequirements />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
