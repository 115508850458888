import React from 'react';
// import logo from '../images/logo.jpg'; // Make sure to add this image to your project

function Header() {
  return (
    <header>
      {/* <div className="logo"> */}
        {/* <img className="logo_img" src={logo} alt="Logo" /> */}
        <div>
      <h1>  ....</h1>
      <h1> ....</h1>
        <p className="header-quote"> 
          "The greatest use of life is to spend it for something that will outlast it."
          <br /> 
          William James.
        </p>
      </div>
      <div className="header-title">
        <h1>Israeli Independent Academy for Development of Science (IIADS)</h1>
        <h1>Израильская Независимая Академия Развития Науки (ИНАРН)&nbsp;&nbsp;</h1>
        <h1 className="h1-hebrew">האקדמיה העצמאית לפיתוח מדע בישראל (אעפמי) </h1>
      </div>
      
    </header>
  );
}

export default Header;