import React from 'react';
// import { Link } from 'react-router-dom';
// import HomePageColumns from './HomePageColumns'; 
// Import images
import imageSostav from '../images/0.jpg';
// import imageYoutube from '../images/youtube_inarn.jpg';
import imageMedres from '../images/pbm.jpg';
import imageKoten from '../images/25.jpg';
import imageShulman from '../images/61.jpg';
import imageTrofimov from '../images/trgg.jpg';
import imageTepman from '../images/52.jpg';
import imageOrobets from '../images/37.jpg';

function Home() {
  return (
    <div className="div_main">
      {/* You might want to convert this to a separate component */}
      {/* <!--#include file="homepage_col_block.html"--> */}
      {/* <HomePageColumns /> */}
      {/* <!--#include file="homepage_col_block.html"--> */}
      <span id="1" className="span-before"> <br /><br /> </span>
      <h2>Israeli Independent Academy for Development of Science (IIADS)</h2>
      <div className="div_academy">
        <p>Members of the Academy are responsible for over 3,000 inventions and discoveries. They have published more than 6,000 scientific works, including books, monographs, teaching aids, methodological guidelines, and scientific articles. They have also completed more than 500 projects of significant economic, educational, organizational, and intellectual importance.</p>
        <p>Since its inception, IIASD scientists have developed approximately 40 projects at various stages of implementation and published over 1,000 books, monographs, textbooks, and scientific articles in the Academy's journals and international journals.</p>
        {/* <ul>
          <li><p>As of December 31, 2021, the Academy has more than 100 members, including professors, doctors, masters, artists, and academicians from various countries.</p></li>
          <li>The Academy has 3 departments and 1 House of Scientists.</li>
        </ul> */}
      </div>
      <div className="div_academy">
        <img src={imageSostav} className="image_sostav" alt="Aron Naumovich Men" />
        <p className="p_sostav"><b></b><br /><br /><b>Aron Naumovich Men</b> (1922-2009) was the initiator of the creation of the Academy and its continuous President from 2002 to 2009. He was a distinguished scientist with a worldwide reputation, a Professor, and a Doctor of Chemical Sciences. An academician of many national academies, he created a new theory of cluster components and founded a scientific school for the development of new materials. For his contributions to the development of science, he was awarded the Golden Medal.</p>
      </div>
      <div className="div_academy">
        <h2>Historical Reference</h2>
        {/* <a href="https://www.youtube.com/embed/t9_hc6idAu0" target="_blank" rel="noopener noreferrer">
          <img src={imageYoutube} alt="Dr. Lev Preigerman about IIASD" style={{float: 'left', width: '300px', margin: '5px 10px 5px 5px'}} />
        </a> */}
        <p><b>The Academy was established in 2002</b> in the State of Israel with the aim of supporting scientists and specialists, primarily among repatriates. Its goals include advancing fundamental and applied scientific research, developing existing and creating new scientific directions, institutes, and schools at various levels, seeking, initiating, and implementing highly effective ideas and projects, new technologies, and alternative solutions. The Academy also assists in the training of highly qualified specialists, publishes scientific works, and promotes science and scientific achievements.<br /></p>
        <p>In 2003, <b>IIADS</b> received the official status of a self-governing non-profit organization, known as an amuta, and was registered as a legal entity in the State of Israel.</p>
        <p>Starting in April 2024, the IIADS proudly announces the establishment of a new branch in the United States.</p>
        <p><b>Academician A.N. Men</b> was the continuous president of the Academy until his death on October 21, 2009. His students work in various cities in Israel, America, Russia, Germany, Ukraine, and the Baltic States.​</p>
      </div>
      <div className="div_academy">
        <h2>Presidium (Council of Amuta) 2022 -2024</h2>
        <div className="div-presidium-container">
          <div className="div-presidium-row">
            <figure className="figure-presidium-item">
              <img src={imageMedres} alt="Professor Boris Medres" className="image-presidium" />
              <figcaption>
                <h3>Prof. Boris Medres</h3> 
                <p>President of IIADS</p>
              </figcaption>
            </figure>
          </div>
          <div className="div-presidium-row">
            <figure className="figure-presidium-item">
              <img src={imageKoten} alt="Master Mikhail Koten" className="image-presidium" />
              <figcaption>
                <h3>Mikhail Koten</h3> 
                <p>Vice President for Environmental Issues. Head of the Scientific and Technical Association "Imperative Ecology"</p>
              </figcaption>
            </figure>
            <figure className="figure-presidium-item">
              <img src={imageShulman} alt="Prof. Shulman" className="image-presidium" />
              <figcaption>
                <h3>Prof. Shulman</h3> 
                <p>Vice President for Fundamental Matters</p>
              </figcaption>
            </figure>
          </div>
          <div className="div-presidium-row">
            <figure className="figure-presidium-item">
              <img src={imageTrofimov} alt="Prof. Herman Trofimov" className="image-presidium" />
              <figcaption>
                <h3>Prof. Herman Trofimov</h3> 
                <p>Vice President for Energy Issues</p>
              </figcaption>
            </figure>
            <figure className="figure-presidium-item">
              <img src={imageTepman} alt="Prof. Leonid Tepman" className="image-presidium" />
              <figcaption>
                <h3>Prof. Leonid Tepman</h3> 
                <p>Vice President for Economics</p>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      <div className="div_academy">
        <h2>Academic Council - 2022-2024</h2>
        <ul>
        <li><p>Prof. O. Baxanskiy</p></li>
              <li><p>Dr. A. Vilshanskiy</p></li>
              <li><p>Dr. A. Goroshko</p></li>
              <li><p>Prof. V. Iskhakov</p></li>
              <li><p>Prof. N. Lyubashevskiy</p></li>
              <li><p>Prof. B. Medres</p></li>
              <li><p>Prof. L. Preigerman</p></li>
              <li><p>Prof. A. Sokol</p></li>
              <li><p>Prof. G. Trofimov</p></li>
              <li><p>Prof. R. Tartakovskaya</p></li>
              <li><p>Prof. L. Tepman</p></li>
              <li><p>Prof. I. Khuzmiev</p></li>
              <li><p>Prof. S. Shulman</p></li>
              <li><p>Prof. R. Shurupova</p></li>
              <li><p>Prof. E. Fedotova</p></li>
              <li><p>Prof. L. Finkel</p></li>
              <li><p>Prof. I. Edelshteyn</p></li>
        </ul>
        {/* <p><Link to="/scientific-council">See attachment</Link></p>
        <p>2022-2024</p> */}
      </div>
      <div className="div_academy">
        <h2>Audit Committee - 2022-2024</h2>
        <ul>
          <li><b>Vladimir Boroda - Chairman</b></li><br />
          <li><b>Prof. Ismail Khuzmiev</b></li><br />
          <li><b>Dr. Boris Finkelstein</b></li><br />
        </ul>
      </div>
      <div className="div_academy">
        <h2>Academy Directorate</h2>
        <div className="div-presidium-container">
          <div className="div-presidium-row">
            <figure className="figure-presidium-item">
              <img src={imageOrobets} alt="Nikolai Nikolaevich Orobets" className="image-presidium" style={{height: '100px'}} />
              <figcaption>
                <h3>Nikolai Orobets</h3> 
                <p>CEO of IIADS</p>
              </figcaption>
            </figure>
            <figure className="figure-presidium-item">
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;