import React from "react";

const ScienceAndSociety = () => {
  return (
    <div className="div_main">
      <h2>Science and Society</h2>
      <article>
        <h5 style={{ textAlign: "right" }}>June, 2021</h5>
        <h3>Now, Life Among Trees</h3>
        <h4>Leonid Finkel</h4>
        <p>
          We live in a remarkable country – the only one in the world where
          there are more forests now than there were in the 20th century. The
          Jewish National Fund has been transforming the desert into the Land of
          "milk and honey" for over a hundred years. Perhaps that is why in
          Israel, Tu BiShvat is celebrated – the Birthday of the Trees. Or
          simply – the New Year for trees…
          <br />
          On June 16 this year, a unique celebration took place in the Ruhama
          forest in the Negev – the opening of the grove of scientist
          repatriates...
        </p>
        <p>
          <a href="life_among_trees.shtml">View the full article</a>
        </p>
      </article>
      <article>
        <h5 style={{ textAlign: "right" }}>April, 2019</h5>
        <h3>Who They Voted For</h3>
        <h4>
          President of the Independent Academy of Sciences of Israel, Professor
          Lev Preigerman
        </h4>
        <p>
          It is known that the founders of Israel were socialists, with
          recognized leaders such as Ben-Gurion, Chaim Weizmann, Golda Meir.
          However, they were sincerely committed to the ideas of Zionism,
          considered Israel as a Jewish democratic state, and rejected any
          rights of Arabs to territorial autonomy, the claims of Arab states to
          the territories of Eretz Israel, and categorically denied any
          possibility of negotiations and compromise with terrorists. This
          allowed...
        </p>
        <p>
          <a
            href="//drive.google.com/file/d/1JE_QdXCvvQWRJzbTipIvoKHE_f5PTxR7/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            View the full article
          </a>
        </p>
      </article>
      <br />
      <article>
        <h5 style={{ textAlign: "right" }}>April, 2019</h5>
        <h3>How Jews Occupy Judea</h3>
        <h4>Doctor of Physics Ph.D, Professor, Lev Preigerman</h4>
        <p>
          In this report, the author analyzes the widespread false narrative
          about Israel's occupation of Palestine. Based on the provisions of
          International Law and existing International Laws, the author shows
          that it is not the Jews who occupy Arab lands, but, on the contrary,
          Arabs have captured a large part of the territory of Palestine, which
          is the sovereign property of the Jewish people. In conclusion, the
          author proposes his version of the solution to the Israeli-Arab
          conflict.
        </p>
        <p>
          Keywords: occupation, International Law, British Mandate, Arab
          charter, Palestinians.{" "}
          <a
            href="//drive.google.com/file/d/1fsvrXxqUPW0NRX4F96wL7dFycfS98-F6/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            View the full article
          </a>
        </p>
      </article>
      <br />
      <article style={{ textAlign: "justify" }}>
        <h5 style={{ textAlign: "right" }}>March 10, 2016</h5>
        <h3>FOR THE CHEKIST DAY</h3>
        <div className="Section1">
          <p
            className="font8"
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              verticalAlign: "baseline",
            }}
          >
            <span style={{ fontFamily: "Impact", color: "#9A3117" }}>
              AGREEMENT &nbsp;XI-1938
            </span>
            <span
              style={{ fontFamily: '"Agency FB"', color: "#1C1C1C" }}
            ></span>
          </p>
          <p
            className="font8"
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              verticalAlign: "baseline",
              textAlign: "start",
              color: "#1C1C1C",
            }}
          >
            &nbsp;
          </p>
          <p
            className="font8"
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              verticalAlign: "baseline",
              textAlign: "start",
              color: "#1C1C1C",
            }}
          >
            &nbsp;
          </p>
          <p
            className="font8"
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              verticalAlign: "baseline",
              textAlign: "start",
              color: "#1C1C1C",
            }}
          >
            &nbsp; &nbsp; &nbsp;
            <b>
              Treaty on cooperation, mutual assistance and joint activities /
              Moscow November 11, 1938
            </b>
            .
          </p>
          <p
            className="font8"
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              verticalAlign: "baseline",
              textAlign: "start",
              color: "#1C1C1C",
            }}
          >
            &nbsp;
          </p>
          <p
            className="font8"
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              verticalAlign: "baseline",
              textAlign: "justify",
              color: "#1C1C1C",
            }}
          >
            &nbsp;
          </p>
          <p
            className="font8"
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              verticalAlign: "baseline",
              textAlign: "start",
              color: "#1C1C1C",
            }}
          >
            &nbsp; &nbsp; &nbsp;The People's Commissariat for Internal Affairs
            of the USSR, hereinafter referred to as the NKVD, represented by the
            Chief of the Main Directorate of State Security, State Security
            Commissar of the 1st Rank Lavrentiy Beria, on the one hand, and the
            Main Security Office of the National Socialist German Workers'
            Party, represented by the Chief of the Fourth Directorate (GESTAPO)
            Heinrich Müller, based on the power of attorney No. 1-448/12-1,
            dated November 3, 1938, issued by the Chief of the Main Security
            Office Reichsführer-SS Reinhard Heydrich, hereinafter referred to as
            GESTAPO, on the other hand, have concluded this general agreement on
            cooperation, mutual assistance, and joint activities between the
            NKVD and GESTAPO. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
          </p>
          <p
            className="font8"
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              verticalAlign: "baseline",
              textAlign: "start",
              color: "#1C1C1C",
            }}
          >
            <b>§ 1</b>
            .<br />
            &nbsp; Clause 1. The Parties see the necessity for developing close
            cooperation between the state security organs of the USSR and
            Germany in the interest of security and prosperity of both
            countries, strengthening good neighborly relations, friendship
            between the Russian and German peoples, and joint activities aimed
            at conducting merciless fight against common enemies who lead a
            systematic policy of inciting wars, international conflicts, and
            enslavement of humanity.
            <br />
            &nbsp; Clause 2. The Parties signing this agreement, see the
            historical necessity of such a decision and will strive to do
            everything to strengthen the influence and power positions of their
            countries in the world without causing mutual harm.
            <br />
            &nbsp; &nbsp; &nbsp; &nbsp; Considering the historical processes in
            the development of international relations, in which the USSR and
            Germany are leading countries, as well as the fact that good
            relations are being established between our governments, friendship
            and cooperation are strengthening between the peoples, while the
            desire of the common enemies of the USSR and Germany is directed at
            dividing good neighborly relations, igniting mistrust, hostility,
            open enmity and revanchist attacks, the NKVD and GESTAPO will
            conduct joint activities in the fight against common enemies and
            will inform the governments of their countries about the results of
            such activities.
            <br />
            &nbsp; Clause 3. Realizing that the recent changes in the world
            present our countries with a unique opportunity to establish a new
            order in the world, …, wishing to give dynamism to relations between
            the USSR and Germany, the parties have agreed on the following:
          </p>
          <p
            className="font8"
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              verticalAlign: "baseline",
              textAlign: "start",
              color: "#1C1C1C",
            }}
          >
            <b>§ 2</b>
            .<br />
            &nbsp; Clause 1. The NKVD and GESTAPO will develop their relations
            in the name of prosperity of friendship and cooperation between our
            countries.
            <br />
            &nbsp; Clause 2. The Parties will conduct a joint struggle against
            common principal enemies:
            <br />
            - International Jewry, its international financial system, Judaism,
            and Jewish worldview;
            <br />
            - Degeneration of humanity, in the name of revitalizing the white
            race and creating eugenic mechanisms of racial hygiene.
            <br />
            &nbsp; Clause 3. The types and forms of degeneration subject to
            sterilization and destruction were determined by the additional
            Protocol No. 1, which is an integral part of this agreement. &nbsp;
          </p>
          <p
            className="font8"
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              verticalAlign: "baseline",
              textAlign: "start",
              color: "#1C1C1C",
            }}
          >
            <b>§ 3</b>
            .<br />
            &nbsp; Clause 1. The Parties will fully support the strengthening of
            &nbsp; principles: socialism in the USSR, National Socialism in
            Germany, and are convinced that one of the foundational elements of
            security is the process of militarizing the economy, the development
            of the military industry, and strengthening the power and capability
            &nbsp;of the armed forces of their states.
            <br />
            &nbsp;Clause 2. The Parties will facilitate the development of
            cooperation in the military field between our countries, and if
            necessary for war, assist in conducting joint intelligence and
            counterintelligence activities on the territory of enemy states.
          </p>
          <p
            className="font8"
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              verticalAlign: "baseline",
              textAlign: "start",
              color: "#1C1C1C",
            }}
          >
            <b>§ 4</b>
            .<br />
            &nbsp; Clause 1. In the event of situations arising, creating a
            perceived threat to our countries by one of the parties, they will
            inform each other and immediately engage in contact to coordinate
            the necessary initiatives and conduct active measures to reduce
            tension and resolve such situations.
          </p>
          <p
            className="font8"
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              verticalAlign: "baseline",
              textAlign: "start",
              color: "#1C1C1C",
            }}
          >
            <b>§ 5</b>
            .<br />
            &nbsp; Clause 1. The Parties attach great importance to the
            development and deepening of &nbsp;professional activities. The
            exchange of experience and meetings, staff delegations from both
            agencies will be carried out on a regular basis.
            <br />
            &nbsp; Clause 2. The leaders of the NKVD and GESTAPO, employees of
            both agencies will have regular meetings for consultations,
            discussion of other measures, contributing to the development and
            deepening of relations between our countries.
          </p>
          <p
            className="font8"
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              verticalAlign: "baseline",
              textAlign: "start",
              color: "#1C1C1C",
            }}
          >
            <b>§ 6</b>
            .<br />
            &nbsp; Clause 1. The Parties will facilitate the expansion and
            deepening of &nbsp;cooperation between our countries in the
            following areas:
            <br />
            - military &nbsp;industry,
            <br />
            - aircraft manufacturing;
            <br />
            - economics;
            <br />
            - finance;
            <br />
            - scientific and technical cooperation;
            <br />
            - energy;
            <br />
            - science and technology;
            <br />- in the realm of secret knowledge, theozoology, theosophy,
            paranormal and anomalous phenomena, influencing social processes and
            the internal life of states.
          </p>
          <p
            className="font8"
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              verticalAlign: "baseline",
              textAlign: "start",
              color: "#1C1C1C",
            }}
          >
            <b>§ 7</b>
            .<br />
            &nbsp; Clause 1. Each party will facilitate, as much as possible, on
            a reciprocal basis, the visa regime for the entry of &nbsp;employees
            of both departments into our countries.
          </p>
          <p
            className="font8"
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              verticalAlign: "baseline",
              textAlign: "start",
              color: "#1C1C1C",
            }}
          >
            <b>§ 8</b>
            .<br />
            &nbsp; Clause 1. The Parties will conclude additional agreements as
            necessary for the implementation of the provisions of &nbsp;this
            agreement.
          </p>
          <p
            className="font8"
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              verticalAlign: "baseline",
              textAlign: "start",
              color: "#1C1C1C",
            }}
          >
            <b>§ 9</b>
            .<br />
            &nbsp; Clause 1. This agreement shall enter into force on the day of
            its signing for a period of five years and will be automatically
            extended for subsequent five-year periods.
            <br />
            &nbsp; &nbsp; &nbsp;The text of the agreement is printed in Russian
            and German in a single copy, each of which has equal force, sealed
            with the signatures and seals of representatives of the NKVD and
            GESTAPO.
            <br />
            &nbsp; &nbsp; &nbsp; The Russian text of the agreement remains with
            the NKVD, the German - with the GESTAPO.
          </p>
          <p
            className="font8"
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              verticalAlign: "baseline",
              textAlign: "start",
              color: "#1C1C1C",
            }}
          >
            &nbsp; &nbsp; &nbsp; Executed in Moscow, November 11, 1938. at 3:40
            PM.
            <br />
            &nbsp;Signatures of the parties:
            <br />
            HEAD OF THE MAIN DIRECTORATE OF STATE SECURITY OF THE PEOPLE'S
            COMMISSARIAT FOR INTERNAL AFFAIRS OF THE USSR,
            <br />
            STATE SECURITY COMMISSAR OF THE 1ST RANK
            <br />
            L. BERIA
            <br />
            REPRESENTATIVE OF THE HEAD OF THE MAIN SECURITY OFFICE OF GERMANY,
            <br />
            STANDARTENFÜHRER SS
            <br />
            H. MÜLLER.
          </p>
          <p
            className="font8"
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              verticalAlign: "baseline",
              textAlign: "start",
              color: "#1C1C1C",
            }}
          >
            &nbsp;*************************************
          </p>
          <p
            className="font8"
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              verticalAlign: "baseline",
              textAlign: "start",
              color: "#1C1C1C",
            }}
          >
            In addition to this terrible agreement!
            <br />
            In the USSR, until 1938, Hitler's pilots, tankers, and artillerymen
            were trained on the test sites and conducted joint staff exercises!
            <br />
            THEY ATTACKED POLAND TOGETHER WITH THE NAZIS!
            <br />
            THEY HELD A JOINT MILITARY PARADE IN BREST TO CELEBRATE VICTORY OVER
            POLAND!
            <br />
            THE BREST FORTRESS WAS INITIALLY ASSAULTED BY THE GERMANS, BUT WHEN
            THE SOVIET FORCES APPROACHED, THE GERMANS YIELDED TO THE SOVIETS AND
            THE SOVIETS HEAVILY BOMBED BREST FOR A WEEK! POLISH PRISONERS WHO
            SURRENDERED WERE SHOT!
          </p>
        </div>
      </article>
    </div>
  );
};

export default ScienceAndSociety;
