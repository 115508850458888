// src/components/LoginModal.js
import React from 'react';
import '../styles/LoginModal.css';

const LoginModal = ({ show, handleClose }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <h2>Login</h2>
        <form>
          <div className="form-group">
            <label htmlFor="username">Username:</label>
            <input type="text" id="username" name="username" required />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input type="password" id="password" name="password" required />
          </div>
          <div className="button-group">
            <button type="submit">Submit</button>
            <button type="button" onClick={handleClose}>Close</button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default LoginModal;

