import React from 'react';

const ScientificCouncil = () => {
  return (
    <div className="div_main">
      <header>
        {/* Include the content from "header.html" here */}
      </header>

      <nav>
        {/* Include the content from "menu.html" here */}
      </nav>

      <div className="div_academy">
        <h2>MEMBERS OF THE IIADS SCIENTIFIC COUNCIL</h2>
        <div className="council-members">
          <div>
            <ol>
              <li><p>Prof. O. Baxanskiy</p></li>
              <li><p>Dr. A. Vilshanskiy</p></li>
              <li><p>Dr. A. Goroshko</p></li>
              <li><p>Prof. V. Iskhakov</p></li>
              <li><p>Prof. N. Lyubashevskiy</p></li>
              <li><p>Prof. B. Medres</p></li>
              <li><p>Prof. L. Preigerman</p></li>
              <li><p>Prof. A. Sokol</p></li>
              <li><p>Prof. G. Trofimov</p></li>
            </ol>
          </div>

          <div>
            <ol start="10">
              <li><p>Prof. R. Tartakovskaya</p></li>
              <li><p>Prof. L. Tepman</p></li>
              <li><p>Prof. I. Khuzmiev</p></li>
              <li><p>Prof. S. Shulman</p></li>
              <li><p>Prof. R. Shurupova</p></li>
              <li><p>Prof. E. Fedotova</p></li>
              <li><p>Prof. L. Finkel</p></li>
              <li><p>Prof. I. Edelshteyn</p></li>
            </ol>
          </div>
        </div>
      </div>

      <footer>
        {/* Include the content from "footer.html" here */}
      </footer>
    </div>
  );
};

export default ScientificCouncil;
