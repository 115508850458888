import React from "react";

const TitansOfScience = () => {
  return (
    <div className="div_main">
      <h2>Titans of Science</h2>
      <article>
        <h5 style={{ textAlign: "right" }}>April, 2016</h5>
        <h3>The Musketeers of Theoretical Physics</h3>
        <p>
          One of the centers of scientific thought in the young Soviet Republic,
          along with the Leningrad Physical-Technical Institute headed by A. F.
          Ioffe (LFTI), in the 1920s was the Leningrad University (LGU). A group
          of physicists with the eccentric name "Jazz Band" was very popular
          among the students of the university. Its members organized seminars
          on theoretical physics, debates, poetry evenings, humorous skits, etc.
          The founders of the jazz band...
          <a
            href="//1302e79b-b9cc-eb2c-5ed1-eb900e3dec01.filesusr.com/ugd/92e356_8d0da6751e4241e9849a02a0989dc20a.docx?dn=The%20Musketeers%20of%20Theoretical%20Physics.docx"
            target="_blank"
            rel="noopener noreferrer"
          >
            More
          </a>
        </p>
      </article>
      <br />
      <article>
        <h3>Alexander Emmanuilovich NUDELMAN</h3>
        <h4>
          Jew, who armed pilots, tankmen, and ophthalmologists
          <br />
          On the 100th anniversary of Alexander Emmanuilovich Nudelman
        </h4>
        <p>
          During the Great Patriotic War, fighter planes armed with the NS-37
          rapid-fire cannon were not afraid to engage in air combat with any
          number of enemy aircraft. One hit from a projectile was enough to
          disintegrate the enemy plane....
          <a
            href="//docs.google.com/document/d/1KIK68dLfK9F_Kot0ci9BjF_ytCwYCtiCQj-NA7Htj5M/edit"
            target="_blank"
            rel="noopener noreferrer"
          >
            More
          </a>
        </p>
      </article>
      <br />
      <article>
        <h3>Alexey Abrikosov, a student of Landau</h3>
        <p>
          Abrikosov once said: "I have never been a fan of the term 'Russian
          physics,' but, nevertheless, many outstanding works are signed with
          Russian names." Today, June 25, is the birthday of the famous
          physicist, the 2003 Nobel Prize laureate...
          <a
            href="//docs.google.com/document/d/1TtZdXi2BRNtgUQpuTfsBI2rY8pdohxZvT5mLu5QnbjY/edit"
            target="_blank"
            rel="noopener noreferrer"
          >
            More
          </a>
        </p>
      </article>
      <br />
      <article>
        <h4>Yuri Okunev</h4>
        <h3>PRINCETON IMAGES: PHYSICS - EINSTEIN - JUDAISM</h3>
        <h4>
          Lecture at the LIMMUD Educational Organization Conference
          <br />
          Princeton, May 12, 2012
        </h4>
        <p>
          When I was asked to give a presentation on the topic indicated in the
          title, I almost immediately agreed - the topic seemed very interesting
          and even containing a certain non-trivial challenge. Indeed, this
          great triad of Physics–Einstein–Judaism emits enormous intellectual
          energy, this topic is immense and hardly fully comprehensible, and all
          its components have been thoroughly researched in thousands of
          specialized works.
        </p>
        <p>
          To raise such a large-scale topic is not in my power, and, perhaps,
          only at the junction of all three components of this great triad will
          it be possible to find something not fully explored. To reflect on the
          topic of the triad is probably beneficial for every thinking person,
          and I, not without hesitation, decide to present a few thoughts on the
          problems at the intersection between the great scientist, on the one
          hand, and science, religion, and Judaism, on the other...
          <a
            href="//docs.google.com/document/d/1i9sr9SqmG9XGonjv9VLFqJqXYoI0AzFFAUugwtVSqMI/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            More
          </a>
        </p>
      </article>
      <br />
      <article>
        <h3>Computer Genius. Beginning of the Career</h3>
        <p>
          Sergey Brin was born in Moscow on August 21, 1973, in a Jewish family,
          a family of hereditary mathematicians. His father, like his
          grandfather - Israel Abramovich, were candidates of physical and
          mathematical sciences, working at the research institute of the
          Academy of Sciences of the USSR. The path of Sergey's father, Mikhail
          Israilevich, to science was quite thorny. He also showed an aptitude
          for mathematics, and after graduating from Moscow University, he
          applied for postgraduate studies. However, the party committee was
          against it. Then the patronymic - Israilevich - was like a red rag to
          a bull. Mikhail Israilevich managed without postgraduate studies. He
          wrote his candidate dissertation himself...
          <a
            href="//docs.google.com/document/d/1kFiIDWG4BY5azUwZYvliCFy3EtClDAQnlmyRU2Fu3Ss/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            More
          </a>
        </p>
      </article>
      <br />
      <script src="scripts/main.js"></script>
    </div>
  );
};

export default TitansOfScience;
