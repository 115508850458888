import React from 'react';

const Contacts = () => {
  return (
    <div className="div_main">
      <div className="div_academy">
        <h2>FURTHER RELATIONSHIP</h2>
        <p style={{ fontSize: '18pt', margin: '0 50px 0', textIndent: '0' }}>
          Address of the Academy - 2/66, Eshkolot str., Nesher 3668022., Israel
          <br />
          tel: +972-545904005
        </p>
      </div>
      <div className="div_academy">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29266.618206487256!2d35.03128910058207!3d32.77366815224918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151db0774c22044d%3A0xbc0834a1d2ea7cc7!2sEshkolot%202%2C%20Nesher!5e0!3m2!1sru!2sil!4v1591102995705!5m2!1sru!2sil"
          width="425"
          height="318"
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>
    </div>
  );
};

export default Contacts;
