import React from "react";

function About() {
  return (
    <div>
      <h2>About ISRA Science</h2>
      <p>Information about our project, goals, and team.</p>
    </div>
  );
}

export default About;
