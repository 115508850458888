import React from 'react';

const CharterRegistration = () => {
  return (
    <div className="div_main">
      <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'center', textIndent: '0' }}>
        <a href="/charter" style={{ textAlign: 'center' }}>
          <figure>
            <img src="images/ustav.jpg" style={{ width: '150px', border: '1px solid #ccc', boxShadow: 'none' }} alt="Charter and Registration Certificate" />
            <figcaption>
              <b>1. Charter and Registration Certificate</b>
            </figcaption>
          </figure>
        </a>
        <a href="amuta.shtml" style={{ textAlign: 'center' }}>
          <figure>
            <img src="images/amuta.jpg" style={{ width: '150px', border: '1px solid #ccc', boxShadow: 'none' }} alt="Regulations on the Procedure for Applying the Charter" />
            <figcaption>
              <b>2. Regulations on the Procedure for Applying the Charter</b>
            </figcaption>
          </figure>
        </a>
      </div>
    </div>
  );
};

export default CharterRegistration;
