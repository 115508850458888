import React from 'react';
import members from '../data/membersData'; // Adjust the path as necessary

const Members = () => (
  <div>
    {members.map((member, index) => (
      <div className="div_item_sostav" key={index}>
        <h3>{member.name}</h3>
        {member.img && <img src={member.img} className="image_sostav" alt={member.name} />}
        <p className="p_sostav">
        {member.born && <><b>{member.born}</b><br /></>}
          {member.title && <><b>Title:</b> {member.title}<br /></>}
          {member.titles && (
            <>
              <b>Titles:</b>
              {Array.isArray(member.titles) ? (
                <ul>
                  {member.titles.map((title, i) => <li key={i}>{title}</li>)}
                </ul>
                ) : (
                <span>{member.titles}<br /></span>
                )}
            </>
            )}
          {member.education && <><b>Education:</b> {member.education}<br /></>}
          {member.specialization && (
            <>
              <b>Specialization:</b> 
              {Array.isArray(member.specialization) ? (
                <ul>
                  {member.specialization.map((spec, i) => <li key={i}>{spec}</li>)}
                </ul>
              ) : (
                <span>{member.specialization}<br /></span>
              )}
            </>
          )}
          {member.specializations && (  
            <>
              <b>Specializations:</b>
              {Array.isArray(member.specializations) ? (
                <ul>
                  {member.specializations.map((spec, i) => <li key={i}>{spec}</li>)}
                </ul>
              ) : (
                <span>{member.specializations}<br /></span>
              )}
            </>
          )}
          {member.dissertation && <><b>Doctoral Dissertation:</b> {member.dissertation}<br /></>}
          {member.publications && (
            <>
              <b>Publications:</b>
              {Array.isArray(member.publications) ? (
                <ul>
                  {member.publications.map((pub, i) => <li key={i}>{pub}</li>)}
                </ul>
              ) : (
                <span>{member.publications}<br /></span>
              )}
            </>            
          )}
          {member.qualification && <><b>Qualification:</b> {member.qualification}<br /></>}
          {member.qualifications && (
            <>
              <b>Qualifications:</b>
              {Array.isArray(member.qualifications) ? (
                <ul>
                  {member.qualifications.map((qual, i) => <li key={i}>{qual}</li>)}
                </ul>
              ) : (
                <span>{member.qualifications}<br /></span>
              )}
            </>
          )}
          {member.notableAchievements && (
            <>
              <b>Notable Achievements:</b>
              <ul>
                {member.notableAchievements.map((ach, i) => <li key={i}>{ach}</li>)}    
              </ul>
            </>
          )}
          {member.research && (
            <>
              <b>Research:</b>
              <ul>
                {member.research.map((res, i) => <li key={i}>{res}</li>)}
              </ul>
            </> 
          )}
          {member.background && (
            <>
              <b>Background:</b>
              <ul>
                {member.background.map((bg, i) => <li key={i}>{bg}</li>)}
              </ul>
            </>
          )}
          {member.mainResearchDirections && (
            <>
              <b>Main Research Directions:</b>
              <ul>
                {member.mainResearchDirections.map((dir, i) => <li key={i}>{dir}</li>)}
                </ul>
            </>
            )}
            {member.career && (
                <>
                <b>Career:</b>
                <ul>
                {member.career.map((car, i) => <li key={i}>{car}</li>)}
                </ul>
                </>
                )}
               
          {member.accomplishments && (
            <>
              <b>Accomplishments:</b>
              <ul>
                {member.accomplishments.map((acc, i) => <li key={i}>{acc}</li>)}
              </ul>
            </>
          )}
          {member.experience && (
            <>
              <b>Experience:</b>
              <ul>
                {member.experience.map((exp, i) => <li key={i}>{exp}</li>)}
              </ul>
            </>
          )}
          {member.publishedWorks && <><b>Published Works:</b> {member.publishedWorks}<br /></>}
          {member.currentActivity && <><b>Current Activity</b> {member.currentActivity}<br /></>}
          {member.additionalActivities && <><b>Additional Activities</b> {member.additionalActivities}<br /></>}
          {member.careerHighlights && (
            <>
              <b>Career Highlights:</b>
              <ul>
                {member.careerHighlights.map((highlight, i) => <li key={i}>{highlight}</li>)}
              </ul>
            </>
          )}
          {member.memberships && (
            <>
              <b>Memberships:</b>
              <ul>
                {member.memberships.map((membership, i) => <li key={i}>{membership}</li>)}
              </ul>
            </>
          )}
          {member.role && (
            <>
              <b>Role:</b>
              {Array.isArray(member.role) ? (
                <ul>
                  {member.role.map((role, i) => <li key={i}>{role}</li>)}
                </ul>
              ) : (
                <span>{member.role}<br /></span>
              )}
            </>
          )}
          {member.roles && (
            <>
              <b>Roles:</b>
                {Array.isArray(member.roles) ? (
                    <ul>
                        {member.roles.map((role, i) => <li key={i}>{role}</li>)}
                    </ul>
                    ) : (
                    <span>{member.roles}<br /></span>
                    )}
            </>
            )}
          {member.posthumous && <><b>{member.posthumous}</b><br /></>}
          {member.members && (
            <>
              <b>Members:</b>
              <ul>
                {member.members.map((mem, i) => <li key={i}>{mem}</li>)}
              </ul>
            </>
          )}
          {member.posthumouss && (
            <>
              <b>Posthumously:</b>
              <ul>
                {member.posthumouss.map((post, i) => <li key={i}>{post}</li>)}
              </ul>
            </>
          )}
        </p>
      </div>
    ))}
  </div>
);

export default Members;