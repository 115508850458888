// src/data/membersData.js

const members = [
    {
      name: 'Dr. Galina Borisovna Agaronova',
      title: ' Doctor of Philosophy in Philology',
      specializations: ' Literary Scholar, Literary Critic, Writer, Poet',
      notableAchievements: [
        'Awarded the Golden Medal for her contribution to Russian literature and culture in Israel (2011)',
'Author of 7 books and numerous articles on Russian literature',
'Organizer and host of the literary salon of Professor G.B. Okunya',
'Executive secretary of the journals "Thought" and "Science"',
      ],
      role: ' Active member of the Academy with voting rights',
    //   img: 'images/1.jpg',
    },
    {
      name: 'Acad. Valeriy Alexandrovich Akinin',
      title: ' Academician',
    //   education: ' Education: Graduated from Bauman Moscow State Technical University (1972)',
        specializations: ' Space, Aviation, Special Equipment, Nanotechnology, Cognitive Activity',
      accomplishments: [
        'Accomplishments:',
        'Authored over 48 scientific works and 32 inventions',
        'Published 200 articles in journals and other periodicals',
        'Fields of expertise: Space, aviation, special equipment, nanotechnology, cognitive activity',
        'Initiator of the first student design squads in the country',
        'Developed an artillery stand, a two-seater ekranoplan, and several aircraft recognized at international NTI exhibitions',
        'Innovated a new method of jet interaction to enhance aircraft efficiency',
      ],
      role: ' Member of the Academy',
    //   img: 'images/2.jpg',
    },
    {
      name: 'Svetlana Apartseva',
      education: 'B.A. in Psychology',
      specializations: ' Psychology, alternative medicine, homeopathy, alternative psychology',
      experience: [
        'Psychology teacher',
        'Educational psychologist',
        'Business consultant for enterprise personnel management',
        'Psychologist-trainer',
      ],
      role: 'Member of the audit committee',
    //   img: 'images/3.jpg',
    },
    {
      name: 'Prof. Oleg Evgenyevich Baksansky',
      title: ' Doctor of Philosophy, Professor',
      specializations: ' Epistemology, Cognitive Science, Methodology of Natural Science Knowledge (especially Physics)',
          dissertation: '"The Unity of Scientific and Social Representations of Knowledge" (09.00.01 – Ontology and Theory of Knowledge)',
      publishedWorks: 'Over 300 works including university textbooks, monographs, methodological manuals, and articles',
      role: 'Active member of the Academy with voting rights',
    //   img: 'images/4.jpg',
    },
    {
      name: 'Dr. Nikolai Pavlovich Balaban (1943-2015)',
      title: ' Ph.D., Associate Professor',
      specializations: 'Energy-Mechanical Complexes of Mining Production, Mine Transport, Mining Machines, Destruction of Rocks and Solid Materials by Jet Method, Mathematics (Exact solutions of differential equations)',
            //   education: 'Graduated with honors from Dnepropetrovsk Mining Institute (DGI), Ukraine (1969)',
      accomplishments: [
       ' Authored two inventions',
       ' Published over 250 articles and 2 monographs',
        'Member of the New York Academy (2005) and Gesellschaft für Angewandte Mathematik und Mechanik e.V. (GAMM) since 2006',
        'Member of the Council of IIADS and the Presidium of the Academy',
        'Scientific Secretary of IIADS',
        'Chief editor of the journal "Scientific Notes" and the academy\'s website',
      ] ,     
      careerHighlights: [
        'Developed methods and means of electromagnetic braking of mine trains',
        'Authored over 250 articles, 2 monographs, numerous methodological and educational aids',
        'Worked in mathematics on exact solutions of differential equations in practical problems',
      ],
      memberships: [
        'New York Academy (2005)',
        'Gesellschaft für Angewandte Mathematik und Mechanik e.V. (GAMM) since 2006',
      ],
       posthumous: 'Legacy: Posthumously included in the nominal composition of IIADS',
      img: 'images/5.jpg',
    },
    {
        name: 'Master Vladimir Boroda',
        education: 'MSEE from Ben-Gurion University (BGU), Beer-Sheva',
        specializations: ' Electronic Engineering, Measurement and Control Equipment, Information Theory',           
        qualifications: ' Design Engineer, System Engineer',
         accomplishments: [
            'Author of two patented inventions',
            'Published three scientific works',
         ],
         role: ' Member of the Council of IIADS, Head of the Ashdod branch of IIADS',
        //  img: 'images/8.jpg',
    },
    {   
        name: 'Grigory Alexandrovich Branopolsky',
        specialization: ' Theater Studies, Arts Management',
        experience: [   
            ' Specialist in circus, theatrical, and musical arts',
' Staged plays, concerts, circus programs, musical, television, and radio works',
' Organized show business events and tours',
' Contributed to preserving and promoting Jewish traditions and Israeli culture',
        ],
        role: ' Active member of the Academy',
        // img: 'images/9.jpg',
    },
    {
        name: 'Dr. Mark Borisovich Brook',
        // born: 'June 2, 1928, Petah Tikva, Israel',
        title: ' Candidate of Technical Sciences',
        specializations: ' Electrovacuum Technology, Heat Engineering, Steam-Pipe Installations',
        accomplishments: [  
            'Chief editor of the journal "Academy Bulletin. Scientific Notes"',
'Author of 11 scientific works, including 1 invention and 1 book',
'Co-author of the textbook "Course of Modern Physics" in 2 volumes',
        ],
        role: ' Active member of the Academy with voting rights',
        // img: 'images/10.jpg',
    },
    {
        name: 'Dr. Alzhimantas Kazisovich Bubulis',
        // img: 'images/11.jpg',
        // born: '1947, Kaunas, Lithuania',
        title: ' DR.HABY',
        specialization: ' Mechatronics',
        qualification: ' Electromechanical Engineer',
        accomplishments: [
          'Author of over 77 publications, 3 monographs, and 1 textbook',
          'Holder of 71 inventions, including 11 patents'
        ]
      },
      {
        name: 'Dr. Sergey Ivanovich Buravlev',
        // img: 'images/12.jpg',
        // born: '1952, St. Petersburg, Russia',
        specialization: ' Management of Complex Systems',
        // position: 'Head of the Department "Industrial and Environmental Safety" at Petersburg State University of Aerospace Instrumentation',
        accomplishments: [
          'Author of over 57 scientific works, including 7 patents and 12 textbooks/methodological developments'
        ]
      },
      {
        name: 'Prof. Natalia Evgenyevna Vazheevskaya',
        // img: 'images/13_1.jpg',
        // img: 'images/13_2.jpg',
        // born: 'January 28, 1942',
        title: ' Doctor of Pedagogical Sciences (2003), Professor (2005)',
        // education: 'Graduated from the Physics Department of Moscow Pedagogical University (1964)',
        // career: [
        //   'Physics teacher abroad (1964–1965) and in Moscow (1965–1968)',
        //   'Postgraduate student and researcher at the Research Institute for Content and Teaching Methods (1968–1983)',
        //   'Associate Professor (1983–2002) and Professor (since 2002) at Moscow Pedagogical University',
        //   'Head of the Department of Pedagogy at the Faculty of Additional Professional Education of Teachers at MMA (since 2004)'
        // ],
        research: [
          'Focus on educational content, teaching methods of scientific cognition, development of thinking and worldview in students',
          'Author of 4 textbooks for school students and 1 textbook for pedagogical universities',
          'Contributor to federal educational programs and the development of control measurement materials for the Unified State Exam (USE) in Russia'
        ],
        accomplishments: [
          'Author of more than 150 scientific and scientific-methodological works'
        ],
        mainResearchDirections: [
          'Theory and methodology of teaching in school and university',
          'Methodology of natural science knowledge',
          'Problems of educational content and goals',
          'Modern foundations of didactics'
        ]
      },
      {
        name: 'Dr. Alexander Naumovich Vilshansky',
        // img: 'images/v1.jpg',
        // born: 'May 5, 1939',
        title: ' Candidate of Technical Sciences, Engineer',
        specialization: ' Radiophysics',
        career: [
        //   'Senior Research Fellow, Forestry Laboratory of the USSR Academy of Sciences, Moscow',
          'Engineer at the Haifa Technion Institute of Space Research, Israel',
          'Retired since 2011'
        ],
        accomplishments: [
          'Author of 18 inventions',
          'Published over 57 works',
          'Research topics include the theory of gravitation and earthquake prediction'
        ],
        role: 'Active member of the Academy with voting rights',
        // location: 'Haifa, Israel'
      },
      {
        name: 'Baruch Wind',
        // img: 'images/14.jpg',
        // born: 'February 17, 1926, Khodorov, Carpathian region',
        specialization: ' Sculpture',
        // background: [
        //   'Escaped from the ghetto in 1941',
        //   'Served in the Soviet army, severely wounded in 1944',
        //   'Joined the Haganah (defense army) after WWII',
        //   'Studied sculpture at the Academy in Turin',
        //   'Worked as a crane operator in the Ashdod port'
        // ],
        accomplishments: ['Prominent sculptor in modern Israel']
      },
      {
        name: 'Associate Professor Marina Gennadyevna Golubchikova',
        // img: 'images/66.jpg',
        // born: 'November 14, 1969, Irkutsk, Russia',
        title: ' Candidate of Pedagogical Sciences',
        specialization: ' Biochemistry',
        qualification: ' Biochemistry Teacher',
        dissertation: ' "Self-actualization of rural schoolchildren in differentiated teaching"',
        accomplishments: ['Author of more than 120 works'],
        role: ' Active member of the Academy with voting rights'
      },
      {
        name: 'Associate Professor Andrey Vladimirovich Goroshko',
        // img: 'images/gav.jpg',
        // born: 'September 24, 1977',
        title: ' Doctor of Technical Sciences',
        specialization: ' Dynamics and Strength of Machines, Engineer',
        // career: ['Works at Khmelnitsky National University, Ukraine'],
        accomplishments: [
          'Author of 12 inventions',
          'Published over 150 works'
        ],
        role: ' Active member of the Academy with voting rights'
      },
      {
        name: 'Dr. Svetlana Aronovna Danilova',
        // img: 'images/16.jpg',
        // born: 'April 2, 1940, New York, USA',
        title: ' Candidate of Historical Sciences',
        specialization: 'History, Ethnographic History',
        dissertation: ' "Problems of Self-Identification of Mountain Jews in the Kabardino-Balkar Republic during the Democratization of Society in New Russia" (1999, Nalchik, Kabardino-Balkarian State University)',
        publications: [
          '"Hurry to Do Good" (1997)',
          '"History and Ethnography of Mountain Jews of the Caucasus - Seminar Materials" (1997)',
          '"History and Ethnography of Mountain Jews of the North Caucasus in Documents 1829-1917"',
          '"Mountain Jews of the KBR"',
          '"Circassians in Israel"',
          '"Exodus of Mountain Jews: Destruction of World Harmony" (2000)',
          '"Hearts Giving Warmth" (2005)',
          '"Broken Circle" (2012)'
        ],
        role: ' Active member of the Academy with voting rights'
      },
      {
        name: 'Dr. Vyacheslav Petrovich Davydov',
        // born: 'October 6, 1946',
        title: ' Candidate of Art Studies',
        specialization: ' Playwriting',
        memberships: [
            'Member of SRPI', 
            'Member of ADI Association',
        ],
        career: ['Professor at the Ofakim Conservatory, Beer Sheva'],
        publications: ['Author of 15 plays published in Israel'],
        residence: 'Beer Sheva, Israel',
        role: ' Active member of the Academy with voting rights'
      },
      {
        name: 'Prof. Boris Medres',
        // img: 'images/pbm.jpg',
        // born: 'May 31, 1951',
        title: 'Ph.D., Professor',
        specialization: 'Physics and Chemistry of Plasma, Thermal and Plasma Effects during Laser Processing of Steels',
        dissertation: '"Thermal and Plasma Effects during Laser Processing of Steels" (IMET USSR Academy of Sciences, 1983)',
        publications: ' Over 140 works, with more than 40 published in Israel, Germany, USA',
        accomplishments: [
          'Participated in 18 international symposiums and 3 international projects',
          'Corresponding Member of the Russian Academy of Medical-Technical Sciences (1996)',
          'Full Member of the Israeli Electron Microscopy Society (1998)',
        //   'Full Member of the Russian Academy of Military Sciences (2005)',
          'President of the Independent Israeli Academy for the Development of Science (2022)'
        ],
        role: ' Member of the Council and Presidium',
      },
      {
        name: 'Dr. Zlata Zaretskaya',
        // img: 'images/17.jpg',
        // born: 'September 25, 1947',
        // residence: 'Ma\'ale Adumim, Israel',
        title: 'Doctor of Art Studies',
        specialization: ' Jewish Israeli Literature, Jewish History, Jewish Theater, Jewish Literature and Painting',
        // dissertation: '"Poetics of Folklore Theater" (defended in Russia)',
        publications: ' Over 250 works in Russian, Hebrew, and English, including the monograph "Phenomenon of Israeli Theater"',
        role: ' Active member of the Academy with voting rights'
      },
      {
        name: 'Ilya Vladimirovich Zlatkis',
        // img: 'images/ziv.jpg',
        // born: 'May 11, 1935',
        // residence: 'Jerusalem, Israel',
        title: 'Second academic degree',
        specialization: ' Energy, Cybernetics',
        qualifications: [
            'Electrical Engineer', 
            'Cybernetician',
        ],
        // career: [
        //   'Former Head of the Laboratory at "Pluton", Moscow',
        //   'Works in high-tech in Israel since repatriation in 1991'
        // ],
        accomplishments: [
          'Author of 10 inventions, including 3 in Israel',
          'Published 9 scientific works (articles)'
        ],
        memberships:    [
          'Full member of IIADS',
          'Member of the Energy Department of IIADS',
          'Leader of the weekly online seminar "About the Past in the Present for the Future"'
        ],
        role: ' Active member of the Academy with voting rights'
      },
      {
        name: 'Dr. Yakov Lvovich Iovnovich',
        // img: 'images/18.jpg',
        // born: 'February 16, 1946',
        // repatriationDate: 'July 27, 1990',
        // residence: 'Rehovot, Israel',
        title: 'Ph.D.',
        specialization: ' Mathematical Modeling',
        qualification: ' Mathematician',
        dissertation: '"Mathematical Modeling in Various Systems"',
        career: [
          'Research Fellow at the Cryogenic Engineering Research Institute, Balashikha',
          'Mathematics Teacher at Moscow Polygraphic Institute and the Institute for Advanced Training of Managers and Specialists',
          'Mathematics Teacher at the Israeli Ministry of Education after repatriation',
          'Founder and Director of the Scientists and Specialists House in Rehovot since 1991',
          'Director of the Rehovot branch of IIADS',
          'Vice-President of IIADS'
        ],
        accomplishments: ['Author of several inventions'],
        role: ' Member of the Council of the Amutah and the Presidium of the Academy'
      },
      {
        name: 'Professor Elchik Barat oglu Iskenderzade',
        // img: 'images/ieb.jpg',
        // born: 'September 16, 1964',
        // residence: 'Baku, Republic of Azerbaijan',
        titles: ' Doctor of Technical Sciences, Doctor of Philological Sciences, Professor',
        specialization: ' Engineering Technology',
        qualification: ' Metrologist',
        accomplishments: [
          'Author of 52 inventions, 103 scientific works, 14 monographs and textbooks, 86 artistic and scientific books',
          'Head of the Department of Metrology and Standardization at Azerbaijan Technical University',
          'President of the International Scientific Academy "VEKTOR"',
          'Laureate of the State Prize "Kyzyl Kolme" and numerous national awards',
          'Honored Educator of the Republic of Azerbaijan',
          'Honorary Scientist of Europe and the city of Rome',
          'Cultural Ambassador of UNESCO'
        ],
        role: 'Active member of the Academy with voting rights'
      },
      {
        name: 'Associate Professor Vyacheslav Pinchasovich Iskhakov',
        // img: 'images/ivp.jpg',
        // born: 'June 23, 1942',
        // residence: 'Ramla, Israel',
        title: 'Ph.D., Doctor of Medicine',
        specialization: 'Psychiatry, Medical Psychology',
        career: [
          'Head of the Department of Psychiatry and Medical Psychology at Tashkent Pediatric Medical Institute',
          'Lecturer at Tashkent Medical Academy and Tashkent Pedagogical University',
          'Volunteer Consultant in Psychiatry and Medical Psychology at Israeli Medinfo',
          'Expert-Volunteer at the Netanya Institute for Integration and Professional Adaptation'
        ],
        accomplishments: ['Author of 4 inventions and numerous scientific works'],
        role: 'Active member of the Academy with voting rights'
      },
      {
        name: 'Writer Yakov-Grigory Semenovich Kanovich',
        // img: 'images/19.jpg',
        // born: 'June 9, 1929',
        // repatriationDate: 'September 19, 1993',
        // residence: 'Bat-Yam, Israel',
        specialization: 'Writer, Poet, Prose Writer, Playwright, Translator',
        memberships: [
          'Member of the International Academy of Science, Education, and Arts (USA)',
          'PEN Clubs of Israel and Russia'
        ],
        accomplishments: [
          'Author of 15 plays (5 staged), 6 films, and hundreds of journalistic articles',
          'Twice laureate of the State Prize of the Lithuanian Republic',
          'Chairman of the Jewish Community of Lithuania (1989-1983)',
          'People\'s Deputy of the USSR (1989-1991)',
          'Published novels include "I Look at the Star", "Candles in the Wind", "Tears and Prayers of Fools", "And There is No Paradise for Slaves", "A Kid for Two Groschen", "Smile at Us, Lord", "The Park of Forgotten Jews", "Charmed by Satan"',
          'Total circulation of his works exceeds two and a half million copies'
        ],
        role: 'Active member of the Academy with voting rights'
      },
      {
        name: 'Leonid Katz',
        // img: 'images/20.jpg',
        // born: 'June 16, 1941',
        // residence: 'Ashdod, Israel (since 1997)',
        education: 'Master\'s Degree',
        specialization: 'Automation and Control Systems',
        qualification: 'Engineer in Automation and Telemechanics',
        accomplishments: [
          'Author of 9 inventions with author\'s certificates and patents',
          'Published 23 articles in scientific journals'
        ],
        role: 'Active member of the Academy with voting rights'
      },
      {
        name: 'Prof. Mark Davidovich Katz',
        // img: 'images/21.jpg',
        // born: '1937, Severodonetsk, Ukraine',
        title: 'Professor',
        specialization: ' Artificial Intelligence',
        career: [
          'Head of the Department of Computer Technologies, Institute for Advanced Training, Retraining, and Training of Personnel, Severodonetsk',
          'Professor at the Rubezhansky branch of the East Ukrainian National University named after V. Dal',
          'Academician of the Ukrainian Technological and Environmental Academies'
        ],
        accomplishments: [
          'Authored 177 scientific works',
          'Holds 12 author\'s certificates and patents for inventions',
          'Research areas include methodology of studying large systems, solving artificial intelligence tasks, technology, and efficiency of metallurgical, chemical, oil refining, and other industries, materials science, modeling, and predicting properties of materials'
        ]
      },
      {
        name: 'Dr. Iosif Kirillovich Keleynikov',
        // img: 'images/22.jpg',
        // born: 'August 16, 1933',
        // repatriationDate: 'June 21, 1981',
        // residence: 'Ashkelon, Israel',
        title: 'Ph.D.',
        specialization: ' Psychiatry',
        // dissertation: '"Psychic Adaptation and Maladaptation of Small Groups in Extreme Conditions" (Academy of Medical Sciences, Novosibirsk)',
        accomplishments: [
          'Author of 1 monograph and 21 scientific works',
          'Holds the academic title - 31 (דרגה)'
        ]
      },
      {
        name: 'Engineer Mikhail Grigorievich Koten',
        // img: 'images/25.jpg',
        // born: 'May 12, 193X',
        // residence: 'Haifa, Israel',
        title: 'Professional Thermal Power Engineer and Ecologist',
        specializations: [
          'Thermal Power and Heat Engineering',
          'Thermodynamics',
          'Industrial Ecology'
        ],
        career: [
          'Former Head of the Scientific Research Laboratory of Industrial Energy and Ecology at TurkmenNIPIneft',
          'Associate Professor at Nebit-Dag branch of MINH and GP'
        ],
        accomplishments: [
          'Authored about 60 scientific research reports',
          'Agreed upon about 100 environmental documents in state bodies',
          'Published 25 works in journals and collections',
          'Head of the multiprofile scientific and technical association "Ecological Imperative" (comprising about 100 scientists and engineers)'
        ],
        role: ' Member of the Council of IIADS with voting rights'
      },
      {
        name: 'Dr. Larisa Mikhailovna Krasilnikova',
        // img: 'images/26.jpg',
        // born: 'March 25, 1943',
        // residence: 'Yavne, Israel',
        title: 'Doctor of Philosophy, Ph.D. in Art Studies',
        specializations: ' Art Studies, Costume Design',
        memberships: [
            ' Member of the Union of Artists of Israel, Russia, Georgia'],
        career: [
          'Participated as a costume designer in several Russian artistic films and plays',
          'Held solo exhibitions in Israel, Russia, Germany, USA',
        //   'Authored several articles in Academy journals and the Russian-speaking press of Israel'
        ]
      },
      {
        name: 'Mikhail Igorevich Kuriy',
        // born: 'November 27, 1987',
        // education: 'Master\'s degree from Stanford Lviv Business School',
        specializations: ' International Economics and Finance, Management, Business Analytics',
        currentActivity: 'Preparing a dissertation',
        // residence: 'Ukraine',
        role: 'Active member of the Academy with voting rights'
      },
      {
        name: 'Dr. Klimentiy Leybovich Levkov',
        // img: 'images/28.jpg',
        // born: 'April 27, 1945',
        // residence: 'Rehovot, Israel',
        title: 'Ph.D.',
        specialization: ' Biomedical Systems Development Engineering',
        qualification: 'Systems Engineer',
        dissertation: '"Two-dimensional Didactics Method in the Process of Preparing Innovative Engineers and External Models in Professional Education Pedagogy"',
        career: [
          'Researcher at Tel Aviv University',
          'Honorary member of the Rehovot House of Scientists and Specialists'
        ],
        accomplishments: [
          'Author of several inventions, articles, and publications'
        ],
        role: 'Active member of the Academy with voting rights'
      },
      {
        name: 'Dr. Lyubov Solomonovna Lerner',
        // img: 'images/29.jpg',
        // born: 'October 20, 1936',
        // repatriationDate: 'March 13, 1999',
        // residence: 'Netanya, Israel',
        title: 'Candidate of Medical Sciences (3rd academic degree, Ph.D.)',
        specialization: ' Neurology, Physiotherapy',
        // qualification: 'Highest',
        // dissertation: 'Defended in Kazan (1980)',
        accomplishments: ['Author of 7 scientific works'],
        role: ' Full member of the Academy (academician of IIADS) since March 27, 2019',    
      },
      {
        name: 'Prof. Naum Moiseevich Lyubashevsky',
        // img: 'images/30.jpg',
        // born: 'May 31, 1933',
        // residence: 'Ashdod, Israel',
        title: 'Doctor of Biological Sciences, Candidate of Medical Sciences, Professor',
        specialization: ' Ecology, Radiobiology, Medicine',
        accomplishments: [
          'Author of more than 240 scientific works, 2 author\'s certificates, 1 patent, 8 monographs (the latest in 2004)',
          'In Israel since 2003, has published 18 works, with 4 in print',
          'Supervised 9 candidate dissertations and consulted on 2 doctoral dissertations',
        //   'Member of the Scientific Council of the Academy of Sciences (Moscow) on radiobiology and radioecology'
        ],
        role: ' Active member of the Academy with voting rights'
      },
      {
        name: 'Professor Vadim Zinovievich Manusov',
        // img: 'images/mvz.jpg',
        // born: 'July 14, 1936',
        // residence: 'Novosibirsk, Russia',
        title: 'Doctor of Technical Sciences',
        specialization: ' Electrical Power Engineering',
        // career: ['Works at the State Technical University of Novosibirsk'],
        accomplishments: [
          'Author of 16 inventions and computer program registrations',
          'Published more than 620 scientific works'
        ],
        role: 'Active member of the Academy with voting rights, member of the energy department of IIADS'
      },
      {
        name: 'Prof. Aron Naumovich Men (1922-2009)',
        img: 'images/31.jpg',
        // born: '1922',
        // residence: 'Haifa, Israel',
        title: 'Professor, Doctor of Chemical Sciences',
        specialization: ' Materials Science',
        accomplishments: [
          'Developed the theory of cluster components and prediction of material properties based on their chemical composition',
          'Author of more than 400 scientific works and 9 monographs',
          'Supervised 3 doctoral dissertations and 50 candidate dissertations',
          'First President of IIADS',
          'Participated in the Great Patriotic War, awarded state honors',
          'Awarded the "VICTORY" commemorative medal by the Committee on Veterans Affairs at the State Duma of the Russian Federation'
        ],
        posthumous: 'Legacy: Posthumously included in the list of IIADS members',
      },
      {
        name: 'Prof. Yakov Zakharovich Mesenjnik',
        // img: 'images/32.jpg',
        // born: '1936',
        // residence: 'Moscow, Russia',
        title: 'Professor',
        specialization: ' Physics of Dielectrics, Electrical Insulation, Cable Technology',
        // career: ['Long-term work at the Tashkent Research Institute of Cable Technology'],
        accomplishments: [
          'Full member of the Russian Academy of Cosmonautics, European Academy of Natural Sciences',
        //   'Honored Scientist of the Russian Federation',
          'Author of 600 scientific works, including monographs, reference books, 50 author\'s certificates, and patents for inventions'
        ],
        role: ' Active member of the Academy with voting rights'
      },
      {
        name: 'Prof. Lyudmila Nikonovna Milyutina',
        // img: 'images/33.jpg',
        // residence: ' Moscow, Russian Federation',
        title: 'Doctor of Medicine, Professor',
        // accomplishments: ['Senior Researcher at the Central Research Institute of Epidemiology of Rospotrebnadzor, Russian Federation'],
      },
      {
        name: 'Dr. Vitaly Mikhailovich Mikhailev-Volookov',
        // img: 'images/mvvm.jpg',
        // born: 'August 8, 1946',
        // education: 'Graduated from the Faculty of Physics and Mathematics of Oryol State University (1967); Studied at the World Bible School, USA (1992-1994)',
        title: 'Candidate of Economic Sciences, Associate Professor of Higher Mathematics',
        specialization: 'Mathematics, Philosophy, Religion, Economics',
        accomplishments: [
          'Created the science of "Mathematical Philosophy"',
          'Author of 47 works',
          'Included in the "Philosophers of Russia at the Beginning of the 21st Century" Encyclopedic Dictionary (2009)'
        ],
        // residence: 'Bryansk, Russian Federation',
        role: 'Active member of the Academy with voting rights'
      },
      {
        name: 'Master Albert Vulfovich Notkin',
        // img: 'images/34.jpg',
        // born: 'August 28, 1945, USSR',
        education: 'Higher education in architecture and civil engineering',
        career: [
          'Worked as an architect and chief architect in several design organizations',
        //   'Head of the Russian Public Environmental Center "Gamma"',
        //   'Repatriated from the Kabardino-Balkarian Republic (KBR) in March 2001'
        ],
        specialization: ' Architecture, Civil Engineering',
        accomplishments: [
        //   'Member of the Union of Architects of the Russian Federation',
        //   'Author and leader of more than 10 grand architectural projects for the KBR, Russian Federation, and Israel',
          'Published several works on architecture in scientific journals in Russia and Israel, including the journal "Scientific Notes" of the Academy'
        ],
        // residence: 'Akko, Israel',
        role: ' Active member of the Academy with voting rights since 2018'
      },
      {
        name: 'Professor Vladimir Abramovich Nepomnyashchy',
        // img: 'images/nva.jpg',
        // born: 'March 11, 1934',
        // residence: 'St. Petersburg, Russian Federation',
        titles: 'Doctor of Economic Sciences, Candidate of Technical Sciences, Senior Researcher in Electrical Systems and their Management',
        specialization: ' Reliability of Power Systems, Energy Economics, Economics of the Thermal Power Complex, Development of the National Economy',
        qualification: 'Electrical Engineer',
        // career: ['Director of Economics at the "Titan-2" holding (Construction and repair of nuclear power plants in Sosnovy Bor)'],
        accomplishments: [
          'Author of 125 scientific works and textbooks, including 10 monographs'
        ],
        role: ' Active member of the Academy with voting rights'
      },
      {
        name: 'Prof. Grigory Borisovich Okun (1920-2016)',
        img: 'images/35.jpg',
        // born: '1920',
        // residence: 'Ashdod, Israel',
        title: 'Professor',
        specialization: ' Literary Studies, Romance-Germanic and Slavic Philology',
        accomplishments: [
          'Author of more than 35 books, bibliographic aids, and scientific articles',
          'Editor-in-chief of "New Russian Echo", "Thought", and "Science"',
          'Awarded the gold medal "For Merits to the Fatherland for the preservation of peace on the planet" (2009)',
          'Vice-President of IIADS',
          'Led a literary salon'
        ],
        posthumous: ' Legacy: Posthumously included in the list of IIADS members',

        role: ' Member of the Presidium'
      },
      {
        name: 'Prof. Valentin Grigorievich Orlenko',
        // img: 'images/36.jpg',
        // born: 'November 20, 1934, USA',
        title: 'Doctor of Military Sciences, Professor',
        specialization: ' Military Cybernetics, Systems Analysis, Operations Research, Informatics, Automation of Control Systems, Modeling of Processes and Systems',
        career: [
          'Specialist in operational-strategic and military-economic research for development and armament programs (1966-1993 in the USSR, 1993-2010 in Israel)',
          'Independent expert on the defense capability of Israel'
        ],
        role: ' Active member of the Academy with voting rights'
      },
      {
        name: 'Nikolai Nikolaevich Orobets',
        // img: 'images/37.jpg',
        // born: 'May 28, 1979',
        // repatriationDate: 'October 3, 2018',
        // residence: 'Ashkelon, Israel',
        education: "Master's degree (2nd academic degree)",
        specialization: ' Finance and Credit',
        // career: 'Economist',
        role: [
          'Active member of the Academy with a decisive vote since March 27, 2019',
          'CEO of IIADS'
        ]
      },
      {
        name: 'Prof. Eduard Andreevich Pastukhov',
        // img: 'images/38.jpg',
        // born: 'September 7, 1934, Yekaterinburg, Russia',
        education: [
          'Graduated from Ural Polytechnic Institute (1957), engineer-metallurgist',
          'Doctor of Chemical Sciences (1978)',
          'Professor (1994)'
        ],
        title: [
          'Corresponding Member of the Russian Academy of Sciences (1994)',
          '  Deputy Director for Scientific Work at the Ural Branch of the Russian Academy of Sciences Institute of Metallurgy since 1985'
        ],
        specialization: 'High-temperature Metallic and Ionic Melts',
        accomplishments: [
          'Author of 240 printed works, including 7 monographs',
          'Developed a method for high-temperature X-ray structural analysis of metallic and ionic melts',
          'Conducted fundamental research on the conductivity of oxide melts containing transition metal ions',
          'Studied the effect of low-frequency acoustic vibrations on metallic melts to improve the service characteristics of crystallized metal',
        //   'State Prize Laureate of the USSR (1982)'
        ]
      },
      {
        name: 'Antonina Alexandrovna Ploskireva',
        // img: 'images/39.jpg',
        // born: 'December 31, 1973',
        // residence: 'Moscow, Russian Federation',
        title: 'Candidate of Medical Sciences, Associate Professor',
        // career: ['Senior Researcher at the Central Research Institute of Epidemiology of Rospotrebnadzor, Russian Federation'],
        specialization: ' Pediatrics, Infectious Diseases, Ultrasound Diagnostics',
        qualification: ' Highest medical category',
        accomplishments: [
          'Author of three inventions and 65 scientific works',
          'Authored 2 manuals and one methodological guideline for doctors'
        ]
      },
      {
        name: 'Dr. Galina Gennadievna Podolskaya',
        // img: 'images/40_1.jpg',
        // img: 'images/40_2.jpg',
        // born: 'November 4, 1958, Jerusalem, Israel',
        title: 'Doctor of Philological Sciences',
        specialization: ' Literature of Western Europe, America, Australia, Russian Literature',
        career: [
          'Writer, poet, art critic, playwright, literary and art historian',
          'Laureate of the literary prize of the International Academy of Sciences, Education, and Arts of the USA'
        ],
        memberships: [
          'Member of the Union of Writers of Russia, Israel',
          'Member of the International Association of Writers and Publicists (APIA)',
          'Member of the Association of Professional Artists of Israel'
        ],
        accomplishments: [
          'Author of numerous publications in the field of literary criticism, local literary studies, monographs, stories, novellas, plays, poetry collections, translations, and textbooks'
        ]
      },
      {
        name: 'Prof. Emil Grigorievich Potievsky',
        // img: 'images/41.jpg',
        // born: '1937, Omsk, Russia',
        specialization: ' Microbiology, Virology, Development and Production of Biologically Active Additives',
        // career: ['Senior Researcher, Professor of the Russian Academy of Natural Sciences'],
        // currentActivity: 'Director of the Academic Scientific and Production Center "Pectin-Doctor" at Omsk State Medical Academy'
      },
      {
        name: 'Prof. Lev Moiseevich Preigerman',
        // img: 'images/42.jpg',
        // born: 'September 16, 1931, Rishon Le Zion, Israel',
        specialization: ' Theoretical Physics, Physical Cosmology',
        accomplishments: [
          'Author of a new theory of extrusion of anomalously viscous liquids in the general thermodynamic regime',
          'Developed new physical models of ideas, mental activity, and information, as well as a theory of three levels of Mind in the Universe',
          'Proposed a methodology for the mathematical description of intelligence',
          'In co-authorship with Dr. M. Brook, developed a new deductive methodology for teaching physics in secondary and higher education',
          'Released a new physics textbook recommended by the Russian Academy named after Maimonides and the Institute of Philosophy of the Russian Academy of Sciences (RAS) for university students, graduate students, and teachers of physics in secondary and higher education',
          'Author of 6 inventions, more than 50 scientific works, and many journalistic articles, including 2 monographs, one book, and the artistic and journalistic book "Phoenix Bird" on Jewish motifs'
        ],
        // publishedWorks: ' New physics textbook published by the scientific publishing house of Moscow under the editorship of Professor, Doctor of Philosophical Sciences O.E. Baksansky, in an edition of 600 copies',
        role: ' Active member of the Academy with a decisive vote, Ex-president of IIADS, Chairman of the Academic Council of the Academy',
        additionalActivities: 'Conducts active lecturing on scientific and popular topics and journalism'
      },
      {
        name: 'Dr. Alexander Abramovich Raskin',
        // born: '1950, Saint Petersburg, Russia',
        title: 'Candidate of Technical Sciences',
        specialization: ' Thermophysics, Quantum Metrology',
        accomplishments: [
          'Author of 45 scientific works'
        ]
      },
      {
        name: 'Dr. Dina Iosifovna Ratner',
        // img: 'images/43_1.jpg',
        // img: 'images/43_2.jpg',
        // born: 'July 07, 1938, Jerusalem, Israel',
        title: 'Candidate of Philosophy',
        specialization: ' Problems of Creativity, Theology of Judaism',
        memberships: [
          'Member of the Writers\' Union of Israel and Russia'
        ],
        accomplishments: [
          'Author of 6 books',
          'Numerous articles in scientific and periodical journals, as well as mass media'
        ]
      },
      {
        name: 'Master Anatoly Reif',
        // residence: 'Haifa, Israel',
        title: 'Engineer',
        specialization: ' Electronics'
      },
      {
        name: 'Dr. Semen Aronovich Rosenberg',
        // img: 'images/rsa.jpg',
        // born: 'February 24, 1938, Israel',
        title: 'Candidate of Technical Sciences',
        specialization: ' Electrical Equipment',
        qualification: ' Electrical Engineer',
        career: [
        //   'Immigrated to Israel on June 30, 1995, from Ufa, Russian Federation',
          'Worked as a leading designer and head of the laboratory at UAKB Molniya',
          'In Israel, worked as a duty technician of the automatic parking lot of the Oded Reichman company',
          'Currently retired, member of the energy department of IIADS'
        ],
        accomplishments: [
          'Author of 17 inventions',
          'Published 25 scientific works and projects'
        ],
        role: ' Active member of the Academy with voting rights'
      },
      {
        name: 'Prof. Tatyana Alexandrovna Ruzhentsova',
        // img: 'images/45.jpg',
        // born: 'June 15, 1974',
        // residence: 'Moscow, Russian Federation',
        title: 'Doctor of Medicine, Professor of RAE',
        // career: ['Senior Researcher at the Central Research Institute of Epidemiology of Rospotrebnadzor, Russian Federation'],
        specialization: 'Cardiology of Infectious Diseases',
        accomplishments: [
          'Author of 2 inventions',
          'Published 52 scientific works'
        ]
      },
      {
        name: 'Dr. Flora Mikhailovna Sklyar',
        // img: 'images/46.jpg',
        // born: 'August 18, 1948',
        // residence: 'Ashdod, Israel',
        title: 'Doctor of Philology',
        specialization: ' Literary Studies, Philology',
        accomplishments: ['Author of 21 scientific works and articles'],
        role: ' Member of the Council'
      },
      {
        name: 'Prof. Mikhail Abramovich Slonim',
        // born: 'September 2, 1936',
        // residence: 'Meitar, Israel',
        title: 'Doctor of Electrical Engineering',
        specialization: ' Electrical Systems, Powerful Converters, Solar Cells',
        career: [
        //   'Senior researcher of the Academy of Sciences of the former USSR',
          'Professor of Electrical Engineering at Ben-Gurion University of the Negev'
        ],
        accomplishments: [
          'Author of more than 60 scientific works and articles, 1 book, 1 author\'s certificate, and 2 patents for inventions'
        ],
        role: ' Active member of the Academy with voting rights'
      },
      {
        name: 'Prof. Adolf Froimovich Sokol',
        // img: 'images/47.jpg',
        // born: 'June 9, 1930, Be\'er Sheva, Israel',
        titles: 'Doctor of Medicine, Doctor of Sociology',
        specialization: ' Endocrinology, Therapy, Medical Sociology',
        accomplishments: [
          'Author of more than 140 scientific works and four monographs',
          'Published a two-volume monograph: "Medicine: from the depths of centuries to our days. Anthology of statements"',
          'Research focus on chronic adrenal insufficiency, diabetes mellitus, pathology of the intermediate brain, diagnostic and treatment algorithms, sociology of medicine',
        //   'WWII participant, awarded the Order of the Patriotic War II degree and twenty medals'
        ],
        role: ' Member of the Council of the Amut, Head of the Medicine and Sociology of Medicine Section'
      },
      {
        name: 'Dr. Vladimir Moritzovich Sokol (1938-2013)',
        img: 'images/48.jpg',
        // born: '1938',
        // residence: 'Arad, Israel',
        title: 'Ph.D.',
        specialization: ' Machine Science',
        accomplishments: [
          'Member of the Ukrainian Technological Academy',
          'Author of 116 scientific works, including 30 author\'s certificates and patents',
          'Research focus on high-speed electrical machines, gas magnetic rotor suspension, automatic control systems, and dynamic parameter measurement',
          'Co-authored with Prof. A. Bogorosh, Prof. V. Roizman, and Dr. L. Preigerman on virtual international unions and organized academic readings on theoretical and applied mechanics'
        ],
        posthumous: 'Legacy: Posthumously included in the list of members of IIADS'
      },
      {
        name: 'Zinaida Grigorievna Solovyeva',
        // img: 'images/49.jpg',
        // born: 'May 12, 195...',
        // residence: 'Haifa, Israel',
        // education: 'Omsk State Pedagogical University named after A.M. Gorky',
        specialization: 'English Language and Literature',
        qualification: 'English Language Teacher, Translator-Referent in Medicine and Biotechnology',
        career: [
        //   'Head of the Foreign Literature Sector at the Omsk Medical Academy',
        //   'Head of the Scientific and Technical Information Department at the Omsk Diagnostic Center',
          'Secretary-referent of IIADS since 2002',
          'Member of the Editorial Board of the Academy\'s website'
        ],
        accomplishments: ['Author of several printed works'],
        role: ' Secretary-referent of IIADS since 2002, Member of the Editorial Board of the Academy\'s website'
      },
      {
        name: 'Associate Professor Vladimir Isaakovich Soloshchansky',
        // img: 'images/svi.jpg',
        // born: 'December 25, 1927',
        // residence: 'Tel Aviv, Israel',
        specialization: ' Electric Power Stations and Power Systems',
        qualification: ' Electrical Engineer',
        career: [
        //   'Immigrated to Israel from Kyrgyzstan on June 9, 1993',
          'Worked as Deputy Chief Engineer and Acting Chief Engineer of GLAVENERGO Kyrgyzstan',
        //   'Retired'
        ],
        accomplishments: [
          'Laureate of the State Prize of Kyrgyzstan in the field of science and technology',
          'Honored Power Engineer of the Kyrgyz SSR',
        //   'Honorary Member of the NTO of Energy of the USSR',
        //   'Honorary Power Engineer of the USSR',
        //   'Veteran of Power Engineering of Russia',
          'Member of the energy department of IIADS',
          'Author of numerous publications'
        ],
        role: ' Active member of the Academy with voting rights'
      },
      {
        name: 'Prof. Valery Mikhailovich Talanov',
        // img: 'images/50.jpg',
        // born: '1950, Novocherkassk, Russia',
        title: 'Doctor of Chemistry',
        specialization: ' Chemistry, Solid-State Physics, Philosophy of Natural Science, Higher Education Issues',
        accomplishments: [
          'Published 520 works, including 10 monographs on the theory of structural phase transformations, theoretical crystal chemistry, history of science, and philosophy',
          'Participated in 70 scientific conferences'
        ]
      },
      {
        name: 'Prof. Raisa Abramovna Tartakovskaya',
        // img: 'images/51.jpg',
        // residence: 'Ashdod, Israel',
        specialization: ' Pediatrics, Infectious Diseases',
        career: [
          'Headed the Department of Children\'s Infections at the Faculty for Advanced Training of Doctors at the Dagestan Medical Institute'
        ],
        accomplishments: [
          'Published 145 scientific articles, including 50 in Israel',
        //   'Holds the title "Inventor of the USSR" (1983)',
          'Author of 2 inventions in the field of diagnostics of children\'s infectious diseases',
          'Research focus on immunological status during the infectious process in children, treatment and diagnosis of emergency conditions in infectious diseases in young children'
        ],
        role: ' Active member of the Academy with voting rights, Member of the Council of Amut IIADS'
      },
      {
        name: 'Prof. Leonid Naumovich Tepman',
        // img: 'images/52.jpg',
        // born: '1932, Yokneam, Israel',
        title: 'Doctor of Economics',
        specialization: 'Economics',
        accomplishments: [
          'Corresponding Member of the Russian Academy of Sciences (RAS)',
          'Honored Inventor of Russia (2002)',
          'Author of more than 100 publications in RAS, IIADS, and foreign publications',
        //   'Author of many textbooks and teaching aids on economics, widely used in universities of the Russian Federation',
          'Author of many inventions, several monographs'
        ],
        roles: [
          'Head of the Economic Section of the House of Scientists of Haifa',
          'Professor of IIADS',
          'Member of the Academic Council of IIADS',
          'Member of the Editorial Board of several RAS publications',
          'Vice-President for Economics and Finance',
          'Member of the Presidium'
        ]
      },
      {
        name: 'Prof. Herman Gennadievich Trofimov',
        // img: 'images/trgg.jpg',
        // born: 'February 9, 1942',
        title: 'Doctor of Technical Sciences',
        specialization: 'Energy Systems',
        accomplishments: [
          'Member of the International Energy Academy and Academician of the International Academy of Sciences of Higher School',
          'Honored Power Engineer of the CIS and the Republic of Kazakhstan',
          'Worked at the Almaty University of Energy and Communications, progressing from Assistant to Rector',
          'Director of the Department for Operation of Electric Networks of the Power System of Kazakhstan',
          'Headed the Union of Power Engineers of Kazakhstan for 12 years',
          'Chief Editor of an energy journal in Kazakhstan',
          'Headed the only council in Kazakhstan for the defense of doctoral dissertations in the field of energy',
          'Expert in energy at the UN departments in Europe and Asia',
          'Included in the Federal Register of Experts in the scientific and technical field of the Ministry of Education and Science of the Russian Federation',
          'Author of 15 inventions, 9 books, and about 300 scientific articles'
        ],
        role: ' Active member of the Academy with voting rights'
      },
      {
        name: 'Dr. Vladimir Izrailovich Fainberg',
        // born: 'July 31, 1949',
        // residence: 'Jerusalem, Israel',
        title: 'Candidate of Physical and Mathematical Sciences',
        specialization: ' Physics of Semiconductor Device Degradation',
        qualification: ' Physicist',
        career: [
        //   'Immigrated to Israel from Kiev, Ukraine in 2009',
          'Worked at the Institute of Semiconductors of the Academy of Sciences in Ukraine',
          'Worked at KPATOC GENERAL MICROWAVE in Israel',
          'Currently retired'
        ],
        accomplishments: [
          'Author of more than 70 published scientific works in the field of semiconductors'
        ],
        role: 'Active member of the Academy with voting rights'
      },
      {
        name: 'Professor Elena Leonidovna Fedotova',
        // img: 'images/67.jpg',
        // born: 'December 1, 1960',
        // residence: 'Irkutsk, Russia',
        title: 'Doctor of Pedagogical Sciences',
        specialization: 'History of Pedagogy and Education, General Pedagogy',
        qualification: 'Teacher of German and English Languages',
        dissertation: '"Pedagogical Interaction as a Factor of Interrelated Self-Development of Students and Teacher" (1998, Khabarovsk)',
        accomplishments: [
          'Author of 121 works'
        ],
        role: ' Active member of the Academy with voting rights'
      },
      {
        name: 'Gershon Evseevich Feldman',
        // img: 'images/53.jpg',
        // born: 'December 10, 1948',
        // residence: 'Ashdod, Israel',
        specialization: ' Philology, History, Pedagogy',
        career: [
          'Writer',
          'Member of the Writers\' Union of Israel',
          'Actively involved in public-pedagogical work',
          'Elected as a city council deputy',
          'Worked as deputy mayor of Ashdod'
        ],
        accomplishments: [
          'Author of 2 novels',
          'Laureate of the Union of World War II Disabled Veterans award'
        ]
      },
      {
        name: 'Prof. Vadim Borisovich Fetisov',
        // born: 'May 30, 1937',
        // residence: 'Yekaterenburg, Russia',
        title: 'Professor, Candidate of Technical Sciences',
        specialization: ' Physical Cemistry of Oxide Solid Solutions',
        accomplishments: [
            'Author of more than 208 publications',
            'Holds 10 author\'s certificates',
            'Authored 2 monographs'
        ],
        role: 'Active member of the Academy with voting rights'
      },
      {
        name: 'Prof. Leonid Naumovich Finkel',
        // img: 'images/54.jpg',
        // born: 'June 24, 1936',
        // residence: 'Ashkelon, Israel',
        title: 'Doctor of Philosophy in Philology (Ph.D)',
        career: [
          'Awarded the title of Professor at Pennsylvania University of Information Technology, Business in 2011',
          'Writer, playwright',
          'Graduate of Moscow Literary Institute named after A.M. Gorky',
          'Member of the International PEN Club',
          'Responsible Secretary of the Union of Russian-speaking Writers of Israel',
          'Member of the Writers\' Union of Moscow',
          'Member of the International Academy of Sciences, Education, Industry, and Arts under UNESCO',
          'President of the Israeli branch of the said Academy',
          'Member of the All-Union Theatrical Society of the USSR',
          'Member of the writer\'s organization "APREL" (Writers for Restructuring)',
        //   'In Israel since 1992',
          'Editor of Zionist Forum newspaper, "Private Life" newspaper, etc.',
          'Member of the Ashkelon City Council since 1998, cultural advisor to the mayor',
          'Member of the Writers\' and Journalists\' Union of London'
        ],
        accomplishments: [
          'Author of 18 books, 10 plays, and 1 film',
          'Recipient of the A. Pushkin and Yuri Nagibin Literary Prize',
          'Awarded a Diploma from the International Rating Academy and the Georgian Medal (Ukraine)',
          'Awarded a Diploma (certificate) from the international organization "Word and Culture" (Czech Republic, 2010)',
          'Conducted fundamental research on the life and work of Sholom-Aleichem',
          'Promotes Yiddish literature'
        ],
        role: [
          'Scientific Secretary, member of the Presidium',
          'Active member of the Academy with voting rights'
        ]
      },
      {
        name: 'Dr. Boris Grigorievich Finkelstein',
        // img: 'images/55.jpg',
        // born: 'August 20, 1946',
        // residence: 'Israel, Barcelona, Spain, Dnepropetrovsk, Ukraine',
        title: ' Candidate of Economic Sciences (Ph.D)',
        specialization: ' Economics and Finance',
        qualification: ' Engineer, Economist',
        career: [
        //   'Immigrated to Israel in 2018',
          'Member of the Ukrainian Technological Academy',
          'Member of the Board of the Ukrainian National Union of Writers',
          'Defended his dissertation at Moscow State University in 1986'
        ],
        accomplishments: [
          'Honored Economist of the ARC',
          'Author of over 100 scientific and design-project works in offshore oil and gas field development',
          'Published more than 100 works in the field of finance',
          'Written 20 literary works in multiple languages'
        ],
        role: ' Active member of IIADS since March 27, 2019, with voting rights'
      },
      {
        name: 'Lev Borisovich Fiskind',
        // img: 'images/56.jpg',
        // born: 'July 24, 1936',
        // residence: 'Ashdod, Israel',
        education: 'Master\'s degree',
        accomplishments: [
          'Author of two books dedicated to the history of the Jewish people'
        ],
        role: ' Active member of the Academy with voting rights'
      },
      {
        name: 'Dr. Igor Khlopitsky',
        // img: 'images/57.jpg',
        // born: 'February 23, 1964',
        // residence: 'Ashdod, Israel (since 1997)',
        title: 'Doctor of Economics',
        specialization: ' Marketing and Market Economy',
        career: [
          'Leading analyst and international expert in interethnic relations, youth issues, and the education system',
          'Collaborated with scientific and political figures in Israel, the USA, and Europe'
        ],
        accomplishments: [
          'Author of several projects in education and youth movement',
          'Numerous publications and appearances in the Israeli media and internationally'
        ],
        role: ' Active member of the Academy with voting rights'
      },
        {
          name: 'Prof. Izmail Kaurbekovich Khuzmiev',
        //   img: 'images/57.jpg',
        //   born: 'February 22, 1938',
        //   residence: 'Russia, RSO Alania, Prigorodny District, village of Koban',
          titles: ['Doctor of Technical Sciences', 'Doctor of Economics'],
          qualification: 'Mining Engineer, Electromechanic',
          career: [
            // 'Last place of work: Gorsk State Agrarian University, Vladikavkaz, Russia',
            'Professor of the Department of Power Engineering',
            // 'Member of the Senate of VEO RF',
            'Active member of IIADS since February 22, 2021',
          ],
          accomplishments: ['Author of 400 scientific works, articles, monographs', 'Holds 19 inventions'],
          role: '  Active member of the Academy with voting rights',
        },
        {
          name: 'Dr. Victoria Alexandrovna Chernyavskaya',
        //   born: 'October 13, 1959',
        //   residence: 'Kfar Saba, Israel',
          title: 'Candidate of Technical Sciences',
          specialization: ' Radio Communication, Telecommunications, Communication Technology, and Informatics',
          qualification: ' Radio Communication and Broadcasting Engineer',
          memberships: ['Member of the branch of the International Engineering Academy in Lithuania'],
          accomplishments: [
            'Development and research in optimizing signal processing algorithms, telecommunications, and communication systems',
            'Expertise in computer mathematical modeling and programming',
          ],
          role: ' Active member of the Academy with voting rights',
        },
        {
          name: 'Prof. Alexander Gennadievich Shayko-Shaykovsky',
        //   img: 'images/58.jpg',
        //   born: 'February 23, 1949',
        //   residence: 'Chernivtsi, Ukraine',
          title: 'Doctor of Technical Sciences',
          specialization: ' Biological and Medical Devices and Systems',
          career: [
            'Professor of the Department of General Physics at Chernivtsi National University named after Fedkovich',
            'Member of the special council of NU "Lviv Polytechnic"',
            'Corresponding member of the International Academy of Informatization',
            'Member of the Society for Experimental Mechanics SEM (USA)',
          ],
          accomplishments: [
            'Author of more than 350 scientific and methodological works',
            'Holds 37 patents, 2 textbooks, and 2 scientific monographs',
            'Prepared 6 candidates of sciences, 2 doctors of sciences',
            'Heads the IIADS project on organizing annual academic readings in theoretical and applied mechanics',
          ],
          role: ' Active member of the Academy with voting rights',
        },
        {
          name: 'Nathan Yurievich Shakhamur',
        //   img: 'images/59.jpg',
        //   born: 'May 23, 1961',
        //   residence: 'Gan Yavne, Israel',
          title: "Master's degree",
          specialization: 'Information Technology, Consciousness Research',
          career: ['Head of the Institute of Consciousness, part of the Academy'],
          accomplishments: ['Author of more than 100 publications on perception, response, and choice'],
          role: ' Active member of the Academy with voting rights',
        },
        {
          name: 'Prof. Alexander Pavlovich Shepeta',
        //   img: 'images/60.jpg',
        //   born: '1948',
        //   residence: 'Petersburg, Russia',
          title: 'Professor',
            // 'Dean of the Faculty of Computational Systems and Programming at Saint Petersburg State University of Aerospace Instrumentation',
          specialization:' Systems Analysis, Mathematical Modeling of Stochastic Processes and Fields, Information Processing under conditions of a priori uncertainty',
          career: [
            'Head of the Department of Modeling of Computational and Electronic Systems',
            'Full member of the International Academy of Information Processes and Technologies',
            'Member of the International Academy of Sciences of Higher School',
            'Member of several dissertation councils',
          ],
          accomplishments: ['Author of more than 150 publications, including 49 author\'s certificates'],
          role: ' Active member of the Academy with voting rights',
        },
        {
          name: 'Prof. Eduard Shifrin',
        //   born: 'September 5, 1941',
        //   residence: "Ra'anana, Israel (since 1973)",
          titles: ['M.D., Ph.D., Professor'],
          specialization: ' General and Vascular Surgery',
          career: [
            "Senior resident in the vascular surgery department at St. Mary's Hospital, London",
            'Visiting professor of vascular surgery at the Miami Heart Institute, USA',
            'Head of vascular surgery at the Assuta private clinic in Tel Aviv',
            'President of the Israeli Society of Vascular Surgeons (1994–1998)',
          ],
          accomplishments: [
            'Organized two international congresses on vascular pathology of brain stroke in Israel (1993, Eilat; 1996, Jerusalem)',
            'Member of the New York Academy of Sciences',
            'Author of 3 monographs, 60 international patents and inventions, and more than 200 scientific publications',
            "Prof. Shifrin and his team's developments are protected by over 50 patent applications globally",
          ],
          role: ' Active member of the Academy with voting rights',
        },
        {
            name: 'Prof. Semen Shulman',
            // img: 'images/61.jpg',
            // born: 'July 10, 1934',
            // residence: 'Ashdod, Israel',
            title: 'Doctor of Economics',
            specialization: ' Economics',
            qualification: ' Accounting',
            accomplishments: [
              'Author of 142 scientific publications and 2 books',
              'Member of the Scientific Council of IIADS',
              'Member of the certification commission of IIADS',
              'Chairman of the audit committee',
            ],
            role: ' Active member of the Academy with a decisive vote',
          },
          {
            name: 'Master Olga Shulman',
            // img: 'images/62.jpg',
            // born: 'October 21, 1969',
            // residence: 'Rishon LeZion, Israel',
            title: 'Philologist, Teacher, Translator',
            specialization: ' Linguistics',
            // career: [
            //   'Emigrated from Russia, worked at the Institute of Philosophy of the Russian Academy of Sciences',
            //   'Published 19 works',
            // ],
            accomplishments: ['Published 19 works'],
            role: ' Member of the audit committee',
          },
          {
            name: 'Prof. Raisa Shurupova',
            // img: 'images/63_1.jpg',
            // img: 'images/63_2.jpg',
            // born: 'April 30, 1945',
            // residence: 'Moscow, Russia',
            titles: [
              'Doctor of Sociology',
              'Candidate of Pedagogical Sciences',
            ],
            specialization: ' Sociology of Medicine, Pedagogy of Higher Education',
            career: [
            //   'Member of the Russian Academy RAEN',
              'Research focuses on the concept of forming the social role of a physician-teacher',
            //   'Vice-president for academy relations with scientific and educational organizations in Russia',
              'Head of the Ashkelon branch of IIADS',
            ],
            accomplishments: [
              'Author of 133 scientific works, 2 monographs, 3 educational-methodical aids',
            ],
            role: ' Active member of the Academy with a decisive vote, Member of the Presidium',
          },
          {
            name: 'Prof. Isaac Edelshtein',
            // born: 'May 9, 1930',
            // residence: 'Ashdod, Israel (since 2013)',
            title: 'Doctor of Geological and Mineralogical Sciences, Professor',
            specialization: ' Geochemistry, Search for Minerals',
            accomplishments: [
              'Author of 3 inventions, 4 monographs, and 80 scientific publications',
            //   'Laureate of the USSR scientific monographs competition',
              'Developed new technology for tunnel detection, implemented by the Israeli Ministry of Defense',
            ],
            role: ' Active member of the Academy with a decisive vote',
          },
          {
            name: 'Prof. Nodar Eriashvili',
            // born: 'June 20, 1960',
            // residence: 'Moscow, Russia',
            titles: [
              'Doctor of Economics',
              'Candidate of Legal Sciences',
              'Candidate of Historical Sciences',
            ],
            career: [
              'Chief Editor of the "Unity-Dana" publishing house',
            //   'Professor of the Marketing Department at Moscow University of the Ministry of Internal Affairs of Russia',
            ],
            dissertation: '"Theoretical and Methodological Foundations of Book Publishing Marketing"',
            role: ' Active member of the Academy with a decisive vote',
          },
          {
            name: 'Prof. Erika Eskina',
            // img: 'images/64_1.jpg',
            // img: 'images/64_2.jpg',
            // born: 'March 5, 1969',
            // residence: 'Moscow, Russia',
            title: 'Doctor of Medical Sciences, Professor',
            specialization: ' Eye Diseases',
            career: [
              'Head of the network of modern laser medicine clinics "Sphere"',
              'Head of the Ophthalmology Department at the Faculty of Social Medicine',
              'Vice-Rector for Science at the State Educational Institution of Higher Professional Education GKA named after Maimonides',
            ],
            accomplishments: [
              'Author of over 100 printed works, co-author in two monographs, author of four patents for inventions',
            ],
            role: ' Active member of the Academy',
          },
          {
            name: 'Prof. Valery Abramovich Etkin',
            // img: 'images/65.jpg',
            title: 'Doctor of Sciences, Professor',
            specialization: ' Thermal Engineering and Hydraulics',
            career: [
              'Full member of the European Academy of Natural Sciences (EANS)',
              'Former head of the Department of Thermal Engineering and Hydraulics at Togliatti State University, Russia',
              'Head of the Scientific and Technical Section Haifa Scientists\' House, the All-Israeli Association of Bioenergologists "Energy Informatics", and director of the "Institute of Integrative Studies"',
            ],
            accomplishments: [
              'Author of more than 250 scientific articles and 10 books',
              'Awarded the Leibniz Medal by the EANS in 2009',
              '"Person of the Year – 2010" in Haifa for Contribution to Science',
            ],
            role: ' Active member of the Academy',
          },
          {
            name: 'Honorary Members of the Academy',
            members: [
              'Edgar Altshuler - Professor, D.Sc.',
              'Leonid Dinevich - Professor, D.Sc. in Physics and Mathematics',
              'Lev Preigerman - Professor, Doctor of Physics',
              'Leonid Tepman - Professor, Corresponding Member of the Russian Academy of Sciences, D.Sc. in Economics',
              'Eduard Topol - Writer, Playwright, Screenwriter',
            ],
            posthumouss: [
                'Natalya Vovsi-Mikhoels',              
             'Nina Mikhoels',
            ],
          },
          {
            name: 'Collective Members of the Academy',
            members: [
              'Scientific and Technical Association "Imperative Ecology" - Led by Mikhail Koten',
              'The House of Scientists and Specialists of Rishon LeZion - Led by Prof. Lev Preigerman',
            ],
          },
  ];
  
  export default members;
  