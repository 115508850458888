import React from 'react';

const FromTheLivesOfScientists = () => {
  return (
    <div className="div_main">
      <h2>FROM THE LIVES OF SCIENTISTS</h2>
      <h3><i>Interesting stories from the lives of scientists</i></h3>
      <article>
        <h3>Well-known chemist R. Bunsen</h3>
        <img src="images/izu1.jpg" style={{ float: 'left', width: '100px', margin: '0 50px 10px 10px' }} alt="R. Bunsen" />
        <p>
          Once, they introduced a lady to the scientist, whom she mistook for a recently deceased mystic, a
          scientist with the same last name.<br />
          - Have you finished your work on the role of God in history? - the lady asked.<br />
          - Unfortunately, no, madam, the scientist replied. - My premature death prevented me from doing so.
        </p>
      </article>
      <br />
      <article style={{ clear: 'both' }}>
        <h3>About the absent-mindedness of the Russian composer...</h3>
        <p>There were many funny stories told about the absent-mindedness of the Russian composer and scientist,
          A. P. Borodin. Here are some of them.</p>
        <h4>First story.</h4>
        <p>Once, Borodin invited friends to his family celebration. The evening was lively, and they stayed up
          late. Suddenly, Borodin stood up. - I'm sorry, my friends, but I have to leave. I need to go home, I
          have a lecture tomorrow - Only the burst of laughter that followed his words reminded Borodin that
          he was at home.</p>
        <h4>Second story.</h4>
        <p>Once, Borodin, who was alone at home for a short time, had to leave for some errands. Afraid that one
          of his friends might come during that time, he wrote a note that said "I'll be home in an hour" and
          attached it to the front door in a prominent place. He quickly finished his errands and returned
          home in half an hour, read the note on the door, and said in frustration:<br />
          - What a pity. I hurried on purpose to come back earlier. And now I have to wait for a whole half
          hour - he said and sat down on a bench to wait for himself.</p>
        <h4>Third story.</h4>
        <p>Once, Borodin and his wife traveled abroad. When passing through customs, his wife briefly moved
          away. Borodin handed his passports to the official.<br />
          - And who is this? - the official asked, pointing to his wife's passport.<br />
          - This is my wife, - Borodin was confused for some reason, which aroused the suspicion of the
          official.<br />
          - Wife? - the official asked again. - What's her name?<br />
          Borodin was even more confused as he suddenly forgot his wife's name. At that moment, his wife
          approached, and Borodin pleaded:<br />
          - Katya, for God's sake, remind me of your name, I completely forgot.<br />
          The wife and the official, exchanging glances, burst into laughter together.
        </p>
      </article>
      <br />
      <article>
        <h3>Niels Bohr once nailed</h3>
        <p>to the frame of his doors a horseshoe. A passing friend asked with surprise:<br />
          - Mr. Bohr, do you, a renowned scientist, believe in these silly superstitions?<br />
          - Well, you see, - Bohr replied, of course not! But they say that even those who don't believe in
          superstitions, a horseshoe still helps.
        </p>
      </article>
      <br />
      <article>
        <h3>Among scientists, there were</h3>
        <p>many misogynists and just as many ladies' men. Prominent misogynists included:<br />
          - the great Pythagoras. He showed no interest in women and got married for the first time, more by
          chance than by calling, when he was 60 years old.<br />
          - Plato. He was even less interested in women and never married. He believed that spiritually rich
          people, to which he naturally counted himself, valued the beauty of the soul far more than the
          beauty of the body. Thus, the famous phrase about platonic love was born.<br />
          - the great philosopher Immanuel Kant avoided female company and was also never married.<br />
          - one of the greatest experimental physicists, Cavendish, had a deep fear of women. He didn't engage
          in any conversations, even with his female servants, and communicated with them solely through notes
          left on the table. He took great care to avoid encountering women in his home.<br />
          - The great Newton also had no interest in women and was never married.
        </p>
      </article>
      <br />
      <article>
        <h3>Most scientists were not,</h3>
        <p>however, wearing blue stockings or suharis.<br />
          - M. Lomonosov was a troublemaker in his youth, a brawler, loved women and wine. He often escaped
          scandals thanks to the Empress's intervention. He married a German girl when he was over thirty.<br />
          - A. Einstein was very passionate as a young man. He fell in love with his first wife, Mileva Maric,
          even though she had a limp, when he was not yet 20. He married her against his parents' wishes.
          However, in the family, he became a dictator, demanding submission to his scientific work, to the
          point of excluding personal contact. He lived with her for a short time, although she was a
          physicist and assisted him in his work. His second wife, his relative, second cousin Elsa Löwenthal.
          He married her more out of gratitude, as she took care of him during a serious illness, but she died
          young. He found his true love later in life. The charming wife of the Soviet artist Konenkov and a
          Soviet intelligence resident, Margarita, who was courted by S. Rachmaninoff, Vrubel, the father and
          son Chaliapin, American physicist Oppenheimer, became Einstein's lover in the late 1930s. In 1945,
          Margarita was recalled to the Soviet Union, and they parted forever.<br />
          - L. Landau was an unattractive youth and had no success with women. He referred to himself as a
          "krasivist," in contrast to those who valued the intellect and spirituality in women, whom he called
          "dushists." He believed that a scientist should not marry and should be content with mistresses, the
          more, the better. He met his future wife, the first Moscow beauty Kora, at a ball, fell in love with
          her at first sight, courted her persistently, and, in accordance with his theory, did not rush to
          propose to her. When Daou (as everyone called him) woke up after a severe car accident, his first
          question to his wife was: "Kora, did I manage to marry you?" He passionately loved his wife Kora
          until the end of his life. However, this did not prevent him from having numerous affairs and
          confessing his infidelity to his wife sincerely. Interestingly, his love affairs were often with
          "podavalshchitsy," that is, not very bright girls, although they were usually beautiful.<br />
          - Another great scientist of the last century, Niels Bohr, unlike Einstein and Landau, was a
          monogamist and spent his entire life happily and in mutual adoration with his first and only wife,
          Margaret.
        </p>
      </article>
      <br />
      <article>
        <h3>Isaac Newton, waiting</h3>
        <p>for lunch and a delayed guest, sat at the dining table, lost in deep thought. The servants, thinking
          that the guest would not come, served lunch only to Newton. Newton, engrossed in his thoughts, did
          not notice this. The guest arrived. Tired of waiting for the host to notice him and not daring to
          disturb him, the guest, thinking that Newton was waiting for him with lunch, quietly ate the meal
          and left. Waking up from his thoughts, Newton looked at the empty plates and said:<br />
          - Hmm! If not for the obvious proof by contradiction, I would have thought that I haven't had lunch
          yet today... So he remained without lunch that day.
        </p>
      </article>
      <br />
      <article>
        <h3>Norbert Wiener, as expected</h3>
        <p>of a great scientist, was known for his forgetfulness. Once, his family moved to a new apartment.
          Returning home the next day after work, Wiener completely forgot about it and went to the old
          address. He was surprised to find the door to the apartment unlocked and, only when he entered the
          empty rooms, did he remember the move. He also remembered that his wife had written down the new
          address for him on a piece of paper, which he never found while searching his pockets. What to do?
          In desperation, he went out onto the street and, to his joy, saw a familiar girl. He approached her
          and introduced himself.<br />
          - I'm Professor Wiener, - he said, - my family moved to a new apartment yesterday, and I can't
          remember the address. Maybe you know our new address? - Well, what a joker you are, Dad, the girl
          said, smiling broadly. Let's go home quickly; Mom knew that you would forget the address and sent me
          to fetch you...
        </p>
      </article>
      <br />
      <article>
        <h3>While conducting exams, Landau</h3>
        <p>wrote the famous formula from quantum mechanics, E = ħν, and asked the student, "What is ν?"<br />
          - Well, confidently answered the student. - It's the Planck constant.<br />
          - And what about ħ, in your opinion? - asked the slightly bewildered professor.<br />
          – ħ, - the student replied confidently, is the height of Planck...<br />
          This was followed by a silent scene.
        </p>
      </article>
    </div>
  );
};

export default FromTheLivesOfScientists;
