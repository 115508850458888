// src/components/NavBar.js
import React, { useState } from 'react';
import logo from '../images/logo1.jpg'; // Correctly importing the image
import LoginModal from './LoginModal';

const NavBar = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="divmenu">
      <nav className="nav_menu">
        <span id="burger-menu">&equiv;</span>
        <a href="./" className="logo-in-menu">
          <img src={logo} title="Back to the Main Page of IIASD" width="50px" style={{ boxShadow: 'none' }} alt="IIADS Logo" />
        </a>
        
        <ul className="topmenu">
          <li>
            Academy
            <ul className="submenu">
              <li><a href="./">About the Academy...</a></li>
              <li><a href="members">Members of the Academy</a></li>
              <li><a href="/contacts">Contacts</a></li>
            </ul>
          </li>
          <li>
            Publications
            <ul className="submenu">
              <li><a href="/publications" className="down">Publications of Academy Members</a></li>
            </ul>
          </li>
          <li>
            Events
            <ul className="submenu">
              <li><a href="/events">Events</a></li>
            </ul>
          </li>         
        </ul>
      </nav>
      <li className="login-li">
            <button onClick={handleOpenModal} className="login-button">Login</button>
          </li>
      <LoginModal show={showModal} handleClose={handleCloseModal} />
    </div>
  );
};

export default NavBar;




//   return (
//     <div className="divmenu">
//       <nav className="nav_menu">
//         <span id="burger-menu">&equiv;</span>
//         <a href="./" className="logo-in-menu">
//           <img src={logo} title="Back to the Main Page of IIASD" width="50px" style={{ boxShadow: 'none' }} alt="IIADS Logo" />
//         </a>
//         <ul className="topmenu">
//           <li>Academy
//             <ul className="submenu">
//               <li><a href="./">About the Academy...</a></li>
//               <li><a href="members">Members of the Academy</a></li>
//               {/* <li><a href="pozdravl2023.shtml">Congratulations 2023</a></li> */}
//               <li><a href="/contacts">Contacts</a></li>
//               {/* <li><a href="charter-registration">Charter, Registration Certificate</a></li> */}
//               {/* <li><a href="Bank-Details">Bank Details</a> </li> */}
//             </ul>
//           </li>
//           <li>Publications
//             <ul className="submenu">
//               <li><a href="/publications" className="down">Publications of Academy Members</a></li>
//               {/* <li><a href="publish_other.shtml" className="down">Other Publications</a></li> */}
//             </ul>
//           </li>
//           {/* <li>Academy Editions
//             <ul className="submenu">
//               <li><a href="izdan.shtml" className="down">About Academy Editions...</a></li>
//               <li><a href="izdanchlacad.shtml">Publications by Academy Members</a></li>
//               <li><a href="sovmizd.shtml">Joint Publications of R&D UIS and the Academy</a></li>
//             </ul>
//           </li> */}
//           {/* <li>Activities
//             <ul className="submenu">
//               <li><a href="/creativity">Creative Activities</a></li>
//               <li><a href="tek_de.shtml">Current Activities</a></li>
//               <li><a href="podg_spec.shtml">Training of Highly Qualified Specialists</a></li>
//               <li><a href="tvorch_sv.shtml">Creative Connections</a></li>
//             </ul>
//           </li> */}
//           <li>Events
//             <ul className="submenu">
//               {/* <li><a href="info_acad_immed.shtml">Urgent Messages!</a></li> */}
//               <li><a href="/events">Events</a></li>
//               {/* <li><a href="our_links.shtml">Our Links (Connection with Other Organizations)</a></li> */}
//             </ul>
//           </li>
//          {/* <li>Scientific World
//             <ul className="submenu">
//               <li><a href="/science-and-society">Science and Society</a></li>
//               <li><a href="/advancements-in-science-and-technology">Advances in Science and Technology</a></li>
//               <li><a href="/titans-of-science">Titans of Science</a></li> 
//               <li><a href="/did-you-know-that">Did You Know...</a></li> 
//               <li><a href="/from-the-lives-of-scientists">From the Life of Scientists</a></li>
//             </ul>
//           </li>  */}
//           {/* <li>Archive
//             <ul className="submenu">
//               <li><a href="/polemics" className="down">Polemics</a></li>
//               <li><a href="arhiv_tek_de_2019.shtml" className="down">2019 Current Activities</a></li>
//               <li><a href="arhiv_tek_de_2020.shtml" className="down">2020 Current Activities</a></li>
//               <li><a href="archiv.shtml" className="down">Archive of Publications</a></li>
//               <li><a href="archiv_sf_de.shtml" className="down">Areas of Activity</a></li>
//               <li><a href="pozdravl2020.shtml">Congratulations 2020</a></li>
//               <li><a href="pozdravl2021.shtml">Congratulations 2021</a></li>
//               <li><a href="pozdravl2022.shtml">Congratulations 2022</a></li>
//               <li><a href="archiv_objavl.shtml" className="down">Announcements</a></li>
//               <li><a href="archiv_info2022.shtml" className="down">2022 Academy Information</a></li>
//               <li><a href="archiv_info2021.shtml" className="down">2021 Academy Information</a></li>
//               <li><a href="arhiv_info2019-2020.shtml" className="down">2019-2020 Academy Information</a></li>
//               <li><a href="arhiv_info2018.shtml" className="down">2018 Academy Information</a></li>
//               <li><a href="arhiv_info2017.shtml">2017 Academy Information</a></li>
//               <li><a href="rip.shtml">In Loving Memory...</a></li>
//             </ul>
//           </li> */}
//           < style={{ marginLeft: 'auto' }}>
//             <button onClick={handleOpenModal} className="login-button">Login</button>
//           </li>
//         </ul>
//       </nav>
//       <LoginModal show={showModal} handleClose={handleCloseModal} />
//     </div>
//   );
// };

// export default NavBar;
