import React from 'react';

const Footer = () => {
  return (
    <div className="footer">
      <div style={{ width: '100%', marginBottom: '5px' }}>
        <strong>2023. Israeli Independent Academy for Development of Science (IIADS)</strong>
        <br />
        Registration certificate No.580396224 dated 28.10.2003, valid in accordance with the statute dated 19.10.2003.
      </div>
      {/* <div>
        <a href="//youtube.com/IsraScience" target="_blank" rel="noopener noreferrer">
          <img src="images/youtube.png" width="50px" style={{ boxShadow: 'none' }} alt="IIADS YouTube channel" title="IIADS YouTube channel" />
        </a>
      </div> */}
     
      {/* <div>
        <img src="images/logo2.jpg" width="100px" style={{ boxShadow: 'none' }} alt="ИНАРН: Зарегистрированный товарный знак" title="ИНАРН: Зарегистрированный товарный знак" />
      </div> */}
      <div>
        <div>Address of the Academy: 2/66, Eshkolot str., Nesher 3668022., Israel;</div>
        <div>tel: +972-545904005﻿;</div>
        <div>
          e-mail: <a href="mailto:preiglev@gmail.com">preiglev@gmail.com</a>, <a href="mailto:inarn2016@gmail.com">inarn2016@gmail.com</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
