import React from "react";

const DidYouKnowThat = () => {
  return (
    <div className="div_main">
      <h2>Did you know that...</h2>
      <article>
        <ul style={{ padding: "0" }}>
          <p>
            <div className="article">
              <p>
                {" "}
                <strong>We know much more</strong> about distant objects in the
                Universe, located around 14 billion light-years away, than we do
                about what lies beneath our feet, i.e., the Earth's interior, at
                depths greater than 10 km?
              </p>
              <p>
                {" "}
                <strong>In Israel, a biological computer</strong> has been
                invented, with organic molecules as its basic components,
                instead of minerals as in modern electronic devices. An
                implanted computer like this in the human body could transmit
                signals about various organ deviations in the future and propose
                ways to address them.
              </p>
              <p>
                <strong>In May 2013,</strong> physicists finally managed to
                observe and photograph a hydrogen atom, the smallest atom in
                nature. Interestingly, the size, configuration, and distribution
                of electronic density in the atom, obtained from the photograph,
                perfectly matched the data calculated through quantum mechanics
                formulas.
              </p>
              <p>
                <strong>The great ancient Greek mathematician</strong> and
                philosopher Pythagoras believed in the magic of numbers. Magic
                or not, there are indeed many fascinating numbers. One of them
                is 37037. This number is only divisible by prime numbers,
                including the consecutive numbers 7, 11, and 13. It also divides
                evenly by 37 and another prime number within the first hundred –
                91. But the most interesting property of this number is that if
                you multiply it by 3n, where n is any number from 1 to 9, you
                get a six-digit number consisting of only the same digit n. For
                example, 37037∙3∙1 = 111111, and 37037∙3∙2 = 222222... or
                37037∙3∙7 = 777777, and so on.
              </p>
              <p>
                <strong>Manhattan Island (USA)</strong> was sold in 1626 for
                only $25. If the buyer had lived to our days and sold the
                island, they would have earned several tens of millions of
                dollars. This confirms the well-known rule that investing in
                real estate can be very profitable. However, few have considered
                that it is immeasurably more profitable to invest money in a
                bank with interest. If the same buyer had invested their $25 in
                a bank at 7% annual interest, they would have received $3.6
                trillion today, which is a hundred thousand times more than the
                island's value.
              </p>
              <p>
                <strong>Chinese believe</strong> there are 5 colors in a
                rainbow, Americans think there are 6, but we know for sure that
                there are 7.
              </p>
              <p>
                <strong>Snakes can sleep</strong> for 3 years without food, so
                yogis have a long way to go to match that!
              </p>
              <p>
                <strong>Albert Einstein</strong> received the Nobel Prize not
                for the theory of relativity, but for the theory of the
                photoelectric effect. Michelson, on the other hand, received it
                not for his famous experiments demonstrating that the speed of
                light is independent of the motion of its source but for the
                high-precision interferometer he invented and used in those
                experiments.
              </p>
              <p>
                {" "}
                <strong>Pandas</strong> are not bears; they are raccoons.
                Strawberries are not berries; they are nuts. Watermelons and
                pumpkins are not vegetables or fruits; they are berries.
              </p>
              <p>
                <strong>Unlike dogs,</strong> cats don't always show their
                intelligence, but they are very smart animals. Here are a few
                examples: At crosswalks, cats usually sit calmly while the red
                light is on. But as soon as the green light appears, they dash
                across the road. Many cats, when approaching a closed door of
                their home, jump onto the door and press the doorbell. When we
                went to work, we locked our kitten in a room to prevent it from
                causing trouble in the whole apartment. But it outsmarted us.
                When we left, it would jump onto the latch and bounce off it so
                that it would come out of the lock, and the door would open.
              </p>
              <p>
                <strong>Here's another interesting observation. </strong>It is
                known that cats, when falling, adjust their bodies to land on
                their feet. This trick is performed by only a few stuntmen and
                acrobats. However, not many people know that a cat dropped from
                the 7th floor has a lower chance of survival compared to a cat
                dropped, for example, from the 20th floor. It turns out that
                after falling about 8 floors, the cat "realizes" the danger and
                adjusts itself to minimize injuries when landing on its feet.
              </p>
              <p>
                <strong>Within 10 minutes</strong>, a hurricane generates more
                energy than all the nuclear power plants in the world combined.
              </p>
              <p>
                <strong>Statistics indicate</strong> that the number of
                overweight people on Earth is almost exactly equal to the number
                of underweight people.
              </p>
              <p>
                <strong>The fork in Russia</strong> was first brought from
                Poland by False Dmitry, while the first fork in England was
                imported from Italy in the 17th century.
              </p>
              <p>
                <strong>About people who see</strong> a glowing halo around the
                head or a faint glow of the human body called an aura, there is
                a lot written. The most amazing thing is that this is not
                fiction or charlatanism but the truth. The human body indeed
                emits so-called thermal rays. However, no one notices this glow
                as thermal radiation occurs in the invisible infrared spectrum.
                Research, however, has shown that approximately 1% of people can
                see infrared radiation to some extent, and they are the ones who
                register the aura around the human body.
              </p>
              <p>
                <strong>The number of stars in the Universe</strong> is
                estimated to be around 10^23. Interestingly, this number is of
                the same order as the number of molecules in one mole of a
                substance.
              </p>
              <p>
                <strong>The Universe consists mainly</strong> of hydrogen
                (81.7%) and helium (18.2%). The remaining elements, mostly
                concentrated in Earth-like planetary systems, make up less than
                0.1%.
              </p>
              <p>
                <strong>According to modern</strong> cosmological theories, the
                age of the Universe is estimated to be approximately 14 billion
                years, the age of the Solar System is 5 billion years, the age
                of Earth is 4.5 billion years, the age of Life on Earth is 3.5
                billion years, the age of Homo Erectus is 2-3 million years, and
                the age of modern humans (Homo Sapiens) is around 10 thousand
                years.
              </p>
              <p>
                <strong>The best memory</strong> (on average) is for things you
                do (90%), followed by what you see (visual memory) at 50%, and
                the worst is for what you hear (auditory memory) at only 10%.
              </p>
              <p>
                <strong>Human hair</strong> is stronger than steel wire of the
                same thickness. An average woman's hair, for example, can
                withstand a load of 200 tons.
              </p>
              <p>
                <strong>Women who wear</strong> bright red lipstick smile more
                often than others.
              </p>
              <p>
                <strong>The word "alphabet"</strong> originated from the phrase
                "aleph-bet." The alphabet was invented 1600 years before our era
                in Canaan and was immediately used in Hebrew writing, which was
                also used to write the Torah. Much later, the Hebrew alphabet
                was adopted by the ancient Greeks, who slightly, most likely
                accidentally, changed the script and name of the letters. The
                ancient Romans, who created the Latin alphabet based on the same
                Hebrew and Greek scripts, consciously modernized it. The Slavic
                alphabet (Cyrillic) was created relatively recently, around the
                15th century AD, based on Greek and Latin. Other cultures never
                created their own alphabets and still use pictographic writing
                like cuneiform or hieroglyphs.
              </p>
              <p>
                <strong>According to modern</strong> paleontology, humans did
                not evolve from monkeys. It has been proven that humans belong
                to one of the varieties of anthropoid primates that emerged
                suddenly about 60 million years ago. Human-like apes and humans
                share a common ancestor belonging to hominids. As for
                upright-walking humans, our ancestors appeared 2-3 million years
                ago.
              </p>
              <p>
                <strong>The latest upgrade</strong> of the electron microscope
                will allow real-time observation of the interaction between the
                electron beam and the atoms of crystals.
              </p>
              <p>
                <strong>Absolute space</strong>, which we perceive as a
                container for all the objects in the Universe (galaxies, stars,
                planetary systems, other celestial bodies, surrounding objects,
                and their internal structures, etc.), and absolute time, which
                we perceive as a container for sequentially changing events, do
                not objectively exist in nature. Just like there are no colors,
                sounds, continuous, supposedly impenetrable objects, the sky,
                etc., in nature. The concept of space-time and its properties is
                merely the result of the manifestation and subjective perception
                of material aggregates organized in a certain way, as well as
                sequences of their organized changes.
              </p>
              <p>
                <strong>The entire material aggregate of the Universe</strong>{" "}
                (space) is located inside the surface of a three-dimensional
                sphere of a four-dimensional expanding ball, the radius of which
                is the unidirectionally developing arrow of time (a sequence of
                causally related events) from the past to the future. Since the
                material aggregate is located inside the surface of a
                three-dimensional sphere (a three-dimensional sphere is
                different from the familiar two-dimensional sphere, such as the
                surface of the Earth because it also has depth), it itself can
                only be three-dimensional. Therefore, an observer cannot go
                beyond its boundaries and visualize its configuration. Exiting
                from any point of the three-dimensional sphere and moving
                continuously in one direction, the observer will inevitably
                return to the starting point. Hence, the Universe is finite but
                boundless, and the question of what is outside the Universe is
                meaningless. For the same reason, assertions that other
                Universes may exist next to ours also lack meaning.
              </p>
            </div>
          </p>
        </ul>
      </article>
    </div>
  );
};

export default DidYouKnowThat;
