import React from 'react';

const Events = () => {
  return (
    <div className="div_main">
      <div className="div_academy" id="info">
        <h2>Events</h2>

        <div className="div_item_sostav">
          <h3>Seminar Plan: "Thoughts on Nature" for September 2023.</h3>
          <br className="marg-top-10" />
          <ol>
            <li>
              <b>September 7, 2023, at 18:00</b>
              <br />Prof. Alexander Nikitin
              <br />
              <i>
                "Chain Reaction of Low-Energy Cold Nuclear Fusion. A New Source of
                Carbon-Free Energy. Mechanical Antigravity Engine".
              </i>
            </li>
            <p></p>
            <li>
              <b>September 14, 2023, at 18:00</b>
              <br />Prof. Lev Preigerman
              <br />
              <i>"Paradoxes of the Universe. Evolution".</i>
              <br />
              Continuation of the series.
            </li>
            <p></p>
            <li>
              <b>September 21, 2023</b>
              <br />Vyacheslav Iskhakov
              <br />
              <i>"New Approaches to the Development of Emergent Theory of Mind".</i>
            </li>
            <p></p>
            <li>
              <b>September 28, 2023, at 18:00</b>
              <br />Dr. Alexandra Levinski
              <br />
              <i>"The Role of the Brain in the Formation of the Human Personality".</i>
              <br />
              Presentation.
            </li>
          </ol>
        </div>

        <div className="div_item_sostav">
          <h3>Interregional Scientific Online Conference: "Evolution and Involution of Nature and Human Life Support Systems"</h3>
          <p>Dear Colleagues,</p>
          <ol className="marg-top-10">
            <p>
              The Haifa House of Scientists is ready to regularly publish the
              "Haifa House of Scientists Bulletin" dedicated to the conducted
              conference. To contribute, please send articles based on your
              conference reports. Articles must be strictly formatted according
              to the "Requirements for Article Formatting" (see Appendix 1).
            </p>
            <a href="./docs/Vestnik_Haifa_Requirements_for_the_design_of_articles.pdf" target="_blank" rel="noopener noreferrer">
              <b>Appendix&nbsp;1</b> Bulletin of HHS - Requirements for Article Formatting
            </a>
            <br />
          </ol>
          <p>
            <b>
              The deadline for article submission is July 31, 2023. For questions related to the past conference, you can contact the Haifa House of Scientists via email at dom.uchenih22@gmail.com or by phone at 053-8859855.
            </b>
          </p>
          <p>Chairman of the Council of the Haifa House of Scientists</p>
        </div>

        <div className="div_item_sostav">
          <h3>Seminar Plan: "Thoughts on Nature" for July 2023.</h3>
          <ol className="marg-top-10">
            <li>
              <b>July 6, 2023</b>
              <br />Prof. Izmail Khuzmiev
              <br />
              <i>"On Decarbonization and Climate"</i>
              <p>
                Earth's climate change over millennia is primarily related to
                cycles of solar activity, not the level of carbon dioxide in the
                atmosphere. However, some scientists assert that the main cause
                of climate change is carbon dioxide emissions from industrial
                enterprises.
              </p>
              <p>
                The process of removing carbon dioxide from the atmosphere,
                influenced by the campaign launched in the media, has already
                started. As a result, there is a danger of disrupting the
                processes of life reproduction on Earth due to the reduction of
                food production, oxygen, and biodiversity.
              </p>
            </li>
            <li>
              <b>July 13, 2023, at 18:00 </b>
              <br />Vyacheslav Davydov
              <br />
              <i>"Ze'ev Jabotinsky and Hitler"</i>(continuation of the series).
              <br />
              <p>
                The fight against the Nazi ideology of the final solution to the
                Jewish question in journalistic activity; The emigration of Jews
                to Palestine as the only solution to save the Jewish people from
                annihilation. Jabotinsky's calls to the Jews: Why these appeals
                were not heard by the majority of Jews in Europe; Jabotinsky's
                struggle to create a Jewish army to participate in the war
                against Nazi Germany; Jabotinsky's premature death in America in
                1940 during his visits to European countries to urge emigration
                to Palestine as soon as possible.
              </p>
            </li>
            <li>
              <b>July 20, 2023, at 18:00</b>
              <br />Prof. Lev Preigerman
              <br />
              <i>"Paradoxes of the Universe: String Theory"</i> Continuation of the series.
              <p>
                String theory emerged in the second half of the last century on
                a mathematical basis but unexpectedly became a certain
                alternative to the standard quantum model. Unlike the standard
                model, it managed to unite the theory of relativity and quantum
                field theory. However, it also revealed serious shortcomings and
                has not yet become the theory of everything as expected.
              </p>
            </li>
            <li>
              <b>July 27, 2023, at 18:00</b>
              <br />Dr. Vyacheslav Iskhakov
              <br />
              <i>"On the New Concept of 'REFLECTION' in Modern Psychology"</i>
              <p>
                Recognizing the psyche as one of the highest forms of matter,
                integral, living, and possessing its own direct attention-memory
                material basis and psychogenetically controlled metabolism,
                requires compliance with the laws of biogenesis and the
                development of its own theory of psychogenesis. In this context,
                it becomes legitimate to question the scientific correctness of
                the "reflection" paradigm in relation to the living, open-closed
                system of the psyche.
              </p>
            </li>
          </ol>
        </div>

        <div className="div_item_sostav">
          <h3>Seminar Plan: "Thoughts on Nature" for June 2023.</h3>
          <ol className="marg-top-10">
            <li>
              <b>June 1, 2023, at 18:00.</b>
              <br />Dr. German Trofimov, Doctor of Technical Sciences
              <br />
              <i>"On Kosher Electricity"</i>
              <p>Continuation of the cycle on electric power engineering.</p>
            </li>
            <li>
              <b>June 8, 2023, at 18:00</b>
              <br />Prof. Lev Preigerman
              <br />
              <i>"Paradoxes of the Universe: The Dark, Invisible Universe"</i>
              <p>Continuation of the cycle.</p>
            </li>
            <li>
              <b>June 15, 2023, at 18:00</b>
              <br />Dr. Vyacheslav Iskhakov
              <br />
              <i>"New Perspectives and the Solution to the Psychophysical Problem"</i>
              <p>
                The psychophysical problem, formulated by René Descartes in the
                17th century, remains unsolved to this day, despite numerous
                attempts by scientists from many countries, due to the lack of
                necessary and sufficient grounds. To date, such grounds have
                emerged in the concepts of "protein solitons"; controlled
                proteins in the pores of neuron membranes and neuronal ensembles;
                neurons detecting not only molecular but also physical fields in
                the brain itself; the mechanism of attention-memory conversion of
                non-psychic stimuli into psychic ones thanks to working memory
                and attention; attention, with its zones of total unconscious and
                conscious detection and integration of mental and motor actions,
                as an attribute of living working memory; the expression by the
                focus of attention (the dominanta of the energy-information flow)
                of mnemonic genes of neuronal ensembles; and "emergent causality"
                by R. Sperry (1996).
              </p>
            </li>
            <li>
              <b>June 22, 2023, at 18:00</b>
              <br />Dr. Iosif Keleynikov
              <br />
              <i>"Conversations with Prof. Lev Preigerman"</i>
            </li>
            <li>
              <b>June 29, 2023, at 18:00</b>
              <br />Prof. Boris Medres
              <br />
              <i>"Modern Laser Technologies"</i>
            </li>
          </ol>
        </div>

        <div className="div_item_sostav">
          <h3>Seminar Plan: "Thoughts on Nature" for May 2023.</h3>
          <ol className="marg-top-10">
            <li>
              <b>May 4, 2023, at 18:00</b>
              <br />Prof. Lev Preigerman
              <br />
              <i>"Paradoxes of the Universe"</i>
              <p>Continuation of the cycle.</p>
            </li>
          </ol>
        </div>

        <div className="div_item_sostav">
          <h3>Seminar Plan: "Thoughts on Nature" for April 2023.</h3>
          <ol className="marg-top-10">
            <li>
              <b>April 20, 2023, at 18:00 Israel Daylight Time</b>
              <br />Prof. Lev Preigerman
              <br />
              <i>"Paradoxes of the Universe"</i>
              <p>
                Even at the beginning of the last century, our knowledge of the
                Universe was very limited. However, in the early 1920s, a new
                science, cosmology, emerged, followed by a flurry of
                revolutionary works that turned our understanding of the
                Universe upside down by 180°. This report discusses these and
                other significant events, with the author proposing several
                hypotheses about the evolution of the Universe.
              </p>
            </li>
            <li>
              <b>April 27, 2023, at 18:00 Israel Daylight Time</b>
              <br />
              Associate Professor Ilona Drach.
              <br />
              Associate Professor Andrey Goroshko.
              <br />
              Ukraine. Khmelnytskyi National University.
              <br />
              <i>"Scientific Foundations of Automatic Balancing of Rotors with a Liquid Balancer"</i>
              <p>
                New solutions to the problem of ensuring the vibro-stability of
                rotating machine elements, based on the theory of automatic
                balancing of rotors with liquid balancers, have been obtained.
                The results allow for the calculation of the characteristics of
                the auto-balancing device, the selection of the type of fluid,
                and the determination of the range of angular velocities at which
                auto-balancing occurs. They also assess the impact of inertial,
                elastic-damping, and geometric characteristics of the rotor
                system on the effectiveness of auto-balancing for a wide class of
                machines with variable rotor imbalance.
              </p>
            </li>
          </ol>
        </div>

        <div className="div_item_sostav">
          <h3>Seminar Plan: "Thoughts on Nature" for March 2023.</h3>
          <ol className="marg-top-10">
            <li>
              <b>March 2, 2023, at 20:00</b>
              <br />Doctor of Philosophy, Professor Oleg Baksansky
              <br />
              <i>"Cognitive Didactics"</i>
              <p>The following problems are considered:</p>
              <p>-The possibilities of creating organisms with altered genetic structures</p>
              <p>-Designing and improving new living beings</p>
              <p>
                -The unification by science of all parts of disparate knowledge
                into a single whole
              </p>
              <p>-And others</p>
            </li>
            <li>
              <b>March 9, 2023 at 20:00</b>
              <br />Doctor of Physical and Mathematical Sciences, Professor Vasily Dimitrov, Canada
              <br />
              <i>"Alexander Yakovlevich Rozovsky"</i>
              <p>
                Continuation of the cycle "Memories of Communication with Great
                Scientists and Remarkable People"
              </p>
              <p>
                A.Y. Rozovsky was a Doctor of Chemical Sciences, Professor, and
                one of the fathers of heterogeneous catalysis. A man of complex
                fate, he grew up in the GULAG, where his mother was sent in 1937,
                and his father was executed.
              </p>
            </li>
            <li>
              <b>March 16, 2023, at 20:00</b>
              <br />Dr. Iosif Keleynikov
              <br />
              <i>"Culture: Evolution or Degradation"</i>
              <p>
                Problems of the relationship between art and nature, linguistics
                and coding from the perspective of a curious dilettante.
              </p>
            </li>
            <li>
              <b>March 23, 2023, at 20:00</b>
              <br />Dr. Vyacheslav Davydov
              <br />
              <i>"The Life and Work of Ze'ev Jabotinsky"</i>
              <p>
                Jabotinsky is rightly considered a titan of Jewish revival in the
                first half of the 20th century. Nature generously endowed him
                with various talents - a fine poet, writer, playwright, polyglot,
                journalist, translator, publicist, warrior, all of which he
                devoted to serving the great Zionist idea of the reconstruction
                of the Jewish state in the land of Eretz-Israel, becoming one of
                the leading right-wing Russian Zionists.
              </p>
            </li>
            <li>
              <b>March 30, 2023, at 20:00</b>
              <br />Doctor of Physics, Professor Lev Preigerman
              <br />
              <i>"Anomalous Phenomena" (continuation of the cycle) "Miracles"</i>
              <p>
                Natural and supernatural phenomena. Laws of Nature as
                manifestations of a miracle and intelligent choice. Miracle and
                fraud – telekinesis and ufology. Biblical miracles. Miracles in
                nature and life.
              </p>
            </li>
          </ol>
        </div>

        <div className="div_item_sostav">
          <h3>Seminar Plan: "Thoughts on Nature" for February 2023.</h3>
          <ol className="marg-top-10">
            <li>
              <b>February 2, 2023, at 20:00</b>
              <br />Professor, Doctor of Medical Sciences Raisa Tartakovskaya
              <br />
              <i>"The History of Helicobacter Discovery"</i>
            </li>
            <li>
              <b>February 9, 2023, at 20:00</b>
              <br />Dr. Vitaly Mikhailev
              <br />
              <i>"Mathematical Models of the Fundamental Categories of Philosophy"</i>
              <br />
              Continuation of the cycle
            </li>
            <li>
              <b>February 16, 2023, at 20:00</b>
              <br />Professor Lev Preigerman
              <br />
              <i>"Anomalous Phenomena" (continuation of the cycle)</i>
            </li>
            <li>
              <b>February 23, 2023, at 10:00</b>
              <br />Ilya Zlatkis
              <br />
              <i>"An Attempt to Comprehend the Meaning of the Direction of Scientific Development"</i>
              <p>
                Relay of generations or conflict of generations. What values are
                we ready to pass on to the next generations? Science and
                religion. Contradictions and complementarities.
              </p>
            </li>
          </ol>
        </div>

        <div className="div_item_sostav">
          <h3>Our Academy's 20th Anniversary</h3>
          <p>
            The celebrations began on October 12 this year, when, in the presence
            of the Academy's veterans and other friendly public scientific
            organizations, the President of the Academy, Professor Boris Medres,
            awarded Medal №1 to one of the oldest founders of the Academy and its
            long-time President of INARN, Prof. Lev Preigerman. Simultaneously,
            Professor Preigerman was awarded the title of Honorary Member of the
            Academy (Honorary Academician of INARN) and was presented with a
            corresponding diploma.
          </p>
          <p>
            On November 10 this year, the celebrations continued at the meeting
            of the Board of the Forum of Scientist-Repatriates of Israel. After
            the introductory words of the Head of the Forum, Prof. Leonid
            Dinevich, who highly appreciated the work of the Academy, and a brief
            story by Prof. Preigerman about the history of the Academy, Prof.
            Boris Medres announced the decision of the Presidium of the Academy
            "On awarding the INARN Gold Medal for Achievements in the Development
            of Science" to 28 scientists and leaders of public scientific
            organizations. These individuals were recognized for their
            significant personal contributions to the development of Israeli and
            world science, their efforts in promoting scientific knowledge, and
            their assistance to scientist-repatriates in successful absorption
            and personal achievements in the development of sciences.
          </p>
          <p>
            Prof. Boris Medres also announced that, by decision of the Presidium
            of the Academy, an annual procedure for awarding a commemorative
            medal to outstanding scientists was established, based on nominations
            by the leaders of public scientific organizations and the Board of
            the Forum of Scientists. Many of us, noted Prof. Medres, successfully
            integrated into Israeli and world science. Those who could not do so
            due to age, health, or other reasons were not left out but were given
            the opportunity to continue their scientific creativity and
            development within our public scientific organizations. The results
            of their activity include hundreds of scientific projects, articles,
            patents, and monographs. Most importantly, this has given and
            preserved people's sense of self-dignity, significance, and a sense
            of belonging to the development of science, to which we have
            dedicated our lives.
          </p>
          <p>
            Members of the Forum's Board warmly congratulated the Academy on its
            twentieth anniversary and all the awardees.
          </p>
        </div>

        <div className="div_item_sostav">
          <h3>August 18, 2022. Meeting of the Academy's Presidium.</h3>
          <p>
            <a href="./docs/prot-59-117.pdf" style={{ fontWeight: 'bold' }} target="_blank">
              Protocol 59 (117)
            </a>
            . <br />
            Agenda
            <br />
            1. Discussion of the letter from Prof. M. Kozlov.
            <br />
            2. Statement by L. Lerner
          </p>
        </div>
      </div>
    </div>
  );
};

export default Events;
