import React from 'react';

const BankDetails = () => {
  return (
    <div className="div_main">
      <div className="div_academy" style={{ backgroundColor: 'white', margin: '10px', padding: '10px' }}>
        <h2>Bank Details</h2>
        <article style={{ fontSize: '16pt', fontFamily: 'sans-serif' }}>
          <img src="images/bank1.jpg" style={{ float: 'left', boxShadow: 'none' }} width="200" alt="Bank 1" />
          <p style={{ clear: 'both', fontSize: '21px', fontWeight: 'bold' }} align="center">
          Details of the Israel Postal Bank
          </p>
          <p align="center">for the purpose of transferring funds to the account of the Israeli Independent Academy for Development of Sciences (IIADS) - Israeli Independent Academy for Development of Sciences</p>
          <p style={{ textIndent: 0, fontFamily: 'verdana,sans-serif' }}>
            Dear sir / madam
            <br />
            Regarding your request, please find below the details of the Israel Postal Bank, For credit accounts by S.W.I.F.T transfers.
            <br />
            Your account will be credited in either NIS/USD/EURO according to the type of account.
            <br />
            Please note the difference in our correspondent's bank details for Euro transactions and USD transactions.
            <br />
            For any further information please contact our customer service center at our toll free number 1800-21-31-41 and from cellular phones at 02-5005313.
          </p>
          <p>Money transferred to an account in the Israel Postal Bank will be deposited in shekels or foreign currency, depending on the type of your account. Please note that there is a difference in the correspondent bank details for dollar transfers and euro transfers. For additional information, you can contact the foreign currency center in Israel at 1800-21-31-41 or 02-6290447.</p>
          <table className="table-bank">
            <tbody>
              <tr>
                <td width="50%">Beneficiary Name:<br /><br /></td>
                <td>Israeli Independent Academy for Development of Sciences</td>
              </tr>
              <tr>
                <td>Account Number:<br /> <br /><br /> IBAN</td>
                <td>23-447051<br /><br /><br />IL 450090010000023447051</td>
              </tr>
              <tr>
                <td>Beneficiary Bank Name:<br /></td>
                <td>Israel Postal Bank</td>
              </tr>
              <tr>
                <td>Swift Code:<br />  </td>
                <td>IPOAILIJ</td>
              </tr>
              <tr>
                <td>US Dollar Correspondent Bank:<br /></td>
                <td>Deutsche Bank Trust Company America</td>
              </tr>
              <tr>
                <td>US Dollar Correspondent Bank Swift code:<br /></td>
                <td>BKTRUS33</td>
              </tr>
              <tr>
                <td>EURO Correspondent Bank:<br /><br /><br />EURO Correspondent Bank Swift code<br /></td>
                <td>Deutsche Bank Frankfurt<br /><br />DEUTDEFF</td>
              </tr>
            </tbody>
          </table>
          <p>For colleagues in Israel, when transferring, please also specify:


            <ul>
            <li>Beneficiary Name: Israeli Independent Academy for Development of Sciences</li>
              <li>Bank: בנק הדואר הנביאים 09 סניף 001 חיפה</li>
              <li>Account number: 23-447051</li>
            </ul>
          </p>
        </article>
      </div>
    </div>
  );
}

export default BankDetails;
