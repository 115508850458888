import React from 'react';

const ComplianceWithTheRequirements = () => {
  return (
    <div className="div_main">
      <div className="div_academy">
        <nav className="path_nav">
          <a href="/publications">Publications &gt;</a>
        </nav>
        <h2>
          On Compliance with the Requirements of the "Israeli Maritime Policy" and Environmental Requirements for the Conservation of the Marine Ecosystem in the "Concept of Development of the Mediterranean Coastal Area of Israel" Presented in the Article by M. Kozlov, A. Kozlov, and V. Gurevich
        </h2>
        <h3>Honored Ecologist of the Russian Federation, Prof., Dr. Mikhail Rudnik</h3>
        <p>NTA "Ecological Imperative"</p>
        <br />
        <b>
          <time dateTime="2018-07-30">July 30, 2018</time>
        </b>
        <p>
          Since the multifunctional dam is the core of the idea (Concept), we will examine it in light of the requirements of the "Israeli Maritime Program" and the current state of the coastal territory. The Israeli maritime policy reflects some of the issues I raised in my article but also proposes new requirements and rules for the development of the marine space and includes maps showing the current state of the Mediterranean coastal area. These maps include restricted marine zones established by the Ministry of Defense, natural resource and heritage protection zones, sand resource areas, nature and ecosystem conservation zones, marine infrastructure lines, fishing zones, aquaculture zones, and more, which reflect the condition of the coastal area.
        </p>
        <p>
          It is known that to protect the coast and maritime borders, the Israeli navy has created and deployed a tiered coastal defense system - a network of coastal observation stations and rapid response naval units consisting of high-speed armed boats that continuously patrol and control all coastal waters of the country in the Mediterranean Sea.
        </p>
        <p>
          From the listed cartographic material, it follows that the route of the dam proposed by the authors of the Concept will pass directly through these restricted marine zones, closed ecosystems, fishing zones, and aquaculture areas, intersecting gas pipeline lines, communication cables, and other infrastructure. The question arises: "How will the dam's route be adjusted to align with the restricted zones, ecosystems, and other areas closed to navigation?"
        </p>
        <p>
          The National Plan N37 for clusters of infrastructure facilities - power plants, a liquefied natural gas terminal, and desalination plants - recommends the construction of small artificial islands, which do not require connection to the shore (bridge or tunnel), beyond the marine horizon, 7 to 12 km from the shore to mitigate their visual and environmental impact, as well as high waves and frequent storms. Therefore, the authors' idea of constructing a dam in general and connecting it with bridges to artificial islands does not comply with the political document of the marine strategy.
        </p>
        <p>
          "Marine water pollution poses a threat to the ability to desalinate seawater, produce seafood through fishing or marine agriculture, and engage in sports and recreational activities at sea and on beaches. The geographic structure of the Mediterranean Sea and its ecological sensitivity, being a closed structure, lead to particularly serious pollution consequences and, therefore, to a reduction in the pollutants discharged into it. Maintaining the quality of marine water and preventing pollution from various sources is a fundamental principle in the marine spatial plan.
        </p>
        <p>
          The marine space of Israel represents large areas of soft substrate and some harder substrate habitats, including globally unique ones, such as the coastal abrasion platforms, the Achiv underwater canyon, underwater aeolian or sand ridges (kurkar ridges), methane seep zones, and deep-water corals". This is why the "Israeli Maritime Policy" prohibits the construction of artificial islands on hard substrate (seabed) and the use of sand for construction. "Along the Mediterranean coast of Israel, there are dozens of marine coastal structures located on the sandy shore and in shallow waters, several meters below sea level. Studies show that all the aforementioned structures somehow altered the wave and current system in their vicinity before their construction, and as a result, the local morphology of adjacent coastal areas changed". Undoubtedly, such requirements will also be imposed on the 180 km long dam, and all the aforementioned requirements have not been considered in the Concept.
        </p>
        <p>
          Many technical questions arise for which the authors currently have no answers. I am not even mentioning the coordination of such a project with commissions on artificial islands, the American-Israeli Commission for the Eastern Mediterranean, the Ministry of Defense, and others.
        </p>
        <p>
          It should not be forgotten that in this case, we are not talking about island construction but about a new grandiose utopian idea of building the world's largest dam, which has not been considered at the state level, is not recommended for development, has not undergone any preliminary and pre-project environmental examination, and does not have the legal status of an important and relevant state task (as some reviewers write).
        </p>
        <p>
          The weakness of the proposed Concept (since it cannot be called a project) is the lack of a business plan. The presented concept with a declaration of urgent contextual demand in the form of an article has already caused a certain reaction from state bodies, to which the authors applied.
        </p>
        <p>
          I will not dwell in detail on the insignificant remarks made in my address on the technical issues of the concept. They are insignificant, and many of them are answered in this article. The authors themselves have not yet been able to substantiate their idea technically. Unfortunately, I have not received answers to the questions I raised from the reviewers, except for general phrases such as: "the article contains useful information and well-founded warnings."
        </p>
        <p>
          Regarding the proposed concept, it does not meet the requirements of the "Israeli Maritime Policy" and the environmental requirements for preserving the marine ecosystem.
        </p>
        <br />
        <p><span className="span_pb">References:</span></p>
        <p>
          1. ם ין תיכוך מסמת מדיניוב למרחי הימל של ישרא- ל מינהן  התכנו  (Ministry of Finance of Israel. Political Document for the Israeli Marine Space. ). [Electronic resource]/ Electronic data - URL: <a href="http://www.iplan.gov.il/Documents/Report_4.pdf" target="_blank" rel="noopener noreferrer">www.iplan.gov.il/Documents/Report_4.pdf</a>
        </p>
        <p>
          2. THE ISRAEL MARINE PLAN .[Electronic resource]/ Electronic data - URL: <a href="//msp-israel.net.technion.ac.il/" target="_blank" rel="noopener noreferrer">https://msp-israel.net.technion.ac.il/.../MSP_plan.compressed</a>
        </p>
      </div>
    </div>
  );
};

export default ComplianceWithTheRequirements;
